import { Box, Fade, Stack, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonVariant1, ButtonVariant2 } from "../../components/Buttons/Buttons";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import Specs from "../../components/Specs/Specs";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Header from "../../layout/Header";
import store from "../../redux/store";
import { getCarData, selectVehicleSlice, setSelectedVehicle } from "../../redux/vehicle/vehicleSlice";

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
  },
}));

const LeftWrapper = styled(Box)(({ theme }) => ({
  width: "50%",
  backgroundColor: theme.palette.white.main,
  padding: "26px 80px",
  backgroundImage: "url('/images/bg-vector-1.svg')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  backgroundSize: "contain",
  [theme.breakpoints.down(900)]: {
    width: "100%",
    padding: "10px 13px 10px 20px",
    backgroundSize: "cover",
  },
  [theme.breakpoints.down(769)]: {
    width: "100%",
    padding: "34px 13px 34px 20px",
    backgroundSize: "cover",
  },
}));

const RightWrapper = styled(Box)(({ theme }) => ({
  width: "50%",
  backgroundColor: theme.palette.black.main,
  padding: "26px 80px",
  [theme.breakpoints.down(900)]: {
    width: "100%",
    padding: "34px 13px 130px 20px",
  },
}));

const ImageContainer = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "62%",
  left: "35%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80%",
  objectFit: "contain",
  pointerEvents: "none",
  [theme.breakpoints.down("lg")]: {
    top: "80%",
    left: "50%",
    width: "400px",
    height: "260px",
    objectFit: "contain",
  },
  [theme.breakpoints.down("md")]: {
    top: "85%",
    width: "350px",
    height: "200px",
    objectFit: "contain",
  },
  [theme.breakpoints.down("sm")]: {
    top: "88%",
    width: "250px",
    height: "200px",
    objectFit: "contain",
  },
}));

const ButtonContainer = styled(Stack)(({ theme }) => ({
  marginTop: "85px",
  gap: "28px",
  flexDirection: "row",
  padding: "0px 50px 100px 50px",
  [theme.breakpoints.down(1480)]: {
    padding: "0px 0px 100px 0px",
  },

  [theme.breakpoints.down(1285)]: {
    padding: "0px 30px 100px 30px",
    gap: "15px",
    flexDirection: "column",
  },
}));

const CarDetailsPage = () => {
  const windowWidth = useWindowWidth();

  const navigate = useNavigate();

  const { carData, selectedVehicle } = useSelector(selectVehicleSlice);

  const { car_name } = useParams();

  const [open, setOpen] = useState<boolean>(false);


  useEffect(() => {
    store.dispatch(setSelectedVehicle({ id: car_name, type: "car" }));
  }, [carData, car_name]);

  const closeDrawer = () => {
    setOpen(false);
  };

  useEffect(() => {
    store.dispatch(getCarData());
    store.dispatch(setSelectedVehicle({ id: car_name, type: "car" }));

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [car_name]);


  if (carData.length === 0) return null;

  return (
    <>
      <Helmet>
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Fade in={true} timeout={800}>
        <Container>
          <Stack sx={{ flexDirection: { lg: "row", xs: "column-reverse" } }}>
            <LeftWrapper>
              {windowWidth > 900 && <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />}
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: (theme) => theme.palette.black.light,
                    mt: "88px",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                  variant="body1"
                >
                  {selectedVehicle?.Range} Real World Range
                </Typography>
              </Box>
            </LeftWrapper>

            <RightWrapper>
              {windowWidth > 992 ? (
                <Header color="var(--color-variant-white)" />
              ) : (
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: {
                      lg: "right",
                      xs: "space-between",
                    },
                  }}
                >
                  {windowWidth < 900 && (
                    <Box>
                      <SwytchdLogo color="var(--color-variant-white)" />
                    </Box>
                  )}
                  <Box>
                    <HamburgerDrawer variant={"white"} />
                  </Box>
                </Stack>
              )}

              <Box
                sx={{
                  color: "#fff",
                  mt: { md: "88px", xs: "40px" },
                  p: { xl: "0px 80px", lg: "0px 20px", xs: "0px 20px" },
                }}
              >
                <Typography sx={{ fontSize: { xs: "20px", md: "35px" } }} variant="h2">
                  {`${selectedVehicle?.Make} ${selectedVehicle?.Model}`}
                </Typography>
                <Typography
                  mt="10px"
                  variant="h5"
                  sx={{
                    fontSize: { xs: "14px", md: "20px" },
                  }}
                >
                  &#8377; {Number(selectedVehicle?.CostMonth.split(" ")[0]).toLocaleString("en-IN")}/month
                </Typography>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.green.light,
                    mt: { md: "55px", sm: "22px", xs: "22px" },
                    fontSize: { xs: "14px", md: "20px" },
                    fontWeight: 300,
                  }}
                  mt="10px"
                  variant="h5"
                >
                  {/* Top speed: {selectedVehicle?.topSpeed}.  */}
                  Real World Range: {selectedVehicle?.Range}.{/* Charging time:{" "} */}
                  {/* {selectedVehicle?.chargingTime} */}
                </Typography>
                <Typography mt="30px" fontSize={"16px"} variant="h3">
                  {selectedVehicle?.Vehicle_Description}
                </Typography>
              </Box>

              {windowWidth > 900 && (
                <ButtonContainer>
                  <ButtonVariant1
                    sx={{ color: (theme) => theme.palette.black.main }}
                    // onClick={() => isLoggedIn ? navigate("/join-waitlist") : navigate("/auth/signin")}
                    onClick={() => navigate("/join-waitlist")}
                  >
                    {selectedVehicle?.Total_Available > 0 ? "BOOK NOW @999" : "JOIN WAITLIST @999"}
                  </ButtonVariant1>
                  <ButtonVariant2 onClick={() => setOpen(true)}>VIEW SPECS</ButtonVariant2>
                </ButtonContainer>
              )}
            </RightWrapper>
          </Stack>
          <ImageContainer
            src={`${process.env.REACT_APP_IMG_URL}/vehicles/${selectedVehicle?.Vehicle_Type.toLowerCase()}s/${selectedVehicle?.Make.toLowerCase()}/${selectedVehicle?.Model?.replaceAll(" ", "+").toLowerCase()}/${selectedVehicle?.Model?.replaceAll(" ", "_").toLowerCase()}_large.webp`} alt="bike"
          />
        </Container>
      </Fade>
      {windowWidth < 900 && (
        <Stack p="20px 35px" direction="column" spacing="14px">
          <Typography variant="body1" fontSize="12px">
            {selectedVehicle?.Range} Real World Range
          </Typography>
          <ButtonVariant1 onClick={() => setOpen(true)} sx={{ bgcolor: (theme) => theme.palette.green.main }}>
            VIEW SPECS
          </ButtonVariant1>
          <ButtonVariant2
            sx={(theme) => ({
              borderColor: theme.palette.green.main,
              color: theme.palette.green.main,
            })}
            // onClick={() => isLoggedIn ? navigate("/join-waitlist") : navigate("/auth/signin")}
            onClick={() => navigate("/join-waitlist")}
          >
            {selectedVehicle?.Total_Available > 0 ? "BOOK NOW @999" : "JOIN WAITLIST @999"}
          </ButtonVariant2>
        </Stack>
      )}
      <ImageSlider vehicle={carData} />
      <Specs open={open} onClose={closeDrawer} vehicleData={selectedVehicle} />
    </>
  );
};

export default CarDetailsPage;
