import React from "react";
import { Helmet } from "react-helmet";
import ServicesBanner from "../../components/ServicesBanner/ServicesBanner";
import ServicesBannerMobile from "../../components/ServicesBanner/ServicesBannerMobile";
import useWindowWidth from "../../hooks/useWindowWidth";
import Footer from "../../layout/Footer";
import BackedBy from "./BackedBy";
import Banner from "./Banner";
import FuelCostSection from "./FuelCostSection";
import HeroSection from "./HeroSection";
import MediaMentions from "./MediaMentions";
import Recognition from "./Recognition";
import WhySwytchdSection from "./WhySwytchdSection";

const Homepage: React.FC = () => {
  const windowWidth = useWindowWidth();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Electric Vehicle Subscription </title>
        <meta name="description" content="Subscribe to your favorite electric vehicle with all-inclusive monthly plans starting at just Rs. 4,200. Our electric vehicle subscription service offers the perfect solution. " />
        <meta name="keywords" content="Electric Vehicle Subscription, Electric car subscription, EV subscription service, Electric bike subscription, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <HeroSection />
      {windowWidth > 769 ? <ServicesBanner /> : <ServicesBannerMobile />}
      <WhySwytchdSection />
      <Banner />
      <FuelCostSection />
      <MediaMentions />
      <Recognition />
      <BackedBy />
      <Footer />
    </>
  );
};

export default Homepage;

/**
 * CustomersReview and GetInTouch components are removed for now
 * which are available under <CustomersReview/> and <GetInTouch/>
 */
