import React, { useState } from "react";
import {
  styled,
  Box,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { StyledTextField } from "../joinWaitlistPage/JoinWaitlistForm";
import { useTimer } from "react-timer-hook";
import { useSelector } from "react-redux";
import {
  selectAuthSlice,
  setForgotPasswordOtp,
} from "../../redux/auth/authSlice";
import userPool from "../../userPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import FailureAlert from "../../components/Alerts/Error";
import { toast } from "react-hot-toast";
import store from "../../redux/store";
import axios from "axios";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.black.light,
  minHeight: "calc(100vh - 70px)",
  padding: "26px 60px",

  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
}));

interface IProp {
  isForgotPasswordOtp: boolean;
}
const VerifyOTP: React.FC<IProp> = ({ isForgotPasswordOtp }) => {
  const [otp, setOtp] = useState("");
  const [err, setErr] = useState("");
  const [otpError, setOtpError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { userData, forgotPasswordPhone, newPassword } =
    useSelector(selectAuthSlice);

  const navigate = useNavigate();

  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: time,
    onExpire: () => console.warn("expired"),
  });

  const verify = () => {
    if (!otp || otp.length < 6) {
      return setOtpError("Please enter valid OTP!");
    }

    setIsLoading(true);

    if (isForgotPasswordOtp) {
      // store.dispatch(setForgotPasswordOtp(otp));
      // return navigate("/auth/recover-password/change-password");
      const _user = {
        Username: `+91${forgotPasswordPhone}`,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(_user);

      cognitoUser.confirmPassword(otp, newPassword, {
        onSuccess() {
          toast.success("Password changed successfully!");
          setIsLoading(false);
          navigate("/auth/signin");
        },

        onFailure(err) {
          setIsLoading(false);
          if (err.name === "UserNotFoundException") {
            customChangePassword();
          } else {
            setErr(err.message);
          }
        },
      });

      const customChangePassword = () => {
        if (!newPassword) return;

        setIsLoading(true);

        const _user = {
          Username: forgotPasswordPhone,
          Pool: userPool,
        };

        const cognitoUser = new CognitoUser(_user);

        cognitoUser.confirmPassword(otp, newPassword, {
          onSuccess() {
            toast.success("Password changed sucessfully!");
            setIsLoading(false);
            navigate("/auth/signin");
          },

          onFailure(err) {
            setIsLoading(false);
            toast.error("SOMETHING UNTOWARD HAPPENED, PLEASE TRY AGAIN", {
              duration: 8000,
              position: "top-center",
            });
            setErr(err.message);
            navigate("/auth/signin");
          },
        });
      };
    }

    let _userData = {
      Username: `+91${userData.phone}`,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(_userData);

    cognitoUser.confirmRegistration(otp, true, (err, result) => {
      if (err) {
        setIsLoading(false);
        if (err.name === "UserNotFoundException") {
          customVerify();
        } else {
          return setOtpError(err.message);
        }
      }

      if (result === "SUCCESS") {
        axios.post("/kf/signup", {
          userid: userData.userid,
          email: userData.email,
          phone: userData.phone,
          name: userData.name,
        });
        setIsLoading(false);
        toast.success("Account succesfully created!");
        navigate("/auth/signin");
      }
    });
  };

  const customVerify = () => {
    if (!otp || otp.length < 6) return;

    setIsLoading(true);

    if (isForgotPasswordOtp) {
      store.dispatch(setForgotPasswordOtp(otp));
      return navigate("/auth/recover-password/change-password");
    }

    let _userData = {
      Username: userData.phone,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(_userData);

    cognitoUser.confirmRegistration(otp, true, (err, result) => {
      if (err) {
        setIsLoading(false);
        return setOtpError(err.message);
      }

      if (result === "SUCCESS") {
        axios.post("/kf/signup", {
          userid: userData.userid,
          email: userData.email,
          phone: userData.phone,
          name: userData.name,
        });
        setIsLoading(false);
        toast.success("Account succesfully created!");
        navigate("/auth/signin");
      }
    });
  };

  const resendOTP = () => {
    if (forgotPasswordPhone != null && forgotPasswordPhone !== "") {
      setIsLoading(true);
      let _userData = {
        Username: `+91${forgotPasswordPhone.slice(-10)}`,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(_userData);
      cognitoUser.forgotPassword({
        onSuccess: (data: any) => {
          setIsLoading(false);
          toast.success("OTP resend succesfully!");
        },
        onFailure: (err: Error) => {
          if (JSON.stringify(err.name) === "UserNotFoundException") {
            customResendOTP(forgotPasswordPhone.slice(-10));
          } else {
            setIsLoading(false);
            return setOtpError(err.message);
          }
        },
      });
    } else {
      toast.error("SOMETHING UNTOWARD HAPPENED, PLEASE TRY AGAIN", {
        duration: 8000,
        position: "top-center",
      });
      navigate("/auth/recover-password");
    }
  };

  const customResendOTP = (username: any) => {
    setIsLoading(true);
    let _userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(_userData);

    cognitoUser.forgotPassword({
      onSuccess: (data: any) => {
        setIsLoading(false);
        toast.success("OTP resend succesfully!");
      },
      onFailure: (err: Error) => {
        setIsLoading(false);
        return setOtpError(err.message);
      },
    });
  };
  return (
    <Container alignItems="center" justifyContent="center">
      {otpError && (
        <FailureAlert
          errorMsg={otpError}
          errorTitle={"Verification Failed"}
          closeErrorPopup={() => setOtpError("")}
        />
      )}
      {err && (
        <FailureAlert
          errorMsg={err}
          errorTitle={"Error"}
          closeErrorPopup={() => setErr("")}
        />
      )}

      <Stack direction="column" justifyContent="center" pt="4em">
        <Typography
          color="#fff"
          variant="h6"
          sx={{ fontSize: { sm: "20px", md: "24px" } }}
          alignSelf="center"
        >
          PLEASE VERIFY THE MOBILE NUMBER
        </Typography>
        <Stack
          direction="column"
          alignSelf="center"
          rowGap={5}
          sx={{
            position: "relative",
            width: { xs: "100%", sm: "350px" },
            padding: { xs: "0px", md: "0px 20px" },
          }}
        >
          {isLoading && (
            <CircularProgress
              sx={{
                "&.MuiCircularProgress-colorPrimary": {
                  color: (theme) => theme.palette.green.light,
                },
                "&.MuiCircularProgress-root": {
                  width: "60px !important",
                  height: "60px !important",
                  position: "absolute",
                  top: "20%",
                  left: "40%",
                },
              }}
            />
          )}
          <Typography
            variant="h3"
            color="#aaa"
            textAlign="center"
            sx={{ fontSize: { xs: "12px", md: "16px" } }}
          >
            Enter 6 digits OTP received
            <Typography
              sx={{
                color: (theme) => theme.palette.green.light,
                ml: "5px",
                fontSize: { xs: "12px", md: "16px" },
              }}
              variant="h5"
              component="span"
            >
              {userData.phone.replace(/^.{6}/g, "XXXXXX")}
            </Typography>
          </Typography>

          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputStyle={{
              textAlign: "center",
              width: "inherit",
            }}
            renderSeparator={<span style={{ visibility: "hidden" }}>-</span>}
            renderInput={(props) => (
              <StyledTextField
                variant="standard"
                type="tel"
                inputProps={{ ...props }}
              />
            )}
          />

          <ButtonVariant1
            sx={(theme) => ({
              color: theme.palette.black.main,
              fontSize: { md: "18px", lg: "24px" },
              borderRadius: "80px",
              padding: { sm: "8px", md: "14px 35px" },
            })}
            onClick={() => verify()}
          >
            {isForgotPasswordOtp ? "PROCEED" : "VERIFY"}
          </ButtonVariant1>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h3"
              color="#aaa"
              sx={{
                fontSize: { xs: "14px", md: "18px" },
              }}
            >
              Resend OTP in {minutes}:{seconds}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: (theme) =>
                  seconds === 0 && minutes === 0
                    ? theme.palette.green.light
                    : theme.palette.gray.light,
                fontWeight: 600,
                fontSize: { xs: "14px", md: "18px" },
                cursor: "pointer",
              }}
              onClick={() => {
                if (seconds === 0 && minutes === 0) {
                  const time = new Date();
                  time.setSeconds(time.getSeconds() + 120);
                  restart(time);
                  resendOTP();
                }
              }}
            >
              Resend OTP
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default VerifyOTP;
