import React from "react";

import styles from "./css/MediaMentions.module.css";
import MediaCards from "../../components/Cards/MediaCards";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const Recognition: React.FC = () => {

  return (
    <>
      <Box p="20px 0px">
        <Typography variant="h2" fontWeight="600" textAlign="center" sx={{
          fontSize: { xs: "20px", md: "35px" }
        }}>RECOGNITION</Typography>
        <Typography textAlign="center" variant="body1" p="10px 0px" sx={{
          fontSize: { xs: "14px", md: "18px" }
        }}>Official Start-Up India Certified Business</Typography>
        <div className={styles.content} style={{ padding: "20px 0px" }}>
          <MediaCards p="0px">
            <img src="/images/startup-india.png" alt="Startup India" />
          </MediaCards>
        </div>
      </Box>
      <Box p="20px 0px">
        <Typography variant="h2" fontWeight="600" textAlign="center" sx={{
          fontSize: { xs: "20px", md: "35px" }
        }}>LICENCED BY</Typography>
        <Typography textAlign="center" variant="body1" p="10px 0px" sx={{
          fontSize: { xs: "14px", md: "18px" }
        }}>Karnataka State Transport Authority Official Licence Holder</Typography>
        <div className={styles.content} style={{ padding: "20px 0px 50px 0px" }}>
          <MediaCards>
            <img src="/images/licenced-bt.png" alt="Licenced By" />
          </MediaCards>
        </div>
      </Box>
    </>
  );
};

export default Recognition;
