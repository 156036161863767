import { Button, Stack, styled } from "@mui/material";

const ServicesTag = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  cursor: "auto",
  fontSize: "20px",
  fontWeight: 300,
}));

const StickyServicesBanner = () => {
  return (
    <Stack
      sx={{
        position: "sticky",
        bottom: 0,
        backgroundColor: (theme) => theme.palette.black.light,
      }}
      p="35px 90px"
      direction="row"
      justifyContent="space-around"
      flexWrap="wrap"
    >
      <ServicesTag
        disableElevation
        disableRipple
        startIcon={<img src="/images/no-deposit.svg" alt="Low Deposit" />}
        variant="text"
      >
        LOW DEPOSIT
      </ServicesTag>
      <ServicesTag
        disableElevation
        disableRipple
        startIcon={<img src="/images/insurance-icon.svg" alt="Insurance" />}
        variant="text"
      >
        INSURANCE
      </ServicesTag>

      <ServicesTag
        disableElevation
        disableRipple
        startIcon={<img src="/images/servicing-icon.svg" alt="Servicing" />}
        variant="text"
      >
        SERVICING
      </ServicesTag>

      <ServicesTag
        disableElevation
        disableRipple
        startIcon={<img src="/images/recovery-icon.svg" alt="Recovering" />}
        variant="text"
      >
        RECOVERY
      </ServicesTag>

      <ServicesTag
        disableElevation
        disableRipple
        startIcon={<img src="/images/km-per-pm-icon.svg" alt="kmperpm" />}
        variant="text"
      >
        1500 KM/PM
      </ServicesTag>
    </Stack>
  );
};

export default StickyServicesBanner;
