export function getDays(a: number, b: number) {
  // To calculate the time difference of two dates
  let date1 = new Date(a);
  let date2 = new Date(b);
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  
  return Math.round(Difference_In_Days);
}

export function getCurrentUnixTimestamp() {
  const currentDate = new Date();
  const unixTimestamp = Math.floor(currentDate.getTime() / 1000);
  return unixTimestamp;
}
