import { Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./css/Banner.module.css";

const Banner: React.FC = () => {
  const [currentImage, setCurrentImage] = React.useState<number>(0);
  const images: string[] = [
    "banner-img-1",
    "banner-img-2",
    "banner-img-3",
    "banner-img-4",
  ];

  const navigate = useNavigate();

  React.useEffect(() => {
    const t = setTimeout(() => {
      if (currentImage === images.length - 1) {
        setCurrentImage(0);
        return;
      }
      setCurrentImage((prev) => prev + 1);
    }, 3000);

    return () => clearTimeout(t);
  }, [currentImage, images.length]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.banner_heading}>
          <h1>SWYTCH EVERY MONTH!</h1>
          <p>
            Drive it like you own it,
            <Typography
              variant="body2"
              component="span"
              sx={{
                display: { xs: "block", md: "inline-block" },
                fontFamily: "var(--font-family-fraunces)",
                fontWeight: 400,
                fontStyle: "italic",
                fontSize: { xs: "20px", md: "28px" },
                ml: "5px",
              }}
            >
              swytch it like you don't
            </Typography>
          </p>
        </div>
        <div className={styles.btns}>
          <button
            onClick={() => navigate("/electric-cars-subscription")}
            className={`button-variant-1 ${styles.btn}`}
          >
            VIEW CARS
          </button>
          <button
            onClick={() => navigate("/electric-bikes-subscription")}
            className={`button-variant-1 ${styles.btn}`}
          >
            VIEW BIKES
          </button>
        </div>
      </div>

      <AnimatePresence>
        <motion.div
          className={styles.banner_img}
          key={images[currentImage]}
          initial={{ opacity: 0, x: 1000, y: 0 }}
          animate={{ opacity: 1, x: -300, y: 0 }}
          exit={{ opacity: 0, x: -1000, y: 0 }}
          transition={{ duration: 2, ease: "easeOut" }}
        >
          <img
            src={`/images/${images[currentImage]}.svg`}
            alt="banner images"
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Banner;
