import { CognitoUserPool } from "amazon-cognito-identity-js";

/**
* dev
*/

// const poolData = {
//   UserPoolId: "ap-south-1_6EyWNZSjo",
//   ClientId: "4c515vmjl029mv91m7gnvkvbm8"
// }

/**
* prod
*/
const poolData = {
  UserPoolId: "ap-south-1_YRYWMFQfD",
  ClientId: "5997kqis7732cl1b0cnldb3h0l"
}

/*eslint-disable*/
export default new CognitoUserPool(poolData);
