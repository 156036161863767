import { useRoutes } from "react-router-dom";
import ProtectedRoutes from "./hoc/ProtectedRoutes";
import UnauthorizedRoutes from "./hoc/UnauthorizedRoutes";
import AboutUs from "./pages/aboutUs";
import AuthHeader from "./pages/auth/AuthHeader";
import ChangePassword from "./pages/auth/ChangePassword";
import InputMobileNumber from "./pages/auth/InputMobileNumber";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import VerifyOTP from "./pages/auth/VerifyOTP";
import BikeDetailsPage from "./pages/bikeDetailsPage";
import BikesListPage from "./pages/bikes";
import CarDetailsPage from "./pages/carDetailsPage";
import CarListPage from "./pages/cars";
import ComparePage from "./pages/compare";
import CompareTablePage from "./pages/compare/CompareTablePage";
import FAQ from "./pages/faq";
// import Coverpage from "./pages/coverpage";
import Homepage from "./pages/homepage";
import JoinWaitlistPage from "./pages/joinWaitlistPage";
import PrivacyPolicy from "./pages/privacyPolicy";
import ProfileManagement from "./pages/profileManagement";
import SubscriptionManagementPage from "./pages/subscriptionManagement";
import TermsAndConditions from "./pages/termsAndConditions";

export default function Router() {
  return useRoutes([
    // {
    //   path: "/",
    //   element: <Coverpage />,
    // },
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "electric-bikes-subscription",
      children: [
        {
          path: "",
          element: <BikesListPage />,
        },
        {
          path: ":bike_name",
          element: <BikeDetailsPage />,
        },
      ],
    },
    {
      path: "electric-cars-subscription",
      children: [
        {
          path: "",
          element: <CarListPage />,
        },
        {
          path: ":car_name",
          element: <CarDetailsPage />,
        },
      ],
    },

    {
      path: "join-waitlist",
      element: <JoinWaitlistPage />,
    },

    {
      path: "compare",
      children: [
        {
          path: "",
          element: <ComparePage />,
        },
        {
          path: "table",
          element: <CompareTablePage />,
        },
      ],
    },

    {
      path: "terms-and-conditions",
      element: <TermsAndConditions />,
    },

    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },

    {
      path: "faqs",
      element: <FAQ />,
    },

    {
      path: "about-us",
      element: <AboutUs />,
    },

    {
      path: "auth",
      element: (
        <>
          <ProtectedRoutes>
            <AuthHeader />
          </ProtectedRoutes>
        </>
      ),
      children: [
        {
          path: "signin",
          element: <SignIn />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
        {
          path: "verify-otp",
          element: <VerifyOTP isForgotPasswordOtp={false} />,
        },
        {
          path: "recover-password",
          children: [
            {
              path: "",
              element: <InputMobileNumber />,
            },
            {
              path: "verify-otp",
              element: <VerifyOTP isForgotPasswordOtp={true} />,
            },
            {
              path: "change-password",
              element: <ChangePassword />,
            },
          ],
        },
      ],
    },
    {
      path: "subscriptions",
      element: (
        <UnauthorizedRoutes>
          {" "}
          <SubscriptionManagementPage />
        </UnauthorizedRoutes>
      ),
    },
    {
      path: "manage-profile",
      element: (
        <UnauthorizedRoutes>
          <ProfileManagement />
        </UnauthorizedRoutes>
      ),
    },
  ]);
}
