let i = 0;
export const detectButtonName = (vehicles: any): any => {

  if (i === vehicles.length - 1) {
    return "JOIN WAITLIST"
  }

  if (vehicles[i]?.Total_Available > 0) {
    return "BOOK NOW"
  }

  i = i + 1;

  return detectButtonName(vehicles);

}
