import React from 'react'
import { Stack, Box, styled } from '@mui/material';
import HamburgerDrawer from '../../components/HamburgerDrawer/HamburgerDrawer';
import SwytchdLogo from '../../components/SwytchdLogo/SwytchdLogo';
import Header from '../../layout/Header';
import useWindowWidth from '../../hooks/useWindowWidth';
import { Outlet } from 'react-router-dom';



const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.black.light,
  padding: "26px 60px",
  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
    padding: "26px 20px",
  },
}));

const AuthHeader: React.FC = () => {

  const windowWidth = useWindowWidth();

  return (
    <>
      <Container>
        <Stack direction="row" justifyContent="space-between">
          <SwytchdLogo color="var(--color-variant-white)" />
          <Box>
            {windowWidth > 769 ? (
              <Header color="var(--color-variant-white)" />
            ) : (
              <HamburgerDrawer variant="white" />
            )}
          </Box>
        </Stack>
      </Container>
      <Outlet />
    </>
  )
}

export default AuthHeader
