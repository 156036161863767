import { BatteryChargingFull, Close, Speed } from "@mui/icons-material";
import {
  Stack,
  Box,
  Grid,
  Drawer,
  Typography,
  CircularProgress,
  Skeleton,
  Rating,
  TextField,
  MenuItem,
  Autocomplete,
  Dialog,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import {
  StyledSkeleton,
  StyledSkeletonText,
} from "../../components/Loaders/VehicleListLoader";
import LargeSubscriptionCard from "../../components/SubscriptionListCard/LargeSubscriptionCard";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Header from "../../layout/Header";
import {
  ButtonVariant2,
  ButtonVariant1,
} from "../../components/Buttons/Buttons";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { useSelector } from "react-redux";
import {
  getBikeData,
  getCarData,
  selectVehicleSlice,
} from "../../redux/vehicle/vehicleSlice";
import store from "../../redux/store";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import SmallVehicleDisplayCard from "../../components/VehicleDisplayCard/SmallVehicleDisplayCard";
import { selectAuthSlice } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const reasonArray: string[] = [
  "Not interested in continuing",
  "Bought a vehicle",
  "Moving out of city",
  "Found an alternative",
  "Going on vacation",
  "Financial problems",
];

/**
 * Switch Vehicle Page
 */
const SwitchVehicle = ({ subscriptionData, onClose, close }: any) => {
  const { carData, bikeData } = useSelector(selectVehicleSlice);

  const [vehicles, setVehicles] = useState<any>([]);

  const [open, setOpen] = useState(false);
  const loading = open && vehicles?.length === 0;

  const [switchVehicle, setSwitchVehicle] = useState<any>();
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [color, setColor] = useState<any>([]);

  const [load, setLoad] = useState<boolean>(false);

  const { loggedInUser } = useSelector(selectAuthSlice);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    store.dispatch(getCarData());
    store.dispatch(getBikeData());
    if (active && bikeData.length > 0 && carData.length > 0) {
      setVehicles([...bikeData, ...carData]);
    }

    return () => {
      active = false;
    };
  }, [loading, carData, bikeData]);

  const getColors = async () => {
    setLoad(true);
    const { data } = await axios.get(
      `/kf/vehicles/model/${switchVehicle.Model}/city/Bengaluru`
    );

    setColor(data.colors);
    setLoad(false);
  };

  const handleSwitch = async () => {
    if (!selectedColor) {
      return toast.error("Please select color!");
    }
    try {
      await instance.post("/kf/user/subscription/switch", {
        user_code: loggedInUser.userid,
        vehicle: subscriptionData.Vehicle_Code,
        type: switchVehicle.Vehicle_Type,
        name: `${switchVehicle.Make} ${switchVehicle.Model}`,
        color: selectedColor,
      });
      toast.success("Vehicle Switch Request Successfully Sent!");
      close(false);
    } catch (e) {
      toast.error("SOMETHING UNTOWARD HAPPENED, PLEASE TRY AGAIN");
    }
  };

  return (
    <Box
      sx={{
        width: { md: "500px" },
        p: "10px 20px",
      }}
    >
      <Stack
        p="20px 0px"
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography
          variant="h4"
          lineHeight="1.3em"
          sx={{
            fontSize: { xs: "16px", md: "25px" },
          }}
        >
          {" "}
          Switch Vehicle
        </Typography>
        <Close
          sx={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => onClose()}
        />
      </Stack>

      <Box p="20px 0px">
        <Typography variant="body1" component="span">
          Switch
          <Typography
            variant="h5"
            component="span"
            fontSize="18px"
            sx={{
              color: (theme) => theme.palette.green.main,
              m: "0px 5px",
            }}
          >
            {" "}
            {subscriptionData.Make} {subscriptionData.Vehicle_Name} -{" "}
            {subscriptionData.Vehicle_Registered_No}{" "}
          </Typography>
          to:
        </Typography>
      </Box>

      <Stack direction="column" rowGap={5}>
        <Autocomplete
          id="switch vehicle"
          options={vehicles}
          loading={loading}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          color="success"
          value={switchVehicle ? switchVehicle : null}
          onChange={(event: any, newValue: any) => {
            setSwitchVehicle(newValue);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              size="small"
              color="success"
              label="SWITCH VEHICLE"
              variant="standard"
              name="switchVehicle"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress sx={{ color: "#000" }} size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option: any, { inputValue }) => {
            const matches = match(
              `${option.Make} ${option.Model}`,
              inputValue,
              {
                insideWords: true,
              }
            );
            const parts = parse(`${option.Make} ${option.Model}`, matches);

            return (
              <li {...props} key={option.Model}>
                <div>
                  {parts.map((part: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        color: part.highlight ? "#24FF69" : "#000",
                        fontWeight: 300,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
          getOptionLabel={(options: any) => options.Make + " " + options.Model}
        />

        {switchVehicle ? (
          <TextField
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value as string)}
            fullWidth
            select
            id="color-select"
            label="COLOR"
            variant="standard"
            color="success"
            onFocus={getColors}
          >
            {load ? (
              <MenuItem>Loading...</MenuItem>
            ) : (
              color.map((el: any, key: number) => {
                return (
                  <MenuItem value={el.color} key={key}>
                    {el.color}
                  </MenuItem>
                );
              })
            )}
          </TextField>
        ) : null}

        {switchVehicle && (
          <SmallVehicleDisplayCard
            fontMax={"13px"}
            vehicleData={switchVehicle}
            type={switchVehicle.Vehicle_Type}
            hideButton={true}
          />
        )}

        {switchVehicle && (
          <Box mt="-20px">
            <ButtonVariant1
              sx={{
                bgcolor: (theme) => theme.palette.green.main,
              }}
              onClick={handleSwitch}
            >
              SWITCH
            </ButtonVariant1>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

/**
 * Stop subscription/Renew Subscription page
 */
const StopSubscription = ({ subscriptionData, onClose, type, close }: any) => {
  const [rating, setRating] = useState<number | null>();
  const [comment, setComment] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const { loggedInUser } = useSelector(selectAuthSlice);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  const handleSubmit = async () => {
    if (type === "STOP") {
      if (!rating || !comment || !reason) {
        return toast.error("Please enter all fields!");
      }
      try {
        await instance.post("/kf/user/subscription/return", {
          user_code: loggedInUser.userid,
          vehicle: subscriptionData.Vehicle_Registered_No,
          type: subscriptionData.Vehicle_Type,
          name: `${subscriptionData.Make} ${subscriptionData.Vehicle_Name}`,
          color: subscriptionData.Color,
          ops: "Return",
          duration: 1,
          rating: rating,
          comment: comment,
          reason: reason,
        });
        toast.success("Stop subscription request successfully sent!");
        close(false);
      } catch (error: any) {
        toast.error(error.message);
      }
    }

    if (type === "RENEW") {
      if (!rating || !comment) {
        return toast.error("Please enter all fields!");
      }
      try {
        const { data } = await instance.post("/kf/user/subscription/renew", {
          user_code: loggedInUser.userid,
          vehicle: subscriptionData.Vehicle_Registered_No,
          type: subscriptionData.Vehicle_Type,
          name: `${subscriptionData.Make} ${subscriptionData.Vehicle_Name}`,
          color: subscriptionData.Color,
          ops: "Return",
          duration: 1,
          rating: rating,
          comment: comment,
        });
        toast.success("Successfully Submitted!");
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "100%", lg: "500px" },
        p: "10px 20px",
      }}
    >
      <Stack
        p="20px 0px"
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        {type === "STOP" ? (
          <Typography
            variant="h4"
            lineHeight="1.3em"
            sx={{
              fontSize: { xs: "16px", md: "25px" },
            }}
          >
            Stop Subscription
          </Typography>
        ) : (
          <Typography
            variant="h4"
            lineHeight="1.3em"
            sx={{
              fontSize: { xs: "16px", md: "25px" },
            }}
          >
            Renew Subscription
          </Typography>
        )}
        <Close
          sx={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => onClose()}
        />
      </Stack>

      <Stack direction="column" rowGap={1.5}>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "14px", md: "18px" },
          }}
        >
          Rating
        </Typography>
        <Rating
          value={rating}
          onChange={(event, newValue) => setRating(newValue)}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "14px", md: "18px" },
          }}
        >
          Comment
        </Typography>
        <TextField
          required
          size="small"
          variant="outlined"
          name="comment"
          color="success"
          autoComplete="new-password"
          multiline
          rows={5}
          onChange={(e) => setComment(e.target.value as string)}
          value={comment}
        />
        <Typography variant="body1" fontSize="14px">
          Please give us honest feedback on why you'd like to leave SWYTCHD.
          Don't worry, we take every feedback as important, so please do be as
          open and descriptive as you can.
        </Typography>
        {type === "STOP" && (
          <>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "14px", md: "18px" },
              }}
            >
              Reason
            </Typography>
            <TextField
              id="select-reason"
              value={reason}
              fullWidth
              select
              onChange={(event) => setReason(event.target.value as string)}
              color="success"
            >
              {reasonArray.map((el: string, key: number) => {
                return (
                  <MenuItem key={key} value={el}>
                    {el}
                  </MenuItem>
                );
              })}
            </TextField>
          </>
        )}
        <Box p="20px 0px">
          <ButtonVariant1
            sx={{
              bgcolor: (theme) => theme.palette.green.main,
            }}
            onClick={handleSubmit}
          >
            Submit
          </ButtonVariant1>
        </Box>
      </Stack>
    </Box>
  );
};

/**
 * Subscription Details Drawer Component
 */
const SubscriptionDetails = ({ details, onClose }: any) => {
  const [distance, setDistance] = useState<any>();
  const [batteryStatus, setBatteryStatus] = useState<any>();
  const [location, setLocation] = useState<{ lat: string; lon: string }>({
    lat: "",
    lon: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { loggedInUser } = useSelector(selectAuthSlice);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  const getVehicleId = async () => {
    setIsLoading(true);
    const vehicleId = await instance.get(
      `/aquila/vehicle/${details.Vehicle_Registered_No}`
    );
    setIsLoading(false);
    return vehicleId.data.data.getVehicleDetailAPI.device.uniqueDeviceId;
  };

  const getDistanceTravelled = async (
    vehicleId: string,
    f: number,
    t: number
  ) => {
    setIsLoading(true);
    const distance = await instance.get(
      `/aquila/vehicle/distance/${vehicleId}/${f}/${t}`
    );
    setIsLoading(false);
    return distance.data;
  };

  const getBatteryStatus = async (id: string) => {
    setIsLoading(true);
    const { data } = await instance.get(`/aquila/vehicle/obd/${id}`);
    setIsLoading(false);
    return data?.data?.getLatestOBDData?.com_soc?.value;
  };

  const getLocation = async (id: string) => {
    setIsLoading(true);
    const { data } = await instance.get(
      `/aquila/vehicle/location/vehicleid/${id}`
    );
    setIsLoading(false);
    return [
      data.data.getDeviceLatestLocation.latitude,
      data.data.getDeviceLatestLocation.longitude,
    ];
  };

  const openGoogleMaps = () => {
    const latitude = Number(location.lat);
    const longitude = Number(location.lon);
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    //replace \n with ""

    let from = details.Vehicle_Delivery_Date.replace(/\n/g, "");
    let to = details.Vehicle_Due_Date.replace(/\n/g, "");

    (async () => {
      const id = await getVehicleId();
      const distance = await getDistanceTravelled(id, from, to);

      distance ? setDistance(distance) : setDistance("-");
      const status = await getBatteryStatus(id);
      setBatteryStatus(status ?? 0);
      const [la, lo] = await getLocation(id);
      setLocation({
        lat: la,
        lon: lo,
      });
    })();
  }, []);

  if (!details._id) return <></>;

  return (
    <Box
      sx={{
        width: { xs: "350px", sm: "350px", lg: "500px" },
        p: "10px 20px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        {isLoading && (
          <CircularProgress
            sx={{
              "&.MuiCircularProgress-colorPrimary": {
                color: (theme) => theme.palette.green.light,
              },
              "&.MuiCircularProgress-root": {
                width: "60px !important",
                height: "60px !important",
                position: "absolute",
                top: "50%",
                left: "40%",
              },
            }}
          />
        )}
        <Box p="20px 0px">
          <Typography variant="body1" fontWeight="500">
            {`${details.Make} ${details.Vehicle_Name}`}
          </Typography>
          <Box p="10px 0px">
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{
                color: (theme) => theme.palette.green.main,
                fontStyle: "italic",
              }}
            >
              {`${details.Vehicle_Registered_No}`} /{" "}
              {`${details.Vehicle_Nick_Name}`}
            </Typography>
          </Box>
        </Box>
        <Close
          sx={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => onClose()}
        />
      </Stack>
      <Stack direction="row" justifyContent="center" gap={12}>
        {distance ? (
          <Stack direction="column" alignItems="center" rowGap="10px">
            <Box
              sx={{
                background: "#000",
                borderRadius: "50%",
                position: "relative",
                p: "5px 5px 0px 5px",
              }}
            >
              <CircularProgress
                variant="determinate"
                value={distance ? (100 / 1500) * parseInt(distance) : 0}
                sx={{
                  "&.MuiCircularProgress-root": {
                    color: (theme) => theme.palette.green.main,
                  },
                }}
                size={90}
              />

              {distance === "-" ? (
                <Typography
                  sx={{
                    position: "absolute",
                    top: "55%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#fff",
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    position: "absolute",
                    top: "55%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#fff",
                  }}
                  variant="body1"
                >
                  {distance && parseInt(distance)}
                </Typography>
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: "33%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Speed sx={{ color: "#fff" }} />
              </Box>
            </Box>
            {
              <Typography sx={{ fontSize: "14px" }} fontWeight="400">
                KM Travelled
              </Typography>
            }
          </Stack>
        ) : (
          <Skeleton height="100px" variant="rounded" />
        )}
        {batteryStatus >= 0 ? (
          <Stack direction="column" alignItems="center" rowGap="10px">
            <Box
              sx={{
                background: "#000",
                borderRadius: "50%",
                position: "relative",
                p: "5px 5px 0px 5px",
              }}
            >
              <CircularProgress
                variant="determinate"
                value={batteryStatus ? batteryStatus : 0}
                sx={{
                  "&.MuiCircularProgress-root": {
                    color: (theme) => theme.palette.green.main,
                  },
                }}
                size={90}
              />

              <Typography
                sx={{
                  position: "absolute",
                  top: "55%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#fff",
                  textAlign: "center",
                }}
                variant="body1"
              >
                {batteryStatus ? batteryStatus : "-"}
              </Typography>

              <Box
                sx={{
                  position: "absolute",
                  top: "33%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(90deg)",
                }}
              >
                <BatteryChargingFull
                  sx={{
                    color: "#fff",
                  }}
                />
              </Box>
            </Box>
            {!batteryStatus ? (
              <Typography
                sx={{ fontSize: "14px" }}
                fontWeight="400"
                textAlign="center"
              >
                Battery Status <br></br> Not Available
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "14px" }} fontWeight="400">
                Battery Status
              </Typography>
            )}
          </Stack>
        ) : (
          <Skeleton height="100px" variant="rounded" />
        )}
      </Stack>

      <Box p="30px 0px">
        {location.lat && location.lon ? (
          <Box onClick={openGoogleMaps}>
            <MapContainer
              style={{
                height: "300px",
                backgroundColor: "#gray",
              }}
              center={[Number(location.lat), Number(location.lon)]}
              zoom={17}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[Number(location.lat), Number(location.lon)]}
              ></Marker>
            </MapContainer>
          </Box>
        ) : (
          <Skeleton height="380px" variant="rectangular" />
        )}
      </Box>
      <Typography variant="subtitle1">
        {location.lat != null ? "This is your Last known location" : ""}
      </Typography>
      <Typography
        sx={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
        variant="body1"
      >
        {location.lat == null ? "Location not available" : ""}
      </Typography>
      {/* <Stack direction="row" justifyContent="space-between" gap={"5px"} p="10px 0px 10px 0px">
        <ButtonVariant2 sx={{
          borderColor: (theme) => theme.palette.green.main,
          color: (theme) => theme.palette.green.main
        }}>Unlock</ButtonVariant2>
        <ButtonVariant1 sx={{
          bgcolor: (theme) => theme.palette.green.main,
        }}>Lock</ButtonVariant1>
      </Stack> */}
    </Box>
  );
};

/**
 * Renew Subscription Alert
 */

const RenewSubscriptionAlert = ({ subscriptionData, onClose }: any) => {
  const { loggedInUser } = useSelector(selectAuthSlice);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  const renew = async () => {
    try {
      const { data } = await instance.post("/kf/user/subscription/renew", {
        user_code: loggedInUser.userid,
        vehicle: subscriptionData.Vehicle_Registered_No,
        type: subscriptionData.Vehicle_Type,
        name: `${subscriptionData.Make} ${subscriptionData.Vehicle_Name}`,
        color: subscriptionData.Color,
        ops: "Renew",
        duration: 1,
        rating: "",
        comment: "",
      });
      toast.success("Subscription renewal request successfully sent!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <Box p="10px">
      <Typography
        variant="body1"
        sx={{
          color: (theme) => theme.palette.green.main,
          textAlign: "center",
          pb: "10px",
        }}
      >
        RENEW SUBSCRIPTION
      </Typography>

      <Typography variant="body1" fontSize="15px">
        Looks like you want to renew your vehicle subscription, Please confirm
        to submit renewal request
      </Typography>

      <Stack direction="row" columnGap="20px" pt="20px">
        <ButtonVariant2
          sx={{
            borderColor: (theme) => theme.palette.green.main,
            color: (theme) => theme.palette.green.main,
            padding: "5px 10px",
          }}
          onClick={() => onClose()}
        >
          CANCEL
        </ButtonVariant2>

        <ButtonVariant1
          sx={{
            bgcolor: (theme) => theme.palette.green.main,
            padding: "5px 10px",
          }}
          onClick={() => {
            renew();
            onClose();
          }}
        >
          CONFIRM
        </ButtonVariant1>
      </Stack>
    </Box>
  );
};

/**
 * Main Subscription Management Page
 */
const SubscriptionManagementPage = () => {
  const navigate = useNavigate();

  const [subscriptionData, setSubscriptionData] = useState([]);

  const [details, setDetails] = useState<any>({});

  const [open, setOpen] = useState<boolean>(false);
  const [openRenewDrawer, setOpenRenewDrawer] = useState<boolean>(false);
  const [openStopDrawer, setOpenStopDrawer] = useState<boolean>(false);
  const [openSwitchVehicleDrawer, setOpenSwitchVehicleDrawer] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const windowWidth = useWindowWidth();

  const { loggedInUser } = useSelector(selectAuthSlice);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      //KF takes only phone number without country code
      // Last 10 digits of your phone number slice function
      let { data } = await instance.get(
        `/kf/user/subscription/${loggedInUser.phone.slice(-10)}`
      );
      setSubscriptionData(data);
      setLoading(false);
    })();
  }, []);

  const handleDrawer = (id: string) => {
    if (!open) {
      const _ = subscriptionData.filter((el: any) => el._id === id);
      setDetails(_[0]);
      setOpen(true);
    } else {
      setDetails({});
      setOpen(false);
    }
  };

  const handleRenewDrawer = (id: string) => {
    if (!openRenewDrawer) {
      const _ = subscriptionData.filter((el: any) => el._id === id);
      setDetails(_[0]);
      setOpenRenewDrawer(true);
    } else {
      setDetails({});
      setOpenRenewDrawer(false);
    }
  };

  const handleStopDrawer = (id: string) => {
    if (!openStopDrawer) {
      const _ = subscriptionData.filter((el: any) => el._id === id);
      setDetails(_[0]);
      setOpenStopDrawer(true);
    } else {
      setDetails({});
      setOpenStopDrawer(false);
    }
  };

  const handleSwitchDrawer = (id: string) => {
    if (!openSwitchVehicleDrawer) {
      const _ = subscriptionData.filter((el: any) => el._id === id);
      setDetails(_[0]);
      setOpenSwitchVehicleDrawer(true);
    } else {
      setDetails({});
      setOpenSwitchVehicleDrawer(false);
    }
  };

  const getVehicleDocuments = async (type: "insurance" | "rc", id: string) => {
    const _: any = subscriptionData.filter((el: any) => el._id === id);

    const { data } = await instance.get(
      `/kf/vehicle/data/${_[0].Vehicle_Registered_No}`
    );

    if (type === "insurance") {
      let link = data[0].Insurance_Document;
      if (link === "null") {
        return toast.error("Document not found!");
      }
      window.open(link, "blank");
    }

    if (type === "rc") {
      let link = data[0].RC_Document;
      if (link === "null") {
        return toast.error("Document not found!");
      }
      window.open(link, "blank");
    }
  };

  return (
    <>
      <Helmet>
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Stack
        sx={{ p: { xs: "20px 18px", lg: "26px 80px" } }}
        direction="row"
        justifyContent="space-between"
      >
        <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
        <Box>
          {windowWidth > 900 ? (
            <Header color="var(--color-variant-black)" />
          ) : (
            <HamburgerDrawer variant="black" />
          )}
        </Box>
      </Stack>
      {subscriptionData.length > 0 ? (
        <Stack
          sx={{ p: { xs: "10px 0px", lg: "10px 0px" } }}
          direction="column"
        >
          <Typography
            sx={{
              p: {
                xs: "10px 1.4rem",
                md: "10px 5rem",
              },
              fontSize: {
                xs: "20px",
                md: "30px",
              },
            }}
            variant="h2"
          >
            Subscriptions
          </Typography>
          <Typography
            sx={{
              p: {
                xs: "10px 1.4rem",
                md: "10px 5rem",
              },
              fontSize: {
                xs: "10px",
                md: "14px",
              },
            }}
          >
            Options for renew, switch and stop will become available 10 days
            before subscription end date.
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
      <Grid
        container
        rowSpacing="1rem"
        sx={{
          p: "1em 0em",
          justifyContent: {
            xl: "flex-start",
            lg: "flex-start",
            sm: "center",
          },
          rowGap: "4rem",
        }}
      >
        {!loading ? (
          subscriptionData.length > 0 ? (
            subscriptionData.map((el: any, id) => {
              return (
                <Grid
                  key={id}
                  item
                  xl={4}
                  md={6}
                  lg={4}
                  sx={{
                    p: { xs: "20px 18px", lg: "26px 80px" },
                  }}
                >
                  <LargeSubscriptionCard
                    requestVehicleDoc={(type, id) =>
                      getVehicleDocuments(type, id)
                    }
                    subscriptionData={el}
                    onButtonClick={(id) => handleDrawer(id)}
                    onRenewClick={(id) => handleRenewDrawer(id)}
                    onStopClick={(id) => handleStopDrawer(id)}
                    onSwitchClick={(id) => handleSwitchDrawer(id)}
                  />
                </Grid>
              );
            })
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -90%)",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: "1.2rem",
                    md: "1.5rem",
                    lg: "2rem",
                  },
                }}
              >
                No Subscriptions Found
              </Typography>
              <Stack direction="row" columnGap="20px" p="20px 0px">
                <ButtonVariant1
                  sx={{
                    bgcolor: (theme) => theme.palette.green.main,
                  }}
                  onClick={() => {
                    navigate("/electric-bikes-subscription");
                  }}
                >
                  VIEW BIKES
                </ButtonVariant1>
                <ButtonVariant1
                  sx={{
                    bgcolor: (theme) => theme.palette.green.main,
                  }}
                  onClick={() => {
                    navigate("/electric-cars-subscription");
                  }}
                >
                  VIEW CARS
                </ButtonVariant1>
              </Stack>
            </Box>
          )
        ) : (
          <>
            <Grid
              item
              xl={4}
              md={6}
              lg={6}
              sx={{ p: { xs: "20px 18px", lg: "26px 80px" } }}
            >
              <StyledSkeleton
                variant="rectangular"
                animation="wave"
                width={300}
                height={300}
              />
              <StyledSkeletonText
                variant="text"
                animation="wave"
                width={300}
                height={100}
              />
            </Grid>
            <Grid
              item
              xl={4}
              md={6}
              lg={6}
              sx={{ p: { xs: "20px 18px", lg: "26px 80px" } }}
            >
              <StyledSkeleton
                variant="rectangular"
                animation="wave"
                width={300}
                height={300}
              />
              <StyledSkeletonText
                variant="text"
                animation="wave"
                width={300}
                height={100}
              />
            </Grid>
            <Grid
              item
              xl={4}
              md={6}
              lg={6}
              sx={{ p: { xs: "20px 18px", lg: "26px 80px" } }}
            >
              <StyledSkeleton
                variant="rectangular"
                animation="wave"
                width={300}
                height={300}
              />
              <StyledSkeletonText
                variant="text"
                animation="wave"
                width={300}
                height={100}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Drawer anchor="right" open={open}>
        <SubscriptionDetails details={details} onClose={() => setOpen(false)} />
      </Drawer>
      <Drawer anchor="right" open={openRenewDrawer}>
        <StopSubscription
          type="STOP"
          subscriptionData={details}
          onClose={() => setOpenRenewDrawer(false)}
          close={(c: any) => setOpenRenewDrawer(c)}
        />
      </Drawer>
      {/* <Drawer anchor="right" open={openStopDrawer}> */}
      {/*   <StopSubscription type="RENEW" subscriptionData={details} onClose={() => setOpenStopDrawer(false)} /> */}
      {/* </Drawer> */}
      <Dialog open={openStopDrawer}>
        <RenewSubscriptionAlert
          subscriptionData={details}
          onClose={() => setOpenStopDrawer(false)}
        />
      </Dialog>
      <Drawer anchor="right" open={openSwitchVehicleDrawer}>
        <SwitchVehicle
          subscriptionData={details}
          onClose={() => setOpenSwitchVehicleDrawer(false)}
          close={(c: any) => setOpenSwitchVehicleDrawer(c)}
        />
      </Drawer>
    </>
  );
};

export default SubscriptionManagementPage;
