import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialState {
  start: boolean;
  carsData: any;
  bikesData: any;
  vehicleModels: any;
  showModal: boolean;
}

const initialState: InitialState = {
  start: false,
  carsData: [],
  bikesData: [],
  vehicleModels: [],
  showModal: false,
};

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.carsData = action.payload.cars;
      state.bikesData = action.payload.bikes;
      state.vehicleModels = [
        {
          ...state.bikesData[0],
          Name: "Select Model",
        },
        {
          ...state.bikesData[1],
          Name: "Select Model",
        },
      ]
    },
    startCompare: (state) => {
      state.start = true;
    },
    restartCompare: (state) => {
      state.start = false;
      state.vehicleModels = [
        {
          ...state.bikesData[0],
          Name: "Select Model",
        },
        {
          ...state.bikesData[1],
          Name: "Select Model",
        },
      ]
      // state.carsData = carData;
      // state.bikesData = bikeData;
    },
    setVehicleModel: (state, action) => {
      if (state.vehicleModels[0].Name === "Select Model") {
        state.vehicleModels[0] = action.payload;
      } else if (state.vehicleModels[1].Name === "Select Model") {
        state.vehicleModels[1] = state.vehicleModels[0];
        state.vehicleModels[0] = action.payload;
      } else {
        state.vehicleModels = [action.payload, ...state.vehicleModels];
      }
    },
    toggleShowModal: (state) => {
      state.showModal = !state.showModal;
    },
    updateCarsBikesData: (state) => {
      state.carsData = state.carsData.filter(
        (obj1: any) => !state.vehicleModels.find((obj2: any) => obj1.Name === obj2.Name)
      );

      state.bikesData = state.bikesData.filter(
        (obj1: any) => !state.vehicleModels.find((obj2: any) => obj1.Name === obj2.Name)
      );
    },
  },
});

export const selectCompareSlice = (state: RootState) => state.compare;

export const {
  setData,
  startCompare,
  setVehicleModel,
  restartCompare,
  toggleShowModal,
  updateCarsBikesData,
} = compareSlice.actions;

export default compareSlice.reducer;
