import { ICostList } from "../types/interfaces";

export const COST_LIST: ICostList = {
  CARS: {
    name: "Tata Nexon EV",
    subscribe: {
      deposit: "39,999",
      monthly_subscription: "39,999",
      commitment_duration: "1 month",
      insurance: "0",
      servicing: "0",

      add_ons: "0",
      total: "39,999",
    },

    buy: {
      deposit: "1,69,500",
      monthly_subscription: "47,471",
      commitment_duration: "3 years",
      insurance: "800",
      servicing: "800",

      add_ons: "1,500",
      total: "52,771",
    },
  },

  BIKES: {
    name: "some bike",
    subscribe: {
      deposit: "somedata",
      monthly_subscription: "somedata",
      commitment_duration: "1 month",
      insurance: "0",
      servicing: "0",

      add_ons: "0",
      total: "somedata",
    },

    buy: {
      deposit: "somedata",
      monthly_subscription: "somedata",
      commitment_duration: "3 years",
      insurance: "somedata",
      servicing: "somedata",

      add_ons: "somedata",
      total: "somedata",
    },
  },
};

export const COST_TYPE: string[] = [
  "Deposit/Downpayment",
  "Monthly Subscription/EMI",
  "Commitment Duration",
  "Insurance",
  "Maintenance & Servicing",

  "Add ons",
  "Total Monthly Cost",
];

export const COST_TYPE_SHORT: string[] = [
  "Deposit/Downpayment",
  "Monthly Rental/EMI",
  "Commitment Duration",
  "Insurance",
  "Servicing",

  "Add ons",
  "Total Monthly Cost",
];
