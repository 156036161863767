import { Box, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  FailureAlert,
  SuccessModal,
} from "../../components/Alerts/JoinwaitlistAlert";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import ProgressCircle from "../../components/Progress/ProgressCircle";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { selectJoinWaitlistSlice } from "../../redux/joinWaitlist/joinWaitlistSlice";
import JoinWaitlistForm from "./JoinWaitlistForm";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.black.light,
  padding: "26px 60px",
  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
    padding: "26px 20px",
  },
}));

const JoinWaitlistPage = () => {
  const windowWidth = useWindowWidth();
  const { isLoading } = useSelector(selectJoinWaitlistSlice);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Book Your EV Subscription Now </title>
        <meta name="description" content="Book SWYTCHD subscription plans starting at just Rs. 4,200. Our electric vehicle subscription service offers the perfect solution. " />
        <meta name="keywords" content="EV Subscription, Book Electric Vehicle, Book EV Subscription, Bike rental service, Car rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      {isLoading ? <ProgressCircle open={isLoading} /> : <SuccessModal />}
      <FailureAlert />
      <Box id="ovf">
        <Container>
          <Stack direction="row" justifyContent="space-between">
            <SwytchdLogo color="var(--color-variant-white)" />
            <Box>
              {windowWidth > 769 ? (
                <Header color="var(--color-variant-white)" />
              ) : (
                <HamburgerDrawer variant="white" />
              )}
            </Box>
          </Stack>

          <Box p="42px 0px 60px 0px">
            <Typography
              color="#fff"
              variant="h6"
              sx={{ fontSize: { sm: "20px", md: "24px" } }}
            >
              SWYTCH TODAY!
            </Typography>
            <Stack
              mt="56px"
              sx={{ textAlign: { sm: "left", md: "center" } }}
              direction="column"
              spacing={1}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "24px" },
                  color: "#fff",
                  fontWeight: 400,
                  width: { xs: "100%", lg: "85%" },
                  margin: "0 auto",
                }}
                variant="body1"
              >
                You're not making any big commitment or large financial
                decision.
                <Typography
                  sx={{
                    fontSize: { xs: "14px", md: "24px" },
                    color: "#fff",
                    fontWeight: 400,
                    width: { xs: "100%", lg: "85%" },
                    margin: "0 auto",
                    display: "block",
                  }}
                  variant="body1"
                  component="span"
                >
                  We take a small
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "24px" },
                      color: (theme) => theme.palette.green.light,
                      fontWeight: 400,
                      fontStyle: "italic",
                    }}
                    m="0px 6px"
                    component="span"
                    variant="body2"
                  >
                    fully refundable
                  </Typography>
                  booking amount. This is only to secure your booking.
                </Typography>
              </Typography>

              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.green.light,
                  fontSize: { xs: "14px", md: "24px" },
                })}
              >
                Leave it anytime and no hard feelings!
              </Typography>

              <Typography
                pt="30px"
                variant="h3"
                color="#aaa"
                sx={{ fontSize: { xs: "12px", md: "16px" } }}
              >
                Also folks, as of now the SWYTCHD subscription is only for
                personal use.
              </Typography>

              <Typography
                variant="h3"
                color="#aaa"
                sx={{ fontSize: { xs: "12px", md: "16px" } }}
              >
                SWYTCHD EVs cannot be utilized for commercial purposes like
                deliveries and similar business operations.
              </Typography>
            </Stack>

            <JoinWaitlistForm />
          </Box>
        </Container>

        <Footer />
      </Box>
    </>
  );
};

export default JoinWaitlistPage;
