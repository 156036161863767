import { Box, Divider, styled, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import {
  startCompare,
  toggleShowModal,
} from "../../redux/compare/compareSlice";
import store from "../../redux/store";
import { IBikeData, ICarData } from "../../types/interfaces";

interface IProp {
  vehicleModels: any;
}

const Selector = styled(Box)(({ theme }) => ({
  background: theme.palette.white.main,
  borderRadius: "48px",
  boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
  padding: "10px 8px 10px 32px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "800px",
  overflowY: "hidden",
  scrollbarWidth: "none",
  position: "relative",
  "::-webkit-scrollbar": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "350px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "270px",
  },
}));

const AddIcon = styled("img")(({ theme }) => ({
  marginLeft: "28px",
  cursor: "pointer",
  position: "sticky",
  right: 0,
  background: theme.palette.white.main,
  borderRadius: "50%",
}));

const SelectModel: React.FC<IProp> = ({ vehicleModels }) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  return (
    <Selector {...events} ref={ref}>
      {vehicleModels.map((model: any, key: any) => {
        return (
          <Fragment key={key}>
            <Typography
              component="p"
              onClick={() => {
                if (model.Name === "Select Model") {
                  store.dispatch(startCompare());
                  store.dispatch(toggleShowModal());
                }
              }}
              variant="h6"
              sx={{
                minWidth: "fit-content",
                cursor: "pointer",
                fontSize: { lg: "20px", xs: "14px" },
              }}
            >
              {model.Name.split("/")[0]}
            </Typography>
            {key < vehicleModels.length - 1 && (
              <Divider
                orientation="vertical"
                sx={{
                  border: "1px solid #AAAAAA",
                  margin: "0px 20px",
                  height: "40px",
                  alignSelf: "center",
                }}
                flexItem
              />
            )}
          </Fragment>
        );
      })}
      <AddIcon
        onClick={() => {
          store.dispatch(startCompare());
          store.dispatch(toggleShowModal());
        }}
        src="/images/add-circle.svg"
        alt="add"
      />
    </Selector>
  );
};

export default SelectModel;
