import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";

const ExpandMoreIcon = () => {
  return <img src="images/expand-more-icon.svg" alt="expand more" />;
};

const FaqTopicContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "15px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "12px",
  },
}));

const FaqTopic = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.green.light,
  fontSize: "20px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

const FaqSubTopic = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

const Hr = styled(Divider)(({ theme }) => ({
  color: theme.palette.white.main,
  border: "1px solid #ededed",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    backgroundColor: theme.palette.black.light,
    borderRadius: "15px",
    padding: "10px 15px",
  },
  "&.MuiAccordionSummary-root": {
    backgroundColor: theme.palette.black.light,
  },
  [theme.breakpoints.down("md")]: {
    "&.MuiAccordion-root": {
      padding: "3px 0px",
    },
  },
}));

const FaqQuestion = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#fff",
  fontSize: "20px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

const FaqAnswer = styled(Typography)(({ theme }) => ({
  color: "#aaa",
  fontSize: "16px",
  lineHeight: "30px",
  fontWeight: 300,
  "& li": {
    listStyleType: "unset",
    marginLeft: "5px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

const FAQ = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const windowWidth = useWindowWidth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Frequently Asked Questions </title>
        <meta name="description" content="SWYTCHD subscription includes the following: Servicing and general maintenance, Breakdown cover, Fair wear & tear.." />
        <meta name="keywords" content="EV Subscription, Book Electric Vehicle, EV Plans, Car rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Box sx={{ bgcolor: (theme) => theme.palette.black.main }}>
        <Stack
          sx={{
            p: { xs: "26px 22px", md: "26px 80px" },
          }}
          direction="row"
          justifyContent="space-between"
        >
          <SwytchdLogo color="var(--color-variant-white)" />
          <Box>
            {windowWidth > 900 ? (
              <Header color="var(--color-variant-white)" />
            ) : (
              <HamburgerDrawer variant="white" />
            )}
          </Box>
        </Stack>

        <Box
          sx={{
            p: { xs: "26px 22px", md: "26px 80px" },
            mt: { xs: "-20px", md: "18px" },
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: "20px", md: "30px" }, color: "#fff" }}
          >
            FAQs
          </Typography>

          <Stack
            direction="column"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "42px" },
            }}
          >
            <FaqTopicContainer>
              <FaqTopic variant="h3">WHAT'S INCLUDED</FaqTopic>
              <Hr flexItem orientation="vertical" />
              <FaqSubTopic variant="h5">
                Literally everything and it's hassle-free.
              </FaqSubTopic>
            </FaqTopicContainer>

            <StyledAccordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <FaqQuestion variant="h3">
                  What all is included in the SWYTCHD subscription?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  Our SWYTCHD subscription includes the following:
                  <li>Servicing and general maintenance</li>
                  <li>Breakdown cover</li>
                  <li>
                    Fair wear & tear (in accordance with industry guidelines)
                  </li>
                  <li>1500 km per month</li>
                  <li>Fully comprehensive insurance</li>
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>

          <Stack
            direction="column"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "42px" },
            }}
          >
            <FaqTopicContainer>
              <FaqTopic variant="h3">WHAT'S NOT INCLUDED</FaqTopic>
              <Hr flexItem orientation="vertical" />
              <FaqSubTopic variant="h5">Only a few things!</FaqSubTopic>
            </FaqTopicContainer>

            <StyledAccordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <FaqQuestion variant="h3">
                  What all is not included in the SWYTCHD subscription?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  The following is not included in the subscription:
                  <li>
                    Any fees, fines, or penalties incurred whilst under
                    subscription (Speeding tickets, charging overstay fees, etc){" "}
                  </li>
                  <li>
                    Any damage you do to the vehicles that fall outside of our
                    wear & tear guidelines
                  </li>
                  <li>
                    Additional named drivers (2 drivers are included in the
                    subscription) you can buy additional drivers as a bolt-on
                  </li>
                  <li>
                    Additional mileage (you can purchase this as a bolt-on)
                  </li>
                  <li>If you run out of charge as a result of your planning</li>
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>

          <Stack
            direction="column"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "22px" },
            }}
          >
            <FaqTopicContainer sx={{ mb: { xs: "0px", md: "20px" } }}>
              <FaqTopic variant="h3">Delivery</FaqTopic>
              <Hr flexItem orientation="vertical" />
              <FaqSubTopic variant="h5">Easy-peasy!</FaqSubTopic>
            </FaqTopicContainer>

            <StyledAccordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <FaqQuestion variant="h3">
                  Do we offer free delivery?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  From the warehouse location, Up to 20 km free delivery, 20-25 kms Rs 300, More than 25kms Rs
                  500.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>

            <StyledAccordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <FaqQuestion variant="h3">
                  Can I schedule the delivery of my SWYTCHD EV at my
                  convenience?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  We are happy to accommodate a convenient time for you.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>

            <StyledAccordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <FaqQuestion variant="h3">
                  What happens on the day of delivery?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  Your driver will call to provide an ETA close to the time of
                  delivery. Due to reasons out of our control, there are a few
                  cases where your vehicle may be a bit delayed. If this
                  happens, we will give you a call to keep you informed!
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>

            <StyledAccordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <FaqQuestion variant="h3">
                  Do I need to be present for the delivery?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  Either the main subscription holder or a verified named driver
                  must be present for the delivery of the vehicle. They must
                  also have their driving licence for proof of identity.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>

          <Stack
            direction="column"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "42px" },
            }}
          >
            <FaqTopicContainer>
              <FaqTopic variant="h3">Payment stuff</FaqTopic>
              <Hr flexItem orientation="vertical" />
              <FaqSubTopic variant="h5">
                Simplified! and minimal deposit!
              </FaqSubTopic>
            </FaqTopicContainer>

            <StyledAccordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <FaqQuestion variant="h3">
                  How and when do I pay for my SWYTCHD vehicles? When am I
                  charged for my subscription?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  You pay when we deliver! Also, minimal security deposit.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>

          <Stack
            direction="column"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "42px" },
            }}
          >

            <StyledAccordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <FaqQuestion variant="h3">
                  Is charging included for SWYTCHD vehicles?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
          
                <FaqAnswer>
                  All our electric vehicles come with two chargers: a Standard
                  3-pin domestic charger for home usage, and a Type-2 charger
                  (for cars only). Please keep in mind to not use an extension
                  cable whilst charging your vehicles.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  A standard 3 pin connector will charge your vehicle fully in
                  8-24 hours (cars).
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  Type 2 charges connect to wall boxes or fast chargers. Type 2
                  charging takes between 4-8 hours to do a full charge. SWYTCHD
                  provides a wallbox for free to install in your home (for cars
                  only) up to a wiring of 15m. Any more wiring and ancillary
                  costs will have to be bourne by you.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  For apartments, you will need permission form the building for
                  us to be able to install this in your parking spot.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  At Rapid charge locations CCS cables are available to quickly
                  charge your vehicles usually for a maximum of 1 hour. These
                  are available in public locations.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>

          <Stack
            direction="column"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "42px" },
            }}
          >
            <FaqTopicContainer>
              <FaqTopic variant="h3">Eligibility criteria</FaqTopic>
              <Hr flexItem orientation="vertical" />
              <FaqSubTopic variant="h5">Not 10 pages of KYC!</FaqSubTopic>
            </FaqTopicContainer>

            <StyledAccordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <FaqQuestion variant="h3">
                  How can I join SWYTCHD / what are the eligibility criteria?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  You need to be between 18 &#8211; 85 years old, along with the
                  following requirements:
                  <li>Full Driving Licence: 1 year</li>
                  <li>Not banned from driving within the last five years.</li>
                  <li>All criminal convictions must be referred.</li>
                  We also accept drivers with International Driving Permits.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>

          <Stack
            direction="column"
            mb="30px"
            sx={{
              mt: { xs: "22px", md: "72px" },
              rowGap: { xs: "23px", md: "22px" },
            }}
          >
            <FaqTopicContainer sx={{ mb: { xs: "0px", md: "20px" } }}>
              <FaqTopic variant="h3">SWYTCHING/Returning</FaqTopic>
              <Hr flexItem orientation="vertical" />
              <FaqSubTopic variant="h5">
                We know you'll want to try out more vehicles hassle-free. In the
                event you want to return the vehicle, no worries!
              </FaqSubTopic>
            </FaqTopicContainer>

            <StyledAccordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10bh-content"
                id="panel10bh-header"
              >
                <FaqQuestion variant="h3">
                  How do I swap my SWYTCHD vehicles?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  If you want to swap to a different vehicle, simply give us a
                  call or email us at customer@swytchd.com and we&#39;ll
                  organise this for you. However, keep in mind that cost will
                  depend on when you&#39;d want to return the vehicles.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  If there is less than 14 days&#39; notice given, you&#39;ll
                  have to pay the full amount, if more than 14 days&#39; notice
                  you&#39;ll only have to pay 50%.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  Also, if your swap date is before your renewal date, anything
                  left on your current vehicles will count as credit towards the
                  new vehicle&#39;s cost. If it&#39;s after your renewal date,
                  then you&#39;ll need to pay a separate invoice for the excess
                  time on the current vehicles. For example, if your renewal
                  date is on the 20th and your swap date is on the 24th,
                  you&#39;ll pay for those 4 days on the old vehicles. Also,
                  payment will only process for the new vehicles once you sign
                  the contract.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>

            <StyledAccordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
              >
                <FaqQuestion variant="h3">
                  How do I return my SWYTCHD vehicles?
                </FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>
                  If you want to return your vehicles, just call or email us and
                  we&#39;ll arrange for it to be picked up.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  Before we collect your vehicles, please inspect it for any
                  damage and if any damage is found please take a photograph and
                  report it immediately through email.
                </FaqAnswer>
                <br />
                <FaqAnswer>
                  All vehicles must have at least 80% battery available.
                </FaqAnswer>
              </AccordionDetails>
            </StyledAccordion>
          </Stack>
        </Box>
      </Box>
      <Footer variation={true} />
    </>
  );
};

export default FAQ;
