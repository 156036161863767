export let options = {
  key: `${process.env.REACT_APP_RAZORPAY_KEY_ID}`, // Enter the Key ID generated from the Dashboard
  amount: "", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  currency: "INR",
  name: "SWYTCHD PAYMENT",
  description: "SWYTCHD VEHICLE SUBSCRIPTION",
  image: "",
  order_id: "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
  handler: function (response: any) {
    alert(response.razorpay_payment_id);
    alert(response.razorpay_order_id);
    alert(response.razorpay_signature);
  },
  prefill: {
    name: "",
    email: "",
    contact: "",
  },
  notes: {
    address: "Bengaluru, India",
  },
  theme: {
    color: "#101010",
  },
};
