import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICitiesData,
  IJoinWaitlistPayload,
} from "../../types/interfaces";
import { RootState } from "../store";
// import { citiesData } from "../../data";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuthSlice } from "../../redux/auth/authSlice";

interface InitialState {
  isLoading: boolean;
  cities: ICitiesData[];
  formData: any;
  errors: any;
  selectedInterestedVehicle: any | undefined;
  selectedCity: ICitiesData | undefined;
  paymentStatus: "success" | "failure" | undefined;
  paymentResponseMsg: string;
}

const initialState: InitialState = {
  isLoading: false,
  cities: [
    {
      _id: {
        $oid: "63f329251570bf9ce21e42c8",
      },
      city: "Bengaluru",
      state: "Karnataka",
    },
  ],
  formData: {
    fullName: "",
    email: "",
    phone: "",
    interestedVehicle: undefined,
    city: "",
    jobProfession: "",
  },
  errors: {
    fullName: "",
    email: "",
    phone: "",
    interestedVehicle: "",
    city: "",
    jobProfession: "",
  },
  selectedInterestedVehicle: undefined,
  selectedCity: undefined,
  paymentStatus: undefined,
  paymentResponseMsg: "",
};

// export const addToWaitlist = createAsyncThunk(
//   "joinWaitlist/getLaunchedCities",
//   async () => {
//     try {
//       const res = await axios.get("commons/cities/launch");
//       console.log(res);
//     } catch (error: any) {
//       console.log(error);
//     }
//   }
// );

export const addToWaitlist = createAsyncThunk(
  "joinWaitlist/addToWaitlist",
  async (joinWaitlistPayload: IJoinWaitlistPayload, { rejectWithValue }) => {
    try {
      const { loggedInUser } = useSelector(selectAuthSlice);

      const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          appkey: process.env.REACT_APP_API_KEY,
          secretkey: process.env.REACT_APP_SECRET_KEY,
          Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
        },
      });
      
      const data = await instance.post("/kf/webwaitlist", joinWaitlistPayload);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const joinWaitlistSlice = createSlice({
  name: "joinWaitlist",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = {
        ...state.formData,
        [action.payload.name]: action.payload.value,
      };
    },

    updateErrors: (state, action) => {
      state.errors = action.payload;
    },

    updateSelectedInterestedVehicle: (state, action) => {
      state.selectedInterestedVehicle = action.payload;
    },

    updateSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },

    updatePaymentStatusAndResponse: (state, action) => {
      state.paymentStatus = action.payload.status;
      state.paymentResponseMsg = action.payload.response;
    },
    closeSnackbar: (state) => {
      state.paymentStatus = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToWaitlist.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addToWaitlist.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addToWaitlist.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const selectJoinWaitlistSlice = (state: RootState) => state.joinWaitlist;

export const {
  updateFormData,
  updateErrors,
  updateSelectedCity,
  updateSelectedInterestedVehicle,
  updatePaymentStatusAndResponse,
  closeSnackbar,
} = joinWaitlistSlice.actions;

export default joinWaitlistSlice.reducer;
