import { Box, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Container, Summary, Content, Topic } from "../termsAndConditions";

const Li = styled("li")(({ theme }) => ({
  listStyleType: "unset",
}));

const PrivacyPolicy = () => {
  const windowWidth = useWindowWidth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Privacy Policy </title>
        <meta name="description" content="Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights..." />
        <meta name="keywords" content="EV Subscription, Book Electric Vehicle, EV Plans, Car rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Stack
        sx={{
          p: { xs: "26px 22px", md: "26px 80px" },
        }}
        direction="row"
        justifyContent="space-between"
      >
        <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
        <Box>
          {windowWidth > 900 ? (
            <Header color="var(--color-variant-black)" />
          ) : (
            <HamburgerDrawer variant="black" />
          )}
        </Box>
      </Stack>

      <Box
        sx={{
          p: { xs: "26px 22px", md: "0px 80px" },
          mt: "50px",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: { xs: "14px", lg: "20px" } }}>
          Privacy Policy
        </Typography>

        <Content sx={{ margin: "48px 0px 0px 0px" }} variant="body1">
          Last updated: November 30, 2021
        </Content>
        <Content sx={{ margin: "0px" }} variant="body1">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Content>
        <Content sx={{ margin: "0px 0px 34px 0px" }} variant="body1">
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </Content>

        <Topic variant="body1">Interpretation</Topic>
        <Content sx={{ margin: "20px 0px 0px 0px" }} variant="body1">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Content>

        <Topic mt="48px" variant="body1">
          Definitions
        </Topic>
        <Content sx={{ margin: "20px 0px 0px 0px" }} variant="body1">
          For the purposes of this Privacy Policy:
        </Content>

        <Content sx={{ margin: "20px 0px 0px 0px" }} variant="body1">
          <Li>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </Li>
          <Li>
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to SWYTCHD MOBILITY PRIVATE LIMITED, 204, L&T
            Raintree Boulevard, Sahakarnagar, Bangalore, 560092.
          </Li>

          <Li>
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </Li>

          <Li>Country refers to: Karnataka, India</Li>

          <Li>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </Li>

          <Li>
            Personal Data is any information that relates to an identified or
            identifiable individual
          </Li>

          <Li>Service refers to the Website.</Li>

          <Li>
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </Li>

          <Li>
            Third-party Social Media Service refers to any website or any social
            network website through which a User can log in or create an account
            to use the Service.
          </Li>

          <Li>
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </Li>

          <Li>Website refers to SWYTCHD, accessible from swytchd.com</Li>

          <Li>
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </Li>
        </Content>

        <Stack
          sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}
          mt="32px"
        >
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">PERSONAL DATA</Topic>

            <Content variant="body1">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
              <Li sx={{ mt: "20px" }}>Email address</Li>
              <Li>First name and last name</Li>
              <Li>Phone number</Li>
              <Li>Address, State, Province, ZIP/Postal code, City</Li>
              <Li>Usage Data</Li>
            </Content>
          </Box>

          <Container
            sx={{
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
              pt: "42px",
            }}
          >
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              FYI, this is not for your matrimonial profile!
            </Summary>

            <Summary variant="body1">
              We collect your basic personal information only to contact or
              identify you.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">USAGE DATA</Topic>

            <Content variant="body1">
              <Li>
                Usage Data is collected automatically when using the Service.
              </Li>

              <Li>
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </Li>

              <Li>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </Li>

              <Li>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </Li>
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              For your eyes only!
            </Summary>

            <Summary variant="body1">
              We collect your registration and user profile data and also
              collect geographic location/ browser data as this enables us to
              create a more personalized experience.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">
              INFORMATION FROM THIRD-PARTY SOCIAL MEDIA SERVICES
            </Topic>

            <Content variant="body1">
              The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:
            </Content>

            <Content variant="body1">
              <Li>Google</Li>
              <Li>Facebook</Li>
              <Li>Twitter</Li>
            </Content>

            <Content variant="body1">
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account.
            </Content>

            <Content variant="body1">
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              She knows that he knows that they know
            </Summary>

            <Summary variant="body1">
              If you register or log in using third-party services, you’ve
              granted us access to the data already associated with your
              respective accounts with them.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">TRACKING TECHNOLOGIES AND COOKIES</Topic>

            <Content variant="body1">
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </Content>

            <Content variant="body1">
              <Li>
                Cookies or Browser Cookies. A cookie is a small file placed on
                Your Device. You can instruct Your browser to refuse all Cookies
                or to indicate when a Cookie is being sent. However, if You do
                not accept Cookies, You may not be able to use some parts of our
                Service. Unless you have adjusted Your browser setting so that
                it will refuse Cookies, our Service may use Cookies.
              </Li>

              <Li>
                Flash Cookies. Certain features of our Service may use local
                stored objects (or Flash Cookies) to collect and store
                information about Your preferences or Your activity on our
                Service. Flash Cookies are not managed by the same browser
                settings as those used for Browser Cookies. For more information
                on how You can delete Flash Cookies, please read "Where can I
                change the settings for disabling, or deleting local shared
                objects?" available at
                <a
                  href="
                https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_

                "
                  target="_blank"
                  style={{
                    color: "inherit",
                    marginLeft: "5px",
                  }}
                >
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
                </a>
              </Li>

              <Li>
                Web Beacons. Certain sections of our Service and our emails may
                contain small electronic files known as web beacons (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit the Company, for example, to count users who have
                visited those pages or opened an email and for other related
                website statistics (for example, recording the popularity of a
                certain section and verifying system and server integrity).
              </Li>
            </Content>

            <Content variant="body1">
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. Learn more about cookies: Cookies by Privacy
              Policies Generator.
            </Content>

            <Content variant="body1">
              We use both Session and Persistent Cookies for the purposes set
              out below:
            </Content>

            <Content
              sx={{ fontWeight: 600, margin: "0px 0px" }}
              variant="body1"
            >
              <Li>Necessary / Essential Cookies</Li>
            </Content>
            <Box ml="40px">
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Type: Session Cookies
              </Content>
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Administered by: Us
              </Content>
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </Content>
            </Box>

            <Content
              sx={{ fontWeight: 600, margin: "0px 0px" }}
              variant="body1"
            >
              <Li>Cookies Policy / Notice Acceptance Cookies</Li>
            </Content>
            <Box ml="40px">
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Type: Persistent Cookies
              </Content>
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Administered by: Us
              </Content>
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </Content>
            </Box>

            <Content
              sx={{ fontWeight: 600, margin: "0px 0px" }}
              variant="body1"
            >
              <Li>Functionality Cookies</Li>
            </Content>
            <Box ml="40px">
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Type: Persistent Cookies
              </Content>
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                Administered by: Us
              </Content>
              <Content sx={{ margin: "5px 0px" }} variant="body1">
                These Cookies allow us to remember choices You make when You use
                the Website, such as remembering your login details or language
                preference. The purpose of these Cookies is to provide You with
                a more personal experience and to avoid You having to re-enter
                your preferences every time You use the Website.
              </Content>
            </Box>
            <Content variant="body1">
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy..
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              Nom Nom Nom
            </Summary>

            <Summary variant="body1">
              To remember you, our system will give you a cookie which will help
              us analyze how our site is used and hence help us improve. This is
              completely safe, don’t worry.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">USE OF YOUR PERSONAL DATA</Topic>

            <Content variant="body1">
              The Company may use Personal Data for the following purposes:
            </Content>

            <Content variant="body1">
              <Li>
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </Li>

              <Li>
                To manage Your Account: to manage Your registration as a user of
                the Service. The Personal Data You provide can give You access
                to different functionalities of the Service that are available
                to You as a registered user.
              </Li>

              <Li>
                For the performance of a contract: the development, compliance
                and undertaking of the purchase contract for the products, items
                or services You have purchased or of any other contract with Us
                through the Service.
              </Li>

              <Li>
                To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a
                mobile application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </Li>

              <Li>
                To provide You with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.
              </Li>

              <Li>
                To manage Your requests: To attend and manage Your requests to
                Us.
              </Li>

              <Li>
                For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.
              </Li>

              <Li>
                For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.
              </Li>

              <Li>
                We may share Your personal information in the following
                situations:
              </Li>

              <Li>
                With Service Providers: We may share Your personal information
                with Service Providers to monitor and analyze the use of our
                Service, to contact You.
              </Li>

              <Li>
                For business transfers: We may share or transfer Your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all
                or a portion of Our business to another company.
              </Li>

              <Li>
                With Affiliates: We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </Li>

              <Li>
                With business partners: We may share Your information with Our
                business partners to offer You certain products, services or
                promotions.
              </Li>

              <Li>
                With other users: when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </Li>

              <Li>
                With Your consent: We may disclose Your personal information for
                any other purpose with Your consent.
              </Li>
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              Of the people, for the people, by the people
            </Summary>

            <Summary variant="body1">
              We use your personal data to contact you, provide information,
              manage your account, data analysis, all only to improve your
              experience.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">RETENTION OF YOUR PERSONAL DATA</Topic>

            <Content variant="body1">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </Content>

            <Content variant="body1">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              It's all about{" "}
              <span
                style={{
                  textDecoration: "line-through",
                }}
              >
                love
              </span>{" "}
              law
            </Summary>

            <Summary>
              We will only retain your personal data incase of any legal
              obligations or internal analysis.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">TRANSFER OF YOUR PERSONAL DATA</Topic>

            <Content variant="body1">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </Content>

            <Content variant="body1">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </Content>

            <Content variant="body1">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              With your permission!
            </Summary>

            <Summary variant="body1">
              Your personal data may be transferred to or maintained on systems
              outside your state, province or country.
            </Summary>

            <Summary variant="body1">
              Your consent to this Privacy Policy is an agreement to that
              transfer.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">DISCLOSURE OF YOUR PERSONAL DATA</Topic>

            <Content sx={{ fontWeight: 600 }} variant="body1">
              Business Transactions
            </Content>

            <Content variant="body1">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </Content>

            <Content sx={{ fontWeight: 600 }} variant="body1">
              Law enforcement
            </Content>

            <Content variant="body1">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </Content>

            <Content sx={{ fontWeight: 600 }} variant="body1">
              Other legal requirements
            </Content>

            <Content variant="body1">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </Content>

            <Content variant="body1">
              <Li>Comply with a legal obligation</Li>

              <Li>Protect and defend the rights or property of the Company</Li>

              <Li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </Li>

              <Li>
                Protect the personal safety of Users of the Service or the
                public
              </Li>

              <Li>Protect against legal liability</Li>
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              SWYTCHD sent you a message!
            </Summary>

            <Summary variant="body1">
              We are doing our best to protect your personal data from
              unauthorized access, corruption, or theft. However, please note
              that no method of transmission over the Internet, or method of
              electronic storage is 100% secure.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">SECURITY OF YOUR PERSONAL DATA</Topic>

            <Content variant="body1">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              99.9999%
            </Summary>

            <Summary variant="body1">
              While the security of Your Personal Data is the most important to
              us, it is not technically possible for us to ensure 100% security.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">CHILDREN'S PRIVACY</Topic>

            <Content variant="body1">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </Content>

            <Content variant="body1">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              13+ only
            </Summary>

            <Summary variant="body1">
              Our Service does not knowingly address or collect information from
              anyone under the age of 13.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">LINKS TO OTHER WEBSITES</Topic>

            <Content variant="body1">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </Content>

            <Content variant="body1">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              Read all policy terms carefully before clicking!
            </Summary>

            <Summary variant="body1">
              Our Service may contain links to other websites that are not
              operated by us. Please review their Privacy Policy.
            </Summary>
          </Container>
        </Stack>

        <Stack
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            gap: "54px",
            mb: { xs: "55px", lg: "0px" },
          }}
        >
          <Box
            mt="42px"
            sx={{ flexBasis: "48%", pb: { xs: "0px", lg: "330px" } }}
          >
            <Topic variant="body1">CHANGES TO THIS PRIVACY POLICY</Topic>

            <Content variant="body1">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </Content>

            <Content variant="body1">
              We will let you know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </Content>

            <Content variant="body1">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </Content>

            <Content sx={{ fontWeight: 600, mt: "100px" }} variant="body1">
              CONTACT US
            </Content>

            <Content variant="body1">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </Content>

            <Content variant="body1">By email: customer@swytchd.com</Content>
          </Box>

          <Container>
            <Summary
              sx={{
                color: (theme) => theme.palette.green.main,
                fontSize: "16px",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              Change is the only constant
            </Summary>

            <Summary variant="body1">
              We may update our Privacy Policy from time to time and will inform
              you in advance as and when we do so.
            </Summary>
          </Container>
        </Stack>
      </Box>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
