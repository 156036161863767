import styles from "./css/BackedBy.module.css";

const BackedBy = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1>BACKED BY</h1>
        <div className={styles.logo_container}>
          <img src="/images/backed-by-1.svg" alt="logo" />
          <img src="/images/backed-by-2.svg" alt="logo" />
          <img src="/images/backed-by-3.svg" alt="logo" />
          <img src="/images/backed-by-4.svg" alt="logo" />
          <img style={{
            width: "200px",
            height: "80px",
            objectFit: "cover"
          }} src="/images/cime-finance.png" alt="logo" />
          <img style={{
            width: "150px",
            height: "140px",
            objectFit: "cover"
          }} src="/images/backed-by-6.png" alt="logo" />
        </div>
      </div>
    </div>
  )
}

export default BackedBy
