import styled from '@emotion/styled'
import { Grid, Skeleton } from '@mui/material'

const StyledSkeleton = styled(Skeleton)({
  "&.MuiSkeleton-root": {
    borderRadius: "20px"
  }
})


const StyledSkeletonText = styled(Skeleton)({
  "&.MuiSkeleton-root": {
    borderRadius: "19.75px"
  }
})
const SmallVehicleListLoader = () => {
  return (
    <Grid
      container
      rowSpacing="70px"
      sx={{
        justifyContent: { xl: "flex-start", lg: "space-between" },
      }}
    >
      <Grid item xl={4} md={6} lg={6} sx={{ width: "100%" }}>
        <StyledSkeleton variant="rectangular" animation="wave" height={160} />
        <StyledSkeletonText variant="text" animation="wave" height={50} />
      </Grid>
      <Grid item xl={4} md={6} lg={6} sx={{ width: "100%" }}>
        <StyledSkeleton variant="rectangular" animation="wave" height={160} />
        <StyledSkeletonText variant="text" animation="wave" height={50} />
      </Grid>
      <Grid item xl={4} md={6} lg={6} sx={{ width: "100%" }}>
        <StyledSkeleton variant="rectangular" animation="wave" height={160} />
        <StyledSkeletonText variant="text" animation="wave" height={50} />
      </Grid>
      <Grid item xl={4} md={6} lg={6} sx={{ width: "100%" }}>
        <StyledSkeleton variant="rectangular" animation="wave" height={160} />
        <StyledSkeletonText variant="text" animation="wave" height={50} />
      </Grid>
      <Grid item xl={4} md={6} lg={6} sx={{ width: "100%" }}>
        <StyledSkeleton variant="rectangular" animation="wave" height={160} />
        <StyledSkeletonText variant="text" animation="wave" height={50} />
      </Grid>
      <Grid item xl={4} md={6} lg={6} sx={{ width: "100%" }}>
        <StyledSkeleton variant="rectangular" animation="wave" height={160} />
        <StyledSkeletonText variant="text" animation="wave" height={50} />
      </Grid>
    </Grid>
  )
}

export default SmallVehicleListLoader
