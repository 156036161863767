import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";

interface IProp {
  open: boolean;
  onClose: () => void;
  vehicleData: any;
}

const ListText = styled(ListItemText)(({ theme }) => ({
  "&.MuiListItemText-multiline": {
    display: "flex",
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "space-between",
  },
  "& .MuiListItemText-primary": {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
    color: theme.palette.black.light,
  },
  "& .MuiListItemText-secondary": {
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "22px",
    color: theme.palette.black.light,
  },
}));

const Specs: React.FC<IProp> = ({ open, onClose, vehicleData }) => {
  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <Box
        sx={{
          p: { xs: "24px 10px 40px 30px", md: "24px 26px 40px 62px" },
          width: { xs: "100vw", md: "40vw" },
        }}
      >
        <Stack
          sx={{ cursor: "pointer" }}
          direction="row"
          justifyContent="right"
        >
          <img
            onClick={onClose}
            src="/images/close_specs.svg"
            alt="close icon"
          />
        </Stack>
        <Typography mt="15px" variant="h6" fontWeight={500}>
          SPECIFICATION
        </Typography>

        <List sx={{ mt: "40px" }}>
          <ListItem disablePadding>
            <ListText
              primary="Range per full charge"
              secondary={vehicleData?.Range}
            />
          </ListItem>
          <Divider sx={{ m: "12px 0px" }} />
          <ListItem disablePadding>
            <ListText
              primary="Time taken to fully charge"
              secondary={vehicleData?.Charging_Time}
            />
          </ListItem>
          <Divider sx={{ m: "12px 0px" }} />
          <ListItem disablePadding>
            <ListText primary="Top Speed" secondary={vehicleData?.Top_Speed} />
          </ListItem>
          <Divider sx={{ m: "12px 0px" }} />

          {/* <ListItem disablePadding> */}
          {/*   <ListText */}
          {/*     primary="Fast Charging" */}
          {/*     secondary={vehicleData?.fastCharging} */}
          {/*   /> */}
          {/* </ListItem> */}
          {/* <Divider sx={{ m: "12px 0px" }} /> */}

          {/* <ListItem disablePadding> */}
          {/*   <ListText */}
          {/*     primary="Removable or Fixed Battery" */}
          {/*     secondary={vehicleData?.removableOrFixed} */}
          {/*   /> */}
          {/* </ListItem> */}
        </List>
      </Box>
    </Drawer>
  );
};

export default Specs;
