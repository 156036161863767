import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 350,
      md: 769,
      lg: 900,
      xl: 1440,
    },
  },
  palette: {
    black: {
      main: "#101010",
      light: "#1e1e1e",
    },
    white: {
      main: "#ededed",
      light: "#e2e2e2",
    },
    gray: {
      main: "#858585",
      light: "#c5c5c5",
    },
    green: {
      main: "#24ae4f",
      light: "#24ff69",
    },
    red: {
      main: "#ff0000",
      light: "#ff2424",
    },
  },
  typography: {
    fontFamily: "Lexend, sans-serif",
    h1: {
      fontFamily: "Lexend, sans-serif",
      fontWeight: 600,
      fontSize: "40px",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },

    h2: {
      fontFamily: "Lexend, sans-serif",
      fontWeight: 400,
      fontSize: "35px",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },

    h3: {
      fontFamily: "Lexend, sans-serif",
      fontWeight: 300,
      fontSize: "20px",
      lineHeight: 1.5,
      letterSpacing: "-0.01562em",
    },

    h4: {
      fontFamily: "'Fraunces', serif",
      fontWeight: 400,
      fontSize: "35px",
      lineHeight: "2.813em",
      letterSpacing: "0.02em",
    },

    h5: {
      fontFamily: "'Fraunces', serif",
      fontWeight: 400,
      fontSize: "20px",
      fontStyle: "italic",
      lineHeight: "1.3em",
      letterSpacing: "-0.01562em",
    },

    h6: {
      fontFamily: "Lexend, sans-serif",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: 1.5,
      letterSpacing: "-0.01562em",
    },

    body1: {
      fontFamily: "Lexend, sans-serif",
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: "1.3em",
      letterSpacing: "-0.01562em",
    },

    body2: {
      fontFamily: "'Fraunces', serif",
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: "1.3em",
      letterSpacing: "-0.01562em",
    },
    subtitle1: {
      fontFamily: "Lexend, sans-serif",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "1em",
      letterSpacing: "-0.01562em",
    }
  }
});
