import { Button, styled } from "@mui/material";

export const ButtonVariant1 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.green.light,
  borderRadius: "25px",
  width: "100%",
  padding: "14px 35px",
  color: theme.palette.white.main,
  fontWeight: 600,
  "&:hover": {
    backgroundColor: theme.palette.green.main,
    opacity: 0.8,
  },
}));

export const ButtonVariant2 = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  border: `1px solid ${theme.palette.green.light}`,
  width: "100%",
  padding: "14px 35px",
  color: theme.palette.green.light,
  fontWeight: 600,
  "&:hover": {
    opacity: 0.8,
  },
}));
