import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SwytchdLogo from "../components/SwytchdLogo/SwytchdLogo";

import styles from "./css/Footer.module.css";

interface IFooter {
  variation?: boolean;
}

const Footer: React.FC<IFooter> = ({ variation }) => {
  const navigate = useNavigate();


  return (
    <>
      <div
        style={{
          backgroundColor:
            variation === true ? "var(--color-variant-black-1)" : "",
        }}
        className={styles.container}
      >
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <div className={styles.toLeft}>
              <SwytchdLogo color="var(--color-variant-white)" />
              <p className={styles.location}>
                204, Tower 5, L&T Raintree Boulevard, Sahakarnagar, Bangalore,
                India, 50092
              </p>
              <div className={styles.link}>
                <Link to={"/terms-and-conditions"}>Terms of use</Link>
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </div>
            </div>

            <div className={styles.centered}>
              <p className={styles.text}>CONTACT US</p>
              <div className={styles.contact}>
                <p>customer@swytchd.com</p>
              </div>
              <div className={styles.socials}>
                <a
                  href=" https://www.linkedin.com/company/swytchd/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/linkedin.svg" alt="LinkedIn" />
                </a>
                <a
                  href="https://www.facebook.com/swytchd/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/facebook.svg" alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/swytchd/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/instagram.svg" alt="Instagram" />
                </a>

                <a
                  href="https://twitter.com/swytchd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/twitter.svg" alt="Twitter" />
                </a>
              </div>
            </div>

            <div className={styles.toRight}>
              <p className={styles.text}>QUICK LINKS</p>
              <ul>
                <li onClick={() => navigate("/")}>Home</li>
                <li onClick={() => navigate("/about-us")}>About us</li>
                <li onClick={() => navigate("/faqs")}>FAQs</li>
                {/* <li>Blog</li> */}
              </ul>
            </div>
          </div>

          <div className={styles.bottom}>
            <p>SWYTCHD Mobility Pvt. Ltd. All Rights Reserved.</p>
          </div>
        </div>
      </div>
      {variation && (
        <div style={{ height: "1px", background: "#858585" }}></div>
      )}
      <div className={styles.outro}>
        <>
          <p>
            Website Design by
            <a
              style={{ textDecoration: "none" }}
              href="https://www.poeticpotato.com"
              target="_blank"
              rel="noreferrer"
            >
              <span
                style={{
                  color: "var(--color-variant-green-1)",
                  marginLeft: "5px",
                }}
              >
                Poetic Potato
              </span>
            </a>
          </p>

          <p>
            Built by
            <a
              style={{ textDecoration: "none" }}
              href="https://www.midasminds.in"
              target="_blank"
              rel="noreferrer"
            >
              <span
                style={{
                  marginLeft: "5px",
                  color: "var(--color-variant-green-1)",
                }}
              >
                MidasMinds
              </span>
            </a>
          </p>
        </>
      </div>
    </>
  );
};

export default Footer;
