import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { Navigate } from "react-router-dom";
import { IBikeData, ICarData } from "../../types/interfaces";
import { RootState } from "../store";
// import { bikeData } from "../../data";
import axios from "axios";
import { routeSlug } from "../../utils/createRouteSlug";

interface InitialState {
  isLoading: boolean;
  carData: any;
  bikeData: any;
  allVehicleData: any;
  selectedVehicle: any;
  selectedVehicle1: IBikeData | ICarData | null;
  selectedVehicle2: IBikeData | ICarData | null;
}

const initialState: InitialState = {
  isLoading: false,
  carData: [],
  bikeData: [],
  allVehicleData: [],
  selectedVehicle: null,
  selectedVehicle1: null,
  selectedVehicle2: null,
};

export const getBikeData = createAsyncThunk(
  "vehicle/getBikeData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/kf/vehicle/details?type=Bike&city=Bengaluru");
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCarData = createAsyncThunk(
  "vehicle/getCarData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/kf/vehicle/details?type=Car&city=Bengaluru");
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getAllVehicleData = createAsyncThunk(
  "vehicle/getAllVehicleData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/kf/vehicle/details?city=Bengaluru");
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setSelectedVehicle: (state, action) => {
      if (action.payload.type === "bike") {
        // eslint-disable-next-line
        state.bikeData.map((bike: any) => {
          if (routeSlug(bike.Model) === action.payload.id) {
            state.selectedVehicle = bike;
          }
        });
      }

      if (action.payload.type === "car") {
        // eslint-disable-next-line
        state.carData.map((car: any) => {
          if (routeSlug(car.Model) === action.payload.id) {
            state.selectedVehicle = car;
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBikeData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getBikeData.fulfilled, (state, action) => {
        const _ = action.payload.sort((a: any, b: any) => b.Total_Available - a.Total_Available)
        state.bikeData = _;
        state.isLoading = false;
      })
      .addCase(getBikeData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCarData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getCarData.fulfilled, (state, action) => {
        const _ = action.payload.sort((a: any, b: any) => b.Total_Available - a.Total_Available)
        state.carData = _;
        state.isLoading = false;

      })
      .addCase(getCarData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllVehicleData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getAllVehicleData.fulfilled, (state, action) => {
        state.allVehicleData = action.payload;
        state.isLoading = false;

      })
      .addCase(getAllVehicleData.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export const selectVehicleSlice = (state: RootState) => state.vehicle;

export const { setSelectedVehicle } = vehicleSlice.actions;

export default vehicleSlice.reducer;
