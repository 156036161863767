import React from "react";

import styles from "./css/MediaMentions.module.css";
import MediaCards from "../../components/Cards/MediaCards";

const yourStoryLink =
  "https://yourstory.com/2022/10/swytchd-electric-vehicles-ev-startup-monthly-subscription-service-support?utm_pageloadtype=scroll";

const liveMintLink =
  "https://www.livemint.com/news/india/this-bengaluru-based-startup-provides-evs-on-monthly-subscription-11667182055746.html";

const startupPediaLink =
  "https://www.linkedin.com/posts/startup-pedia_ev-electricvehicles-startup-activity-6992797716988719105-Us-O/?utm_source=share&utm_medium=member_android";

const MediaMentions: React.FC = () => {
  const openUrl = (url: string) => {
    window.open(url, "blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>FEATURES</h1>
        </div>

        <div className={styles.content}>
          <MediaCards>
            <img src="/images/shark_tank_logo.svg" alt="shark tank logo" />
          </MediaCards>

          <MediaCards>
            <img
              onClick={() => openUrl(yourStoryLink)}
              src="/images/your_story_logo.svg"
              alt="your story logo"
            />
          </MediaCards>

          <MediaCards>
            <img
              onClick={() => openUrl(liveMintLink)}
              src="/images/live_mint_logo.svg"
              alt="your story logo"
            />
          </MediaCards>

          <MediaCards>
            <img
              onClick={() => openUrl(startupPediaLink)}
              src="/images/startupedia_logo.svg"
              alt="your story logo"
            />
          </MediaCards>
        </div>
      </div>
    </div>
  );
};

export default MediaMentions;
