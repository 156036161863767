import React from "react";

interface IProp {
  children: React.ReactElement<HTMLImageElement>;
  p?: string;
}

const MediaCards: React.FC<IProp> = ({ children, p }) => {
  return (
    <div
      style={{
        padding: p ? p : "55px 30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "306px",
        height: "174px",
        borderRadius: "16px",
        backgroundColor: "var(--color-variant-white-1)",
        cursor: "pointer"

      }}
    >
      {children}
    </div>
  );
};

export default MediaCards;
