import Cookies from "js-cookie";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import userPool from "../../userPool";

interface InitialState {
  isLoading: boolean;
  isLoggedIn: boolean;
  userData: {
    userid: string;
    email: string;
    phone: string;
    name: string;
  };
  loggedInUser: {
    accessToken: string;
    phone: string;
    email: string;
    name: string;
    userid: string;
  };
  newPassword: string;
  forgotPasswordOTP: string;
  forgotPasswordPhone: string;
}

const initialState: InitialState = {
  isLoading: false,
  isLoggedIn: false,
  userData: {
    userid: "",
    email: "",
    phone: "",
    name: "",
  },
  loggedInUser: {
    accessToken: "",
    phone: "",
    email: "",
    name: "",
    userid: "",
  },
  newPassword: "",
  forgotPasswordOTP: "",
  forgotPasswordPhone: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },

    toggleIsLoggedIn: (state) => {
      state.isLoggedIn = true;
    },

    setUserLoggedIn: (state, action) => {
      state.isLoggedIn = true;
      state.loggedInUser = action.payload;
      Cookies.set("user", action.payload.accessToken);
    },

    setNewPassword: (state, action) => {
      state.newPassword = action.payload;
    },

    setForgotPasswordOtp: (state, action) => {
      state.forgotPasswordOTP = action.payload;
    },

    setForgotPasswordPhone: (state, action) => {
      state.forgotPasswordPhone = action.payload;
    },

    signOut: (state) => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.signOut();
        state.loggedInUser = {
          accessToken: "",
          phone: "",
          email: "",
          name: "",
          userid: "",
        };
        Cookies.remove("user");
        state.isLoggedIn = false;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const selectAuthSlice = (state: RootState) => state.auth;

export const {
  toggleIsLoggedIn,
  setUserData,
  setUserLoggedIn,
  signOut,
  setNewPassword,
  setForgotPasswordOtp,
  setForgotPasswordPhone,
} = authSlice.actions;

export default authSlice.reducer;
