import React, { useState } from "react";
import styles from "./css/HeroSection.module.css";
import Header from "../../layout/Header";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import { AnimatePresence, motion } from "framer-motion";
import useWindowWidth from "../../hooks/useWindowWidth";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import { useNavigate } from "react-router-dom";
import { selectAuthSlice } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { selectAppSlice } from "../../redux/app/appSlice";
import { Skeleton } from "@mui/material";
import {
  getBikeData,
  selectVehicleSlice,
} from "../../redux/vehicle/vehicleSlice";
import store from "../../redux/store";

function getImagePixels(measure: "H" | "W", windowWidth: number, type: string) {
  if (measure === "W") {
    if (windowWidth > 1700) {
      return 1124;
    } else {
      return 0.936666667 * windowWidth - (type === "hero_car_2" ? 200 : 80);
    }
  }
  if (measure === "H") {
    if (windowWidth > 1700) {
      return 569;
    } else {
      if (window.outerHeight < 745 && window.outerHeight > 625) {
        return (
          0.506227758 * 0.936666667 * windowWidth -
          (type === "hero_car_2" ? 200 : 80) -
          (750 - window.outerHeight)
        );
      }
      return (
        0.506227758 * 0.936666667 * windowWidth -
        (type === "hero_car_2" ? 200 : 80)
      );
    }
  }
}

function getAnimateAxis(measure: "X" | "Y", windowWidth: number, type: string) {
  if (measure === "X") {
    if (windowWidth < 1250) {
      return 0.763636364 * 0.358072917 * windowWidth + 50;
    }
    if (windowWidth > 1700) {
      return 0.763636364 * 0.358072917 * windowWidth + 100;
    } else {
      return (
        0.763636364 * 0.358072917 * windowWidth -
        (type === "hero_car_2" ? 0 : 120)
      );
    }
  }

  if (measure === "Y") {
    if (windowWidth > 1700) {
      return -540;
    } else {
      if (window.outerHeight < 745 && window.outerHeight > 625) {
        return (
          -0.358072917 * windowWidth +
          (type === "hero_car_2" ? 20 : -85) -
          (700 - window.outerHeight)
        );
      }
      return -0.358072917 * windowWidth + (type === "hero_car_2" ? 20 : -85);
    }
  }
}

const HeroSection: React.FC = () => {
  const [currentImage, setCurrentImage] = React.useState<number>(0);

  const windowWidth = useWindowWidth();

  const navigate = useNavigate();

  const images: string[] = [
    "hero_bike_1",
    "hero_bike_2",
    "hero_bike_3",
    "hero_car_2",
  ];

  const { isLoggedIn } = useSelector(selectAuthSlice);

  const { buttonText } = useSelector(selectAppSlice);

  const [vehicles, setVehicles] = useState<any>([]);

  const numFormat = Intl.NumberFormat("en-US");

  const [pricing, setPricing] = React.useState<any>("0");

  const { bikeData } = useSelector(selectVehicleSlice);

  React.useEffect(() => {
    const t = setTimeout(() => {
      if (currentImage === images.length - 1) {
        setCurrentImage(0);
        return;
      }
      setCurrentImage((prev) => prev + 1);
    }, 3000);

    return () => clearTimeout(t);
  }, [currentImage, images.length]);

  React.useEffect(() => {
    // Fetch bike data only if it's empty
    if (bikeData.length === 0) {
      store.dispatch(getBikeData());
    }
  }, []);

  React.useEffect(() => {
    // Check if bikeData has data and set vehicles
    if (bikeData.length > 0) {
      setVehicles([...bikeData]);
    }
  }, [bikeData]);

  React.useEffect(() => {
    // Check if vehicles has data and calculate pricing
    if (vehicles.length > 0) {
      const lowValue = vehicles?.reduce((a: any, b: any) =>
        a?.CostMonth.replace("INR", "") < b?.CostMonth.replace("INR", "")
          ? a
          : b
      );
      setPricing(lowValue?.CostMonth.replace("INR", ""));
    }
  }, [vehicles]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.toLeft}>
            <div className={styles.logo_container}>
              <SwytchdLogo color="var(--color-variant-white)" />
              <div>
                {windowWidth < 992 && <HamburgerDrawer variant="white" />}
              </div>
            </div>
            <div className={styles.content}>
              <h1>
                Why be an owner when you can be an <span>enjoyer</span>
              </h1>
              <p style={{ marginTop: "23px", fontWeight: 300 }}>
                Enjoy your ride without owning the Vehicle.
              </p>
              <p className={styles.long_text} style={{ fontWeight: 300 }}>
                Subscribe to your favorite electric vehicle with all-inclusive
                monthly plans starting at just{" "}
                <span>Rs. {`${numFormat.format(pricing)}`} </span>
              </p>
              {windowWidth > 769 && (
                <>
                  <p className={styles.short_text} style={{ fontWeight: 400 }}>
                    Just <span style={{ fontWeight: 600 }}>swytch</span> to the
                    good part
                  </p>
                  <div
                    // onClick={() => isLoggedIn ? navigate("/join-waitlist") : navigate("/auth/signin")}
                    onClick={() => navigate("/join-waitlist")}
                    className={`button-variant-1 ${styles.btn}`}
                    style={{ fontWeight: 500 }}
                  >
                    {!!buttonText ? (
                      `${buttonText} FROM Rs.49`
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{
                          width: "inherit",
                          fontSize: "20px",
                          "&.MuiSkeleton-root": {
                            backgroundColor: "#17a642 !important",
                          },
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {windowWidth > 992 && (
            <div
              style={{
                paddingRight: isLoggedIn ? "5rem" : "3rem",
              }}
              className={styles.toRight}
            >
              <Header color="var(--color-variant-black)" />
            </div>
          )}
        </div>
        {windowWidth > 992 && <div className={styles.bg_box}></div>}
        {windowWidth > 769 ? (
          <img
            className={styles.hero_vector}
            src="/images/hero_vector.svg"
            alt="hero svg"
          />
        ) : (
          <img
            className={styles.hero_vector}
            src="/images/hero_vector_mobile.svg"
            alt="hero svg"
          />
        )}
        {windowWidth > 992 && (
          <AnimatePresence>
            <motion.img
              className={styles.hero_image}
              key={images[currentImage]}
              src={`/images/${images[currentImage]}.webp`}
              style={{ objectFit: "contain" }}
              initial={{
                objectFit: "contain",
                width: "800px",
                height: "350px",
                x: 1600,
                y: -650,
              }}
              animate={{
                objectFit: "contain",
                width: getImagePixels("W", windowWidth, images[currentImage]),
                height: getImagePixels("H", windowWidth, images[currentImage]),
                x: getAnimateAxis("X", windowWidth, images[currentImage]),
                y: getAnimateAxis("Y", windowWidth, images[currentImage]),
              }}
              exit={{
                objectFit: "contain",
                width: "800px",
                height: "350px",
                opacity: 1,
                x: -350,
                y: -550,
              }}
              transition={{ duration: 1, ease: "easeOut" }}
            />
          </AnimatePresence>
        )}

        {windowWidth < 992 && (
          <div className={styles.toBottom}>
            <AnimatePresence>
              <motion.img
                className={styles.hero_image}
                key={images[currentImage]}
                style={{ objectFit: "contain" }}
                src={`/images/${images[currentImage]}.webp`}
                initial={{
                  objectFit: "contain",
                  x: 2000,
                  y: -140,
                  width: "300px",
                  height: "200px",
                }}
                animate={{
                  x: 0,
                  y: -140,
                  objectFit: "contain",
                  width: "300px",
                  height: "200px",
                }}
                exit={{
                  opacity: 1,
                  x: -400,
                  y: -140,
                  objectFit: "contain",
                  width: "300px",
                  height: "200px",
                }}
                transition={{ duration: 1, ease: "easeOut" }}
              />
            </AnimatePresence>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroSection;
