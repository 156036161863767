import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPriceComparisionTabSlice } from "../../redux/priceComparisionTab/priceComparisionTabSlice";

import styles from "./css/CostCard.module.css";

interface IProp {
  data: any;
  dataHardCoded?: any;
  title: string;
  selectedTab: string;
}

const CostCard: React.FC<IProp> = ({ data, dataHardCoded, title, selectedTab }) => {

  const { isLoading } = useSelector(selectPriceComparisionTabSlice);

  const [_dataHardCoded, setDataHardCoded] = useState<any>();


  useEffect(() => {
    if (!dataHardCoded) return;
    /* eslint-disable */
    let _: any = dataHardCoded.filter((el: any) => el?.name === data?.Model)[0]
    setDataHardCoded(_);
  }, [dataHardCoded, data])

  if (selectedTab === "CARS") return (
    <div
      className={styles.container}
      style={{
        borderColor:
          title === "Subscribe" ? "var(--color-variant-green-1)" : "",
      }}
    >
      {
        !isLoading && data ?
          <>
            <p>&#8377; {title === "Subscribe" ? "10,000" : _dataHardCoded?.buy.depositDownpayment}</p>
            <p>&#8377; {title === "Subscribe" ? Number(data?.CostMonth.split(" ")[0]).toLocaleString("en-IN") : _dataHardCoded?.buy.monthlySubscription}</p>
            <p> {title === "Subscribe" ? "1 Month" : _dataHardCoded?.buy.commitmentDuration}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.insurance}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.maintainanceAndServicing}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.charging}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.addOns}</p>
            <p
              style={{
                color: "var(--color-variant-green-1)",
                fontStyle: "italic",
              }}
            >
              &#8377; {title === "Subscribe" ? Number(data?.CostMonth.split(" ")[0]).toLocaleString("en-IN") : _dataHardCoded?.buy.total}
            </p>
          </>
          :
          <>
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
          </>
      }
    </div>
  )

  return (
    <div
      className={styles.container}
      style={{
        borderColor:
          title === "Subscribe" ? "var(--color-variant-green-1)" : "",
      }}
    >
      {
        !isLoading && data ?
          <>
            <p>&#8377; {title === "Subscribe" ? "1,600" : _dataHardCoded?.buy.depositDownpayment}</p>
            <p>&#8377; {title === "Subscribe" ? Number(data?.CostMonth.split(" ")[0]).toLocaleString("en-IN") : _dataHardCoded?.buy.monthlySubscription}</p>
            <p> {title === "Subscribe" ? "1 Month" : _dataHardCoded?.buy.commitmentDuration}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.insurance}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.maintainanceAndServicing}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.charging}</p>
            <p>&#8377; {title === "Subscribe" ? "0" : _dataHardCoded?.buy.addOns}</p>
            <p
              style={{
                color: "var(--color-variant-green-1)",
                fontStyle: "italic",
              }}
            >
              &#8377; {title === "Subscribe" ? Number(data?.CostMonth.split(" ")[0]).toLocaleString("en-IN") : _dataHardCoded?.buy.total}
            </p>
          </> :
          <>
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
            <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1" }} />
          </>
      }
    </div>
  );
};

export default CostCard;
