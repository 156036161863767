import React from "react";
import Lottie from "react-lottie";
import styles from "./css/FeaturesCard.module.css";

import carAnimation from "../../animation/cars.json";

const FeaturesCard: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: carAnimation,
    renderer: "svg",
  };
  return (
    <div className={styles.container}>
      <div className={styles.animated}>
        <Lottie options={defaultOptions} />
      </div>

      <div className={styles.content}>
        <p className={styles.header_text}>Usership is the new black</p>
        <p>LOW DEPOSIT</p>
        <p>NO CREDIT CHECKS</p>
        <p>INSURANCE INCLUDED</p>
        <p>NO ROAD TAX</p>
        <p>SERVICING</p>
        <p>SWYTCH EVERY MONTH</p>
      </div>
    </div>
  );
};

export default FeaturesCard;
