import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { bikesPriceComparisionData, carsPriceComparisionData } from "../../data";

interface InitialState {
  selectedTab: "BIKES" | "CARS";
  priceComparisionData: any;
  priceComparisionDataHardCoded: any;
  index: number;
  isLoading: boolean;
  cars: any;
  bikes: any;
}

const initialState: InitialState = {
  selectedTab: "BIKES",
  priceComparisionData: [],
  priceComparisionDataHardCoded: bikesPriceComparisionData,
  index: 0,
  isLoading: false,
  cars: [],
  bikes: []
};

export const getBikeData = createAsyncThunk(
  "priceComparisionTab/getBikeData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/kf/vehicles/type/Bike/city/Bengaluru");
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCarData = createAsyncThunk(
  "priceComparisionTab/getCarData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/kf/vehicles/type/Car/city/Bengaluru");
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const priceComparisionTabSlice = createSlice({
  name: "priceComparisionTab",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    handleChange: (state, action) => {
      if (action.payload === "right") {
        if (state.index === state.priceComparisionData.length - 1) {
          state.index = 0;
          return;
        }

        state.index = state.index + 1;
      }

      if (action.payload === "left") {
        if (state.index === 0) {
          state.index = state.priceComparisionData.length - 1;
          return;
        }

        state.index = state.index - 1;
      }
    },

    handleSelectedTabCars: (state) => {
      state.index = 0;
      state.priceComparisionDataHardCoded = carsPriceComparisionData;
      state.priceComparisionData = state.cars;
    },

    handleSelectedTabBikes: (state) => {
      state.index = 0;
      state.priceComparisionDataHardCoded = bikesPriceComparisionData;
      state.priceComparisionData = state.bikes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBikeData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getBikeData.fulfilled, (state, action) => {
        state.bikes = action.payload;
        state.priceComparisionData = action.payload;
        state.isLoading = false;

      })
      .addCase(getBikeData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCarData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getCarData.fulfilled, (state, action) => {
        state.cars = action.payload;
        state.isLoading = false;

      })
      .addCase(getCarData.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export const selectPriceComparisionTabSlice = (state: RootState) =>
  state.priceComparisionTab;

export const {
  setSelectedTab,
  handleChange,
  handleSelectedTabCars,
  handleSelectedTabBikes,
} = priceComparisionTabSlice.actions;

export default priceComparisionTabSlice.reducer;
