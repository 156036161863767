const ServicesBanner = () => {
  const styles: any = {
    container: {
      width: "100%",
      padding: "30px 0px",
      backgroundColor: "var(--color-variant-gray-1)",
      fontFamily: "var(--font-family-lexend)",
    },
    wrapper: {
      width: "90%",
      margin: "0 auto",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "10px"
    },
    content: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <img src="/images/month-deposit.svg" alt="1 month deposit" />
          <p>LOW DEPOSIT</p>
        </div>
        <div style={styles.content}>
          <img src="/images/insurance.svg" alt="Insurance" />
          <p>INSURANCE</p>
        </div>
        <div style={styles.content}>
          <img src="/images/servicing.svg" alt="Servicing" />
          <p>SERVICING</p>
        </div>
        <div style={styles.content}>
          <img src="/images/recovery.svg" alt="Recovery" />
          <p>RECOVERY</p>
        </div>
        <div style={styles.content}>
          <img src="/images/km-per-pm.svg" alt="KM PER PM" />
          <p>1500 KM/PM</p>
        </div>
      </div>
    </div>
  );
};

export default ServicesBanner;
