import {
  Box,
  Button,
  Fade,
  Stack,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routeSlug } from "../../utils/createRouteSlug";

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "305px",
  height: "400px",
  padding: "28px 53px 28px 28px",
  backgroundColor: theme.palette.white.light,
  borderRadius: "20px",
  backgroundImage: "url('/images/hero_vector.svg')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  backgroundPositionY: 200,
  cursor: "pointer",
}));

const CarImage = styled("img", {
  shouldForwardProp: (prop) =>
    prop !== "isHovered" && prop !== "type" && prop !== "vehicleId",
})<{ isHovered: boolean; type: "bike" | "car"; }>(
  ({ isHovered, type, theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    transform: type === "bike" ? isHovered
      ? "scale(0.8) translate(-10%, 35%)"
      : "translate(20%, 35%)"
      : type === "car" ? isHovered
        ? "scale(0.8) translate(-10%, 45%)"
        : "translate(20%, 45%)"
        : "",
    width: "345px",
    height: "235px",
    objectFit: "contain",
    pointerEvents: "none",
    transition: theme.transitions.create("transform"),
  })
);

const JoinWaitlistButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.green.main,
  borderRadius: "20px",
  width: "90%",
  padding: "10px 0px",
  position: "absolute",
  top: "88%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "&:hover": {
    backgroundColor: theme.palette.green.main,
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.black.light,
  width: "100%",
  height: "134px",
  position: "absolute",
  top: "83%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px 10px 20px 20px",
  padding: "28px 0px",
  cursor: "pointer",
}));

const Tags = styled(Typography)({
  fontSize: "10px",
  fontWeight: 500,
  color: "#fff",
});

interface IProp {
  // vehicleData: IBikeData | ICarData;
  vehicleData: any;
  type: "car" | "bike";
}

const VehicleDisplayCard: React.FC<IProp> = ({ vehicleData, type }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`${routeSlug(vehicleData.Model)}`);
  };

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleNavigation}
    >
      <Typography
        sx={{
          fontSize: isHovered ? "16px" : "20px",
          transition: (theme: Theme) =>
            theme.transitions.create("font-size", {
              duration: theme.transitions.duration.standard,
            }),
        }}
        variant="h6"
      >
        {`${vehicleData.Make} ${vehicleData.Model}`}
      </Typography>
      <Typography
        sx={{
          fontSize: isHovered ? "14px" : "20px",
          transition: (theme: Theme) =>
            theme.transitions.create("font-size", {
              duration: theme.transitions.duration.standard,
            }),
        }}
        variant="h5"
      >
        &#8377; {Number(vehicleData?.CostMonth.split(" ")[0]).toLocaleString("en-IN")}/month

      </Typography>
      <CarImage
        isHovered={isHovered}
        type={type}
        src={`${process.env.REACT_APP_IMG_URL}/vehicles/${vehicleData.Vehicle_Type.toLowerCase()}s/${vehicleData.Make.toLowerCase()}/${vehicleData.Model.replaceAll(" ", "+").toLowerCase()}/${vehicleData.Model.replaceAll(" ", "_").toLowerCase()}.webp`}
        alt={type}
      />
      <Fade in={isHovered} timeout={600}>
        <ButtonContainer>
          <Stack direction="row" justifyContent="center" spacing={0.2}>
            <>
              <Tags variant="body1">SPEED {vehicleData.Top_Speed}</Tags>
              <Tags variant="body1">|</Tags>
              <Tags variant="body1">RANGE {vehicleData.Range}</Tags>
              <Tags variant="body1">|</Tags>
              <Tags variant="body1">
                CHARGING TIME {vehicleData.Charging_Time.replace("hours", "hr").replace("mins", "m")}
              </Tags>
            </>
          </Stack>
        </ButtonContainer>
      </Fade>
      <JoinWaitlistButton
        onClick={handleNavigation}
        variant="contained"
        disableElevation
      >
        <Typography sx={{ fontSize: "14px" }} variant="h1" component="span">
          {type === "bike"
            ? vehicleData.Total_Available > 0
              ? "BOOK NOW @49"
              : "JOIN WAITLIST @49"
            : type === "car"
              ? vehicleData.Total_Available > 0
                ? "BOOK NOW @999"
                : "JOIN WAITLIST @999"
              : ""}
        </Typography>
      </JoinWaitlistButton>
    </Container>
  );
};

export default VehicleDisplayCard;
