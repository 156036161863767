import { Box, styled, Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SelectModel from "../../components/SelectModel/SelectModel";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import VehicleListTab from "../../components/vehicleListTab/VehicleLIstTab";
import useWindowWidth from "../../hooks/useWindowWidth";
import Header from "../../layout/Header";
import {
  restartCompare,
  selectCompareSlice,
  setData,
} from "../../redux/compare/compareSlice";
import store from "../../redux/store";
import { getBikeData, getCarData, selectVehicleSlice } from "../../redux/vehicle/vehicleSlice";

const HeaderContainer = styled(Stack)(({ theme }) => ({
  padding: "26px 80px",
  justifyContent: "space-between",
  flexDirection: "row",
  backgroundColor: theme.palette.white.main,
  [theme.breakpoints.down("md")]: {
    padding: "26px 20px",
  },
}));

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "start",
})<{ start: boolean }>(({ start, theme }) => ({
  position: "relative",
  height: "90vh",
  width: "100%",
  backgroundColor: theme.palette.white.main,
  overflow: "hidden",
}));

const SelectModelContainer = styled(Box)({
  position: "absolute",
  width: "fit-content",
  minWidth: "410px",
  left: "50%",
  top: "180px",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
});

const HeaderText = styled(Typography)(({ theme }) => ({
  width: "500px",
  position: "absolute",
  left: "50%",
  top: "0%",
  transform: "translate(-50%, 250px)",
  fontSize: "30px",
  color: "#1e1e1e",
  [theme.breakpoints.down("md")]: {
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
  },
}));

const BackgroundWrapper: any = styled(Box, {
  shouldForwardProp: (prop) => prop !== "start",
})<{ start: boolean }>(({ start, theme }) => ({
  backgroundImage: "url(/images/compare-page-vector.svg)",
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "30%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 0%",
  backgroundSize: "cover",
}));

const VectorCarContainer = styled(Box)({
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-100%, -80px)",
});

const ComparePage = () => {
  const windowWidth = useWindowWidth();

  const { start, carsData, bikesData, vehicleModels } =
    useSelector(selectCompareSlice);

  const { carData, bikeData } = useSelector(selectVehicleSlice);

  const animation = {
    leftImage: {
      initial: { opacity: 1, x: -100, y: 0 },
      animate: {
        opacity: 1,
        x: -100,
        y: 0,
      },
      exit: {
        opacity: 0,
        x: -2000,
        y: -500,
        width: "2000px",
        height: "1050px",
      },
      transition: { duration: 0.5 },
    },
    rightImage: {
      initial: { opacity: 1, x: 110, y: 0 },
      animate: {
        opacity: 1,
        x: 110,
        y: 0,
      },
      exit: {
        opacity: 0,
        x: 2000,
        y: -500,
        width: "2000px",
        height: "1050px",
      },
      transition: { duration: 0.5 },
    },

    selectModelVariants: {
      open: { x: 0, y: -100 },
      closed: { x: 0, y: 0 },
    },
  };

  useEffect(() => {

    store.dispatch(setData({
      cars: carData,
      bikes: bikeData
    }))

  }, [carData, bikeData])

  useEffect(() => {
    store.dispatch(getCarData());
    store.dispatch(getBikeData());
    store.dispatch(restartCompare());
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Find and Compare The Best EV Subscription </title>
        <meta name="description" content="Find and compare the best electric vehicle subscription plans starting at just Rs. 4,200. Our electric vehicle subscription service offers the perfect solution. " />
        <meta name="keywords" content="EV Subscription, Best Electric Vehicle, Best EV Subscription, Bike rental service, Car rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <HeaderContainer>
        <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
        {windowWidth > 769 ? (
          <Header color="var(--color-variant-black)" />
        ) : (
          <Box>
            <HamburgerDrawer variant="black" />
          </Box>
        )}
      </HeaderContainer>

      <Wrapper start={start}>
        <motion.div
          animate={start ? "open" : "closed"}
          variants={animation.selectModelVariants}
          transition={{ duration: 0.5 }}
        >
          <SelectModelContainer>
            <SelectModel vehicleModels={vehicleModels} />
          </SelectModelContainer>
        </motion.div>
        <AnimatePresence>
          {!start && (
            <>
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <HeaderText variant="h5">
                  Select the models you'd like to compare
                </HeaderText>
              </motion.div>

              <BackgroundWrapper
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                component={motion.div}
                start={start}
              >
                <VectorCarContainer>
                  <motion.img
                    style={{
                      position: "absolute",
                      width: "100px",
                      height: "78px",
                    }}
                    src="/images/vector-car-compare-1.svg"
                    alt="vector car"
                    initial={animation.leftImage.initial}
                    animate={animation.leftImage.animate}
                    exit={animation.leftImage.exit}
                    transition={animation.leftImage.transition}
                  />
                </VectorCarContainer>

                <VectorCarContainer>
                  <motion.img
                    style={{
                      width: "115px",
                      height: "75px",
                    }}
                    src="/images/vector-car-compare-2.svg"
                    alt="vector car"
                    initial={animation.rightImage.initial}
                    animate={animation.rightImage.animate}
                    exit={animation.rightImage.exit}
                    transition={animation.rightImage.transition}
                  />
                </VectorCarContainer>
              </BackgroundWrapper>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {start && vehicleModels[0]?.Name === "Select Model" && (
            <motion.div
              initial={{ opacity: 0, y: -300, x: 200 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              exit={{ opacity: 0, y: 300, x: -200 }}
              transition={{ duration: 0.7, ease: "anticipate" }}
            >
              <Stack mt="10em" direction="row" justifyContent="center">
                <VehicleListTab carsData={carsData} bikesData={bikesData} />
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

export default ComparePage;
