import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { selectAuthSlice } from '../redux/auth/authSlice'

interface IProp {
  children: React.ReactNode;
}

const ProtectedRoutes: React.FC<IProp> = ({ children }) => {
  const { isLoggedIn } = useSelector(selectAuthSlice);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/")
    }
    /*eslint-disable*/
  }, [isLoggedIn])

  return (
    <>{children}</>
  )
}

export default ProtectedRoutes; 
