import React, { useState } from "react";
import {
  styled,
  Box,
  InputAdornment,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { StyledTextField } from "../joinWaitlistPage/JoinWaitlistForm";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import userPool from "../../userPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import FailureAlert from "../../components/Alerts/Error";
import store from "../../redux/store";
import { setForgotPasswordPhone } from "../../redux/auth/authSlice";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.black.light,
  minHeight: "calc(100vh - 90px)",
  padding: "26px 60px",

  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
}));
const InputMobileNumber: React.FC = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 10) return;
    setPhone(e.target.value);
  };

  const forgotPassword = () => {
    if (!phone) {
      setErr("Please enter valid phone number!");
    }

    setIsLoading(true);

    const _user = {
      Username: `+91${phone}`,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(_user);

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        store.dispatch(setForgotPasswordPhone(phone));
        setIsLoading(false);
        // navigate("/auth/recover-password/verify-otp");
        navigate("/auth/recover-password/change-password");
      },

      onFailure: (err) => {
        setIsLoading(false);
        if (err.name === "UserNotFoundException") {
          customForgotPassword();
        } else {
          setErr(err.message);
        }
      },
    });
  };

  const customForgotPassword = () => {
    if (!phone) return;

    setIsLoading(true);

    const _user = {
      Username: phone,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(_user);

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        store.dispatch(setForgotPasswordPhone(phone));
        setIsLoading(false);
        // navigate("/auth/recover-password/verify-otp");
        navigate("/auth/recover-password/change-password");
      },

      onFailure: (err) => {
        setIsLoading(false);
        setErr(err.message);
      },
    });
  };

  return (
    <Container>
      {err && (
        <FailureAlert
          errorMsg={err}
          errorTitle={"Error"}
          closeErrorPopup={() => setErr("")}
        />
      )}
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          pt: "4em",
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", sm: "450px" },
            position: "relative",
            alignItems: "center",
          }}
          direction="column"
          rowGap={5}
        >
          <Typography
            color="#fff"
            variant="h6"
            sx={{ fontSize: { sm: "16px", md: "20px" } }}
            alignSelf="center"
            alignContent="center"
            textAlign="center"
          >
            Forgotten password? No worries, it happens to the best of us
          </Typography>
          <Typography
            color="#fff"
            variant="h6"
            sx={{ fontSize: { sm: "16px", md: "20px" } }}
            alignSelf="center"
            textAlign="center"
          >
            Enter the Mobile Number you last used to register/login
          </Typography>
          {isLoading && (
            <CircularProgress
              sx={{
                "&.MuiCircularProgress-colorPrimary": {
                  color: (theme) => theme.palette.green.light,
                },
                "&.MuiCircularProgress-root": {
                  width: "60px !important",
                  height: "60px !important",
                  position: "absolute",
                  top: "0%",
                  left: "40%",
                },
              }}
            />
          )}

          <StyledTextField
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "20px",
                fontWeight: 500,
              },
            }}
            required
            size="small"
            label="MOBILE"
            value={phone}
            variant="standard"
            name="mobile"
            type="tel"
            autoComplete="new-password"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment disablePointerEvents position="start">
                  +91
                </InputAdornment>
              ),
            }}
          />

          <ButtonVariant1
            sx={(theme) => ({
              color: theme.palette.black.main,
              fontSize: { md: "18px", lg: "24px" },
              borderRadius: "80px",
              width: { sm: "100%", md: "90%" },
              padding: { sm: "8px", md: "14px 35px" },
            })}
            onClick={() => forgotPassword()}
          >
            GET OTP
          </ButtonVariant1>
        </Stack>
      </Stack>
    </Container>
  );
};

export default InputMobileNumber;
