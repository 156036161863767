import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom';
import { selectAuthSlice } from '../redux/auth/authSlice'

interface IProp {
  children: React.ReactNode;
}

const UnauthorizedRoutes: React.FC<IProp> = ({ children }) => {
  const { isLoggedIn, loggedInUser } = useSelector(selectAuthSlice);

  const navigate = useNavigate();

  if (isLoggedIn) {
    return <>{children}</>
  } else {
    navigate("/");
    return null;
  }
}

export default UnauthorizedRoutes; 
