import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setVehicleModel,
  toggleShowModal,
  updateCarsBikesData,
} from "../../redux/compare/compareSlice";
import store from "../../redux/store";
import { IBikeData, ICarData } from "../../types/interfaces";

interface IProp {
  carsData: ICarData[];
  bikesData: IBikeData[];
}

interface ISelectedVehicle {
  vehicleData: any;
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.light,
  borderRadius: "20px",
  width: "360px",
  maxHeight: "430px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.gray.light,
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
  },
  [theme.breakpoints.down(450)]: {
    width: "280px",
  },
}));

const TabHeaderStack = styled(Stack)({
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  width: "180px",
  cursor: "pointer",
  padding: "15px 0px",
});

const VehicleList: React.FC<ISelectedVehicle> = ({ vehicleData }) => {
  const navigate = useNavigate();
  return (
    <List>
      {vehicleData.map((vehicle: any, key: any) => {
        return (
          <Fragment key={key}>
            <ListItem
              sx={{ cursor: "pointer" }}
              onClick={() => {
                store.dispatch(setVehicleModel(vehicle));
                store.dispatch(toggleShowModal());
                store.dispatch(updateCarsBikesData());
                navigate("/compare/table", {
                  replace: true,
                });
              }}
            >
              <ListItemAvatar>
                <img
                  width="90px"
                  height="50px"
                  loading="eager"
                  src={`${process.env.REACT_APP_IMG_URL}/vehicles/${vehicle?.Vehicle_Type.toLowerCase()}s/${vehicle.Make.toLowerCase()}/${vehicle.Model.replaceAll(" ", "+").toLowerCase()}/${vehicle.Model.replaceAll(" ", "_").toLowerCase()}.webp`}
                  alt="vehicle"
                  style={{
                    objectFit: "contain"
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      color: (theme) => theme.palette.black.light,
                    }}
                    variant="h6"
                  >
                    {vehicle?.Name.split("/")[0]}
                  </Typography>
                }
                secondary={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: (theme) => theme.palette.black.light,
                    }}
                    variant="h5"
                  >
                    &#8377; {vehicle?.CostMonth}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
          </Fragment>
        );
      })}
    </List>
  );
};

const VehicleListTab: React.FC<IProp> = ({ carsData, bikesData }) => {
  const [vehicleType, setVehicleType] = useState<string>("CARS");

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between">
        <TabHeaderStack
          sx={{
            borderBottom: (theme) =>
              vehicleType === "CARS"
                ? `1px solid ${theme.palette.black.light}`
                : "",
          }}
          onClick={() => setVehicleType("CARS")}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              color: (theme) =>
                vehicleType === "CARS"
                  ? theme.palette.black.light
                  : theme.palette.gray.main,
            }}
            variant="h6"
          >
            CARS
          </Typography>
        </TabHeaderStack>
        <TabHeaderStack
          sx={{
            borderBottom: (theme) =>
              vehicleType === "BIKES"
                ? `1px solid ${theme.palette.black.light}`
                : "",
          }}
          onClick={() => setVehicleType("BIKES")}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              color: (theme) =>
                vehicleType === "BIKES"
                  ? theme.palette.black.light
                  : theme.palette.gray.main,
            }}
            variant="h6"
          >
            BIKES
          </Typography>
        </TabHeaderStack>
      </Stack>
      <VehicleList
        vehicleData={vehicleType === "CARS" ? carsData : bikesData}
      />
    </Container>
  );
};

export default VehicleListTab;
