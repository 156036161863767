import { Stack, Box, styled, Fade, Modal } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import CompareVehicleTable from "../../components/compareVehicleTable/CompareVehicleTable";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SelectModel from "../../components/SelectModel/SelectModel";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import VehicleListTab from "../../components/vehicleListTab/VehicleLIstTab";
import useWindowWidth from "../../hooks/useWindowWidth";
import Header from "../../layout/Header";
import {
  selectCompareSlice,
  setData,
  toggleShowModal,
} from "../../redux/compare/compareSlice";
import store from "../../redux/store";
import { selectVehicleSlice } from "../../redux/vehicle/vehicleSlice";

const HeaderContainer = styled(Stack)(({ theme }) => ({
  padding: "26px 80px",
  justifyContent: "space-between",
  flexDirection: "row",
  backgroundColor: theme.palette.white.main,
  [theme.breakpoints.down("md")]: {
    padding: "26px 20px",
  },
}));

const CompareTablePage = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();

  const { carsData, bikesData, vehicleModels, showModal } =
    useSelector(selectCompareSlice);

  const { carData, bikeData } = useSelector(selectVehicleSlice);

  // useEffect(() => {
  //   navigate("/compare")
  // }, [navigate]);

  if (bikeData.length === 0 || carData.length === 0) {
    Navigate({
      to: "/compare"
    })
    return null;
  }

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Find and Compare The Best EV Subscription </title>
        <meta name="description" content="Find and compare the best electric vehicle subscription plans starting at just Rs. 4,200. Our electric vehicle subscription service offers the perfect solution. " />
        <meta name="keywords" content="EV Subscription, Best Electric Vehicle, Best EV Subscription, Bike rental service, Car rental service, Monthly ev rental " />
      </Helmet>
      <Box
        sx={{
          background: (theme) => theme.palette.white.main,
          pb: 5,
        }}
      >
        <HeaderContainer>
          <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
          {windowWidth > 769 ? (
            <Header color="var(--color-variant-black)" />
          ) : (
            <Box>
              <HamburgerDrawer variant="black" />
            </Box>
          )}
        </HeaderContainer>

        <Fade in={vehicleModels[0].Name !== "Select Model"} timeout={1000}>
          <Stack direction="column" spacing={10} mt={3}>
            <Box alignSelf="center">
              <SelectModel vehicleModels={vehicleModels} />
            </Box>
            <Stack direction="row" justifyContent="center">
              <CompareVehicleTable
                selectedVehicles={vehicleModels.filter(
                  (vehicle: any) => vehicle.Name !== "Select Model"
                )}
              />
            </Stack>
          </Stack>
        </Fade>

        <Modal open={showModal} onClose={() => store.dispatch(toggleShowModal())}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              outline: "none",
              borderRadius: "25px",
            }}
          >
            <VehicleListTab carsData={carsData} bikesData={bikesData} />
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default CompareTablePage;
