import { Box, Fade, Grid, Stack } from "@mui/material";
import VehicleDisplayCard from "../../components/VehicleDisplayCard/VehicleDisplayCard";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import Header from "../../layout/Header";
import useWindowWidth from "../../hooks/useWindowWidth";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import StickyServicesBanner from "../../components/ServicesBanner/StickyServicesBanner";
import SmallVehicleDisplayCard from "../../components/VehicleDisplayCard/SmallVehicleDisplayCard";
import { useSelector } from "react-redux";
import { getBikeData, selectVehicleSlice } from "../../redux/vehicle/vehicleSlice";
import { useEffect } from "react";
import store from "../../redux/store";
import VehicleListLoader from "../../components/Loaders/VehicleListLoader";
import SmallVehicleListLoader from "../../components/Loaders/SmallVehicleListLoader";
import { Helmet } from "react-helmet";

const BikesListPage = () => {
  const windowWidth = useWindowWidth();

  const { bikeData } = useSelector(selectVehicleSlice);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    store.dispatch(getBikeData());

  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Electric Bikes Subscription </title>
        <meta name="description" content="Subscribe to your favorite electric bikes with all-inclusive monthly plans starting at just Rs. 4,200. Our electric bikes subscription service offers the perfect solution. " />
        <meta name="keywords" content="Electric Bike Subscription, Electric scooter subscription, Scooter rental service, Bike rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Fade in={true} timeout={800}>
        <Box
          sx={{
            maxWidth: "1440px",
            margin: "0 auto",
            bgColor: (theme) => theme.palette.white.main,
          }}
        >
          <Stack
            sx={{ p: { xs: "20px 18px", lg: "26px 80px" } }}
            direction="row"
            justifyContent="space-between"
          >
            <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
            <Box>
              {windowWidth > 900 ? (
                <Header color="var(--color-variant-black)" />
              ) : (
                <HamburgerDrawer variant="black" />
              )}
            </Box>
          </Stack>
          <Box sx={{ p: { xs: "20px 18px", lg: "50px 80px" } }}>
            {windowWidth > 769 ? (
              <Grid
                container
                rowSpacing="70px"
                sx={{
                  justifyContent: { xl: "flex-start", lg: "space-between" },
                }}
              >
                {
                  bikeData.length > 0 ?
                    bikeData.map((bike: any, id: any) => {
                      return (
                        <Grid key={id} item xl={4} md={6} lg={6}>
                          <VehicleDisplayCard vehicleData={bike} type="bike" />
                        </Grid>
                      );
                    })
                    :
                    <VehicleListLoader />
                }
              </Grid>
            ) : (
              <Stack spacing={4}>
                {

                  bikeData.length > 0 ?
                    bikeData.map((bike: any, id: any) => {
                      return (
                        <SmallVehicleDisplayCard
                          key={id}
                          type="bike"
                          vehicleData={bike}
                          hideButton={false}
                        />
                      );
                    })

                    : <SmallVehicleListLoader />
                }
              </Stack>
            )}
          </Box>
        </Box>
      </Fade>

      {windowWidth >= 769 && <StickyServicesBanner />}
    </>
  );
};

export default BikesListPage;
