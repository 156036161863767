import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialState {
  buttonText: string;
}

const initialState: InitialState = {
  buttonText: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setButtonText: (state, action) => {
      state.buttonText = action.payload;
    },
  },
  extraReducers: (builder) => { },
});

export const selectAppSlice = (state: RootState) => state.app;

export const { setButtonText } = appSlice.actions;

export default appSlice.reducer;
