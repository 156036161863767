import { Card, CardHeader, CardMedia, styled, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routeSlug } from "../../utils/createRouteSlug";

const Container = styled(Card)(({ theme }) => ({
  border: `1px solid transparent`,
  borderRadius: "12px",
  backgroundColor: theme.palette.white.light,
  width: "198px",
  height: "215px",
  backgroundImage: "url(/images/bg-vector-2.svg)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  padding: "0px 0px 15px 0px",
  cursor: "pointer",
}));

interface IProp {
  vehicle: any;
}

const VehicleList: React.FC<IProp> = ({ vehicle }) => {
  const navigate = useNavigate();
  const { bike_name, car_name } = useParams();

  const { pathname } = useLocation();

  return (
    <Container
      onClick={() => navigate(`/${pathname.split("/")[1]}/${routeSlug(vehicle.Model)}`)}
      elevation={0}
      sx={{
        borderColor: (theme) => ((routeSlug(vehicle.Model) === bike_name || routeSlug(vehicle.Model) === car_name) ? theme.palette.green.main : null),
      }}
    >
      <CardHeader
        sx={{ pb: "0px", pt: "0px", minHeight: "100px" }}
        title={
          <Typography fontSize="13px" variant="h6">
            {`${vehicle?.Make} ${vehicle?.Model}`}
          </Typography>
        }
        subheader={
          <Typography fontSize="12px" variant="h5">
            &#8377; {vehicle.CostMonth}
          </Typography>
        }
      />
      <CardMedia
        src={`${process.env.REACT_APP_IMG_URL}/vehicles/${vehicle.Vehicle_Type.toLowerCase()}s/${vehicle.Make.toLowerCase()}/${vehicle.Model.replaceAll(" ", "+").toLowerCase()}/${vehicle.Model.replaceAll(" ", "_").toLowerCase()}.webp`}
        alt="bike" component="img" height="100px" sx={{ objectFit: "contain" }} />
    </Container>
  );
};

export default VehicleList;
