import React from "react";
import { useSelector } from "react-redux";
import ChargingCostCard from "../../components/Cards/ChargingCostCard";
import FuelCostCard from "../../components/Cards/FuelCostCard";
import DistanceSlider from "../../components/DistanceSlider/DistanceSlider";
import { selectFuelCostSlice } from "../../redux/fuelCost/fuelCostSlice";

import styles from "./css/FuelCostSection.module.css";

const FuelCostSection: React.FC = () => {
  const { fuelCost, chargingCost } = useSelector(selectFuelCostSlice);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.toLeft}>
          <h1>HOW MUCH FUEL COST WILL YOU SAVE IF YOU SWYTCH?</h1>
          <DistanceSlider />
        </div>

        <div className={styles.toRight}>
          <div className={styles.cost_container}>
            <ChargingCostCard cost={chargingCost} />

            <FuelCostCard cost={fuelCost} />
          </div>
          <p className={styles.note}>
            Assumption of average cost electricity- INR 6/kWh, Average cost of
            fuel- INR 100/litre
          </p>
        </div>
      </div>
    </div>
  );
};

export default FuelCostSection;
