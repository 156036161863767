import React, { useState } from "react";
import {
  styled,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { StyledTextField } from "../joinWaitlistPage/JoinWaitlistForm";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../../userPool";
import FailureAlert from "../../components/Alerts/Error";
import store from "../../redux/store";
import { setUserLoggedIn } from "../../redux/auth/authSlice";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

type IData = {
  phone: string;
  password: string;
};
const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.black.light,
  minHeight: "calc(100vh - 70px)",
  padding: "26px 60px",

  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
}));

const SignIn: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const [signInError, setSignInError] = React.useState("");

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [data, setData] = useState<IData>({
    phone: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "phone") {
      if (e.target.value.length > 10) {
        return;
      }
    }

    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const signIn = () => {
    setIsLoading(true);
    let authenticationData = {
      Username: `+91${data.phone}`,
      Password: data.password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: `+91${data.phone}`,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const d = result.getIdToken().decodePayload();
        store.dispatch(
          setUserLoggedIn({
            accessToken,
            phone: `+91${data.phone}`,
            email: d.email,
            name: d.name,
            userid: d.sub,
          })
        );

        setIsLoading(false);
        toast.success("Sign In Successful!");

        navigate("/");
      },

      onFailure: (err) => {
        setIsLoading(false);
        if (err.message === "User does not exist.") {
          customSignIn();
        } else {
          setSignInError(err.message);
        }
      },
    });
  };

  const customSignIn = () => {
    setIsLoading(true);
    let authenticationData = {
      Username: data.phone,
      Password: data.password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: data.phone,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const d = result.getIdToken().decodePayload();
        store.dispatch(
          setUserLoggedIn({
            accessToken,
            phone: `+91${data.phone}`,
            email: d.email,
            name: d.name,
            userid: d.sub,
          })
        );

        setIsLoading(false);
        toast.success("Sign In Successful!");

        navigate("/");
      },

      onFailure: (err) => {
        setIsLoading(false);
        setSignInError(err.message);
      },
    });
  };

  return (
    <>
      <Helmet>
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Container>
        {signInError && (
          <FailureAlert
            errorMsg={signInError}
            errorTitle={"Error"}
            closeErrorPopup={() => setSignInError("")}
          />
        )}
        <Typography
          color="#fff"
          variant="h6"
          textAlign="center"
          sx={{ fontSize: { sm: "20px", md: "24px" } }}
        >
          SIGN IN
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            pt: "4em",
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", sm: "350px" },
              position: "relative",
              alignItems: "center",
            }}
            direction="column"
            rowGap={5}
          >
            {isLoading && (
              <CircularProgress
                sx={{
                  "&.MuiCircularProgress-colorPrimary": {
                    color: (theme) => theme.palette.green.light,
                  },
                  "&.MuiCircularProgress-root": {
                    width: "60px !important",
                    height: "60px !important",
                    position: "absolute",
                    top: "30%",
                    left: "40%",
                  },
                }}
              />
            )}

            <StyledTextField
              sx={{
                "& .MuiTypography-root": {
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 500,
                },
              }}
              required
              size="small"
              label="PHONE"
              variant="standard"
              name="phone"
              type="tel"
              autoComplete="on"
              onChange={handleChange}
              value={data.phone}
              // error={errors.firstName ? Boolean(errors.firstName) : false}
              // helperText={errors.firstName}
              InputProps={{
                startAdornment: (
                  <InputAdornment disablePointerEvents position="start">
                    +91
                  </InputAdornment>
                ),
              }}
            />

            <StyledTextField
              required
              size="small"
              label="PASSWORD"
              variant="standard"
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="on"
              onChange={handleChange}
              value={data.password}
              // error={errors.firstName ? Boolean(errors.firstName) : false}
              // helperText={errors.firstName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "#fff" }} />
                      ) : (
                        <Visibility sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <ButtonVariant1
                sx={(theme) => ({
                  color: theme.palette.black.main,
                  fontSize: { md: "18px", lg: "24px" },
                  borderRadius: "80px",
                  width: { sm: "100%", md: "90%" },
                  padding: { sm: "8px", md: "14px 35px" },
                })}
                onClick={() => signIn()}
              >
                SIGN IN
              </ButtonVariant1>

              <Typography
                variant="h3"
                color="#aaa"
                textAlign="center"
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontStyle: "italic",
                  cursor: "pointer",
                }}
                p="4px 0px"
                onClick={() => navigate("/auth/recover-password")}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                color: "#fff",
                fontWeight: 400,
                width: { xs: "100%", lg: "85%" },
                margin: "0 auto",
                display: "block",
                textAlign: "center",
              }}
              variant="body1"
              component="span"
            >
              Don't have an account? 
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  color: (theme) => theme.palette.green.light,
                  fontWeight: 600,

                  cursor: "pointer",
                }}
                m="0px 6px"
                component="span"
                variant="body2"
                textAlign="center"
                onClick={() => navigate("/auth/signup")}
              >
                SIGN UP
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default SignIn;
