import { Skeleton } from "@mui/material";
import React from "react";
import Drawer from "react-modern-drawer";

import "react-modern-drawer/dist/index.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAppSlice } from "../../redux/app/appSlice";
import { selectAuthSlice, signOut } from "../../redux/auth/authSlice";
import store from "../../redux/store";
import { INavLink } from "../../types/interfaces";
import { navLinksMobile } from "../../utils/navLinks";
import SwytchdLogo from "../SwytchdLogo/SwytchdLogo";

import styles from "./css/HamburgerDrawer.module.css";

interface IProp {
  variant: "black" | "white";
}

const HamburgerDrawer: React.FC<IProp> = ({ variant }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { isLoggedIn } = useSelector(selectAuthSlice);
  const { buttonText } = useSelector(selectAppSlice);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  return (
    <>
      {variant === "white" ? (
        <img
          onClick={toggleDrawer}
          src="/images/hamburger.svg"
          alt="hamburger"
          style={{
            cursor: "pointer",
          }}
        />
      ) : (
        <img
          onClick={toggleDrawer}
          src="/images/hamburger_black.svg"
          alt="hamburger"
          style={{
            cursor: "pointer",
          }}
        />
      )}

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size={"90vw"}
        style={{
          height: "95vh",
          marginTop: "2.5vh",
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.top}>
              <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
              <img
                style={{ cursor: "pointer" }}
                onClick={toggleDrawer}
                src="/images/close.svg"
                alt="close"
              />
            </div>
            <ul>
              {navLinksMobile.map((link: INavLink, ind: number) => {
                return (
                  <li
                    onClick={() => {
                      navigate(`/${link.to}`);
                      toggleDrawer();
                    }}
                    key={ind}
                  >
                    {link.name}
                  </li>
                );
              })}
            </ul>
            {!isLoggedIn ? (
              <div
                // onClick={() => isLoggedIn ? navigate("/join-waitlist") : navigate("/auth/signin")}
                onClick={() => navigate("/join-waitlist")}
                className={`button-variant-1 ${styles.btn}`}
              >
                {!!buttonText ? (
                  `${buttonText} From Rs.49`
                ) : (
                  <Skeleton
                    variant="text"
                    sx={{
                      width: "100%",
                      fontSize: "20px",
                      "&.MuiSkeleton-root": {
                        backgroundColor: "#17a642 !important",
                      },
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                <div
                  onClick={() => navigate("/subscriptions")}
                  className={`button-variant-1 ${styles.btn}`}
                >
                  SUBSCRIPTIONS
                </div>
                <div
                  onClick={() => navigate("/manage-profile")}
                  className={`button-variant-1 ${styles.btn}`}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  MANAGE PROFILE
                </div>
              </>
            )}
            {!isLoggedIn ? (
              <div
                style={{
                  color: "#24ae4f",
                  minWidth: "fit-content",
                  padding: "1em 0em",
                  fontWeight: 500,
                  cursor: "pointer",
                  textAlign: "center",
                  fontFamily: "var(--font-family-lexend)",
                }}
                onClick={() => navigate("/auth/signin")}
              >
                SIGN IN
              </div>
            ) : (
              <div
                style={{
                  color: "#24ae4f",
                  minWidth: "fit-content",
                  padding: "1em 0em",
                  fontWeight: 500,
                  cursor: "pointer",
                  textAlign: "center",
                  fontFamily: "var(--font-family-lexend)",
                }}
                onClick={() => store.dispatch(signOut())}
              >
                SIGN OUT
              </div>
            )}

            <div className={styles.bottom}>
              <p onClick={() => navigate("/terms-and-conditions")}>
                Terms of Use
              </p>
              <p onClick={() => navigate("/privacy-policy")}>Privacy Policy</p>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default HamburgerDrawer;
