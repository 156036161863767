import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import fuelCostReducer from "./fuelCost/fuelCostSlice";
import vehicleReducer from "./vehicle/vehicleSlice";
import joinWaitlistReducer from "./joinWaitlist/joinWaitlistSlice";
import compareReducer from "./compare/compareSlice";
import priceComparisionTabReducer from "./priceComparisionTab/priceComparisionTabSlice";
import authReducer from "./auth/authSlice"

const store = configureStore({
  reducer: {
    app: appReducer,
    fuelCost: fuelCostReducer,
    vehicle: vehicleReducer,
    joinWaitlist: joinWaitlistReducer,
    compare: compareReducer,
    priceComparisionTab: priceComparisionTabReducer,
    auth: authReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
