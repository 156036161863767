import { updateErrors } from "../../redux/joinWaitlist/joinWaitlistSlice";
import store from "../../redux/store";
import { IFormData } from "../../types/interfaces";

export const validateForm = (
  formData: IFormData,
) => {
  let errors = {
    fullName: "",
    email: "",
    phone: "",
    interestedVehicle: "",
    city: "",
    jobProfession: "",
  };

  if (!formData.fullName) {
    errors = {
      ...errors,
      fullName: "Full Name is required",
    };
  } else {
    errors = {
      ...errors,
      fullName: "",
    };
  }

  // if (!formData.lastName) {
  //   errors = {
  //     ...errors,
  //     lastName: "Last name is required",
  //   };
  // } else {
  //   errors = {
  //     ...errors,
  //     lastName: "",
  //   };
  // }

  if (!formData.email) {
    errors = {
      ...errors,
      email: "Email is required",
    };
  } else if (!formData.email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi)) {
    errors = {
      ...errors,
      email: "Enter valid email",
    };
  } else {
    errors = {
      ...errors,
      email: "",
    };
  }

  if (!formData.phone) {
    errors = {
      ...errors,
      phone: "Phone number is required",
    };
  } else if (!formData.phone.match(/^\d{10}$/)) {
    errors = {
      ...errors,
      phone: "Enter valid phone number",
    };
  } else {
    errors = {
      ...errors,
      phone: "",
    };
  }

  if (!formData.interestedVehicle) {
    errors = {
      ...errors,
      interestedVehicle: "Please select interested vehicle",
    };
  } else {
    errors = {
      ...errors,
      interestedVehicle: "",
    };
  }

  if (!formData.city) {
    errors = {
      ...errors,
      city: "Please select city",
    };
  } else {
    errors = {
      ...errors,
      city: "",
    };
  }

  if (!formData.jobProfession) {
    errors = {
      ...errors,
      jobProfession: "Job profession is required",
    };
  } else {
    errors = {
      ...errors,
      jobProfession: "",
    };
  }

  store.dispatch(updateErrors(errors));
};

export const isFormDataValid = (formData: IFormData) => {
  if (
    !formData.fullName ||
    !formData.city ||
    !formData.email ||
    !formData.interestedVehicle ||
    !formData.jobProfession ||
    !formData.phone ||
    !formData.email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi) ||
    !formData.phone.match(/^\d{10}$/)
  ) {
    return false;
  } else {
    return true;
  }
};
