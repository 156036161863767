import React from "react";
import Slider from "@mui/material/Slider";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import store from "../../redux/store";
import {
  selectFuelCostSlice,
  setDistance,
  setVehicleType,
} from "../../redux/fuelCost/fuelCostSlice";
import { useSelector } from "react-redux";

const DistanceSlider: React.FC = () => {
  const styles = {
    btn: {
      padding: "14px 37px",
      "@media (max-width: 769px)": {
        padding: "8px 16px",
      },
    },

    slider: {
      width: "377px",
      height: "10px",
      color: "#1e1e1e",
      boxShadow: "none",
      "& .MuiSlider-thumb": {
        color: "var(--color-variant-white)",
        boxShadow: "-2px -2px 20px 0px rgba(255,255,255,0.53);",
        webkitBoxShadow: "-2px -2px 20px 0px rgba(255,255,255,0.53)",
      },
      "@media (max-width: 769px)": {
        "& .MuiSlider-thumb": {
          width: 12,
          height: 12,
        },
        height: 5,
        width: "290px",
      },
    },

    valueContainer: {
      width: "500px",
      height: "67px",
      color: "var(--color-variant-white)",
      fontFamily: "var(--font-family-lexend)",
      background: "var(--color-variant-black-1)",
      borderRadius: "8px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "28px 0px",
      fontSize: "32px",
      border: "none",
      "@media (max-width: 1200px)": {
        width: "400px",
      },
      "@media (max-width: 992px)": {
        width: "350px",
      },
      "@media (max-width: 450px)": {
        width: "280px",
        height: "40px",
        fontSize: "var(--font-size-md)",
      },
      "&:focus": {
        outline: "none",
      },
    },

    sliderContainer: {
      display: "flex",
      alignItems: "center",
      gap: "25px",
      "@media (max-width: 450px)": {
        textAlign: "center",
      },
      "& p": {
        fontSize: "12px",
        fontWeight: 600,
        "@media (max-width: 450px)": {
          display: "none",
        },
      },
    },
  };

  const { selectedVehicleType, distance } = useSelector(selectFuelCostSlice);

  return (
    <Box
      sx={{
        color: "var(--color-variant-gray)",
        fontFamily: "var(--font-family-lexend)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "38px",
          padding: "36px 0px",
          "@media (max-width: 769px)": {
            gap: "10px",
          },
        }}
      >
        <Box
          onClick={() => store.dispatch(setVehicleType("CAR"))}
          sx={styles.btn}
          style={{
            borderColor:
              selectedVehicleType === "CAR"
                ? "var(--color-variant-white)"
                : "var(--color-variant-gray-1)",
            color:
              selectedVehicleType === "CAR"
                ? "var(--color-variant-white)"
                : "var(--color-variant-gray)",
          }}
          className={`button-variant-2`}
        >
          CAR
        </Box>
        <Box
          onClick={() => store.dispatch(setVehicleType("BIKE"))}
          sx={styles.btn}
          className={`button-variant-2`}
          style={{
            borderColor:
              selectedVehicleType === "BIKE"
                ? "var(--color-variant-white)"
                : "var(--color-variant-gray-1)",
            color:
              selectedVehicleType === "BIKE"
                ? "var(--color-variant-white)"
                : "var(--color-variant-gray)",
          }}
        >
          BIKE
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "var(--font-size-md)",
          }}
          component="p"
        >
          DAILY TRAVEL DISTANCE
        </Typography>
        <Box
          type="text"
          id="distance-input"
          value={distance}
          component="input"
          onChange={(e) => store.dispatch(setDistance(e.target.value))}
          contentEditable
          sx={styles.valueContainer}
        />

        <Box sx={styles.sliderContainer}>
          <Typography component="p">0 KM</Typography>
          <Slider
            size="medium"
            defaultValue={0}
            aria-label="distance-slider"
            aria-labelledby="distance-input"
            valueLabelDisplay="off"
            min={0}
            max={50}
            value={distance}
            sx={styles.slider}
            onChange={(e, v) => store.dispatch(setDistance(v))}
          />
          <Typography component="p">50 KM</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& p": {
              fontSize: "12px",
              fontWeight: 600,
            },
          }}
        >
          <Typography
            sx={{
              "@media (min-width: 450px)": {
                display: "none",
              },
            }}
            component="p"
          >
            0 KM
          </Typography>
          <Typography
            sx={{
              "@media (min-width: 450px)": {
                display: "none",
              },
            }}
            component="p"
          >
            50 KM
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DistanceSlider;
