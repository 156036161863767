import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  closeSnackbar,
  selectJoinWaitlistSlice,
} from "../../redux/joinWaitlist/joinWaitlistSlice";
import store from "../../redux/store";
import { ButtonVariant1 } from "../Buttons/Buttons";

export const SuccessAlert = () => {
  const { paymentStatus, paymentResponseMsg } = useSelector(
    selectJoinWaitlistSlice
  );

  return (
    <Snackbar
      open={paymentStatus === "success"}
      onClose={() => store.dispatch(closeSnackbar())}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        sx={(theme) => ({
          background: theme.palette.green.main,
          fontFamily: "var(--font-family-lexend)",
          borderRadius: "12px",
          fontSize: { xs: "12px", md: "15px" },
        })}
        variant="filled"
        severity="success"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              store.dispatch(closeSnackbar());
            }}
          >
            <img src="/images/close.svg" alt="close" />
          </IconButton>
        }
      >
        <AlertTitle>Success</AlertTitle>
        You have joined the waitlist. Payment with id "{paymentResponseMsg}" is
        successfull.
      </Alert>
    </Snackbar>
  );
};

export const SuccessModal = () => {
  const { paymentStatus } = useSelector(selectJoinWaitlistSlice);

  const navigate = useNavigate();

  return (
    <Modal
      open={paymentStatus === "success"}
      onClose={() => store.dispatch(closeSnackbar())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", lg: 890 },
          bgcolor: "background.paper",
          border: "none",
          borderRadius: "15px",
          boxShadow: 24,
          p: "20px",
          outline: "none",
        }}
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            color: theme.palette.green.main,
            fontSize: { xs: "15px", md: "30px" },
            textAlign: "center",
          })}
        >
          Congratulations! you&#39;ve positively SWYTCHD
        </Typography>

        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontFamily: "var(--font-family-lexend)",
              fontSize: { xs: "12px", md: "20px" },
              mt: "20px",
            })}
          >
            Please see your email for confirmation and next steps.
          </Typography>
          {/* <Typography
            variant="body1"
            sx={(theme) => ({
              fontFamily: "var(--font-family-lexend)",
              fontSize: { xs: "12px", md: "20px" },
              mt: "20px",
            })}
          >
            We appreciate you taking the time to book a vehicle. Here's what
            happens next :&#41;
          </Typography> */}

          {/* <Typography
            variant="body1"
            sx={(theme) => ({
              fontFamily: "var(--font-family-lexend)",
              fontSize: { xs: "12px", md: "20px" },
            })}
          >
            <ul style={{ width: "85%", margin: "0 auto" }}>
              <li style={{ margin: "10px 0px" }}>
                1. We&#39;ll reach out to you through a Phone call, WhatsApp or
                Email within 24 hours.
              </li>
              <li style={{ margin: "10px 0px" }}>
                2. Ask for a copy of your Driver&#39;s Licence and another
                Government Approved ID for basic KYC.
              </li>
              <li>
                3. Agree a date and time for you to get into an EV of your
                choice!
              </li>
            </ul>
          </Typography> */}

          {/* <Typography
            variant="body1"
            sx={(theme) => ({
              fontFamily: "var(--font-family-lexend)",
              fontSize: { xs: "12px", md: "20px" },
              m: "20px 0px",
              whiteSpace: "pre-wrap",
            })}
          >
            If you have any questions, don&#39;t hesitate to write to us @
            customer@swytchd.com
          </Typography> */}
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontFamily: "var(--font-family-lexend)",
              fontSize: { xs: "12px", md: "20px" },
            })}
          >
            You&#39;ve just made the world a quieter, cleaner and better place.
            Well done, you Pioneer!
          </Typography>

          <ButtonVariant1
            sx={{
              background: (theme) => theme.palette.green.light,
              color: (theme) => theme.palette.black.light,
              fontSize: { xs: "12px", md: "20px" },
              mt: "40px",
            }}
            onClick={() => {
              store.dispatch(closeSnackbar());
              navigate("/");
            }}
          >
            BACK TO HOME
          </ButtonVariant1>
        </Box>
      </Box>
    </Modal>
  );
};

export const FailureAlert = () => {
  const { paymentStatus, paymentResponseMsg } = useSelector(
    selectJoinWaitlistSlice
  );

  return (
    <Snackbar
      open={paymentStatus === "failure"}
      onClose={() => store.dispatch(closeSnackbar())}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        sx={(theme) => ({
          fontFamily: "var(--font-family-lexend)",
          borderRadius: "12px",
          fontSize: { xs: "12px", md: "15px" },
        })}
        variant="filled"
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              store.dispatch(closeSnackbar());
            }}
          >
            <img src="/images/close.svg" alt="close" />
          </IconButton>
        }
      >
        <AlertTitle>Failure</AlertTitle>
        {paymentResponseMsg}
      </Alert>
    </Snackbar>
  );
};
