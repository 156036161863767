import {
  Stack,
  Box,
  Card,
  Alert,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Header from "../../layout/Header";
import { selectAuthSlice } from "../../redux/auth/authSlice";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";

const ProfileManagement = () => {
  const windowWidth = useWindowWidth();

  const { loggedInUser } = useSelector(selectAuthSlice);

  const [document, setDocument] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [aadharCard, setAadharCard] = useState<any>();
  const [aadharCardName, setAadharCardName] = useState<any>();
  const [drivingLicence, setDrivingLicence] = useState<any>();
  const [drivingLicenceName, setDrivingLicenceName] = useState<any>();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  useEffect(() => {
    (async () => {
      const { data } = await instance.get(
        `/kf/user/data/${loggedInUser.phone.slice(-10)}`
      );
      setDocument(data[0]);
    })();
  }, [instance, loggedInUser]);

  useEffect(() => {
    if (document?.Aadhar_Card_Valid === false) {
      if (aadharCard) {
        setIsDisabled(false);
      }
    }
    if (document?.Driving_Licence_Valid === false) {
      if (drivingLicence) {
        setIsDisabled(false);
      }
    }
  }, [
    document?.Aadhar_Card_Valid,
    document?.Driving_Licence_Valid,
    aadharCard,
    drivingLicence,
  ]);

  const uploadAadharCard = async (event: any) => {
    setAadharCard(event.target.files[0]);
    setAadharCardName(event.target.files[0].name);
  };

  const uploadDrivingLicence = async (event: any) => {
    setDrivingLicence(event.target.files[0]);
    setDrivingLicenceName(event.target.files[0].name);
  };

  const removeAadharCard = async () => {
    setAadharCard(null);
    setAadharCardName(null);
  };

  const removeDrivingLicence = async () => {
    setDrivingLicence(null);
    setDrivingLicenceName(null);
  };

  const handleUpload = async () => {
    if (document?.Aadhar_Card_Valid === false) {
      if (!aadharCard) {
        toast.error("Please upload Aadhaar Card!", {
          duration: 8000,
          position: "top-center",
        });
        return;
      }
    }
    if (document?.Driving_Licence_Valid === false) {
      if (!drivingLicence) {
        toast.error("Please upload Driving Licence!", {
          duration: 8000,
          position: "top-center",
        });
        return;
      }
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("userid", loggedInUser.userid);
      if (document?.Aadhar_Card_Valid === false) {
        formData.append("aadhaar", aadharCard);
      }
      if (document?.Driving_Licence_Valid === false) {
        formData.append("driving", drivingLicence);
      }
      const { data } = await instance.post(
        "/kf/user/onboarding/upload",
        formData
      );
      if (data.success === "Submitted successfully") {
        setIsLoading(false);
        setIsDisabled(true);
        toast.success(
          "KYC documents uploaded successfully! Wait for approval from SWYTCHD team.",
          {
            duration: 10000,
            position: "top-center",
            style: {
              fontSize: "16px",
              fontFamily: "Lexend",
              fontWeight: 500,
              minWidth: "25%",
              width: "25%",
            },
          }
        );
        removeAadharCard();
        removeDrivingLicence();
      } else {
        setIsLoading(false);
        toast.error("SOMETHING UNTOWARD HAPPENED, PLEASE TRY AGAIN", {
          duration: 10000,
          position: "top-center",
        });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Helmet>
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Stack
        sx={{ p: { xs: "20px 18px", lg: "26px 80px" } }}
        direction="row"
        justifyContent="space-between"
      >
        <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
        <Box>
          {windowWidth > 900 ? (
            <Header color="var(--color-variant-black)" />
          ) : (
            <HamburgerDrawer variant="black" />
          )}
        </Box>
      </Stack>
      <Stack sx={{ p: { xs: "10px 0px", lg: "18px 0px" } }} direction="column">
        <Typography
          sx={{
            p: {
              xs: "10px 15px",
              md: "10px 5rem",
            },
            fontSize: {
              xs: "20px",
              md: "30px",
            },
          }}
          variant="h2"
        >
          Your Profile
        </Typography>
      </Stack>
      <Card
        sx={{
          "&.MuiCard-root": {
            display: "flex",
            flexDirection: "column",
            width: "90%",
            margin: "0 auto",
            rowGap: "10px",
            p: "10px",
          },
        }}
      >
        {document?.Aadhar_Card_Valid === false ? (
          <Alert severity="warning">
            {document?.Aadhar_Card_Not_Valid_Reason}
          </Alert>
        ) : null}
        {document?.Driving_Licence_Valid === false ? (
          <Alert severity="warning">
            {document?.Driving_Licence_Not_Valid_Reason}
          </Alert>
        ) : null}
        <TextField
          sx={{
            "& label.Mui-disabled": {
              WebkitTextFillColor: "#000",
            },
          }}
          size="small"
          label="FULL NAME"
          variant="standard"
          name="fullName"
          value={loggedInUser.name}
          disabled={true}
        />

        <TextField
          sx={{
            "& label.Mui-disabled": {
              WebkitTextFillColor: "#000",
            },
          }}
          size="small"
          label="MOBILE"
          variant="standard"
          name="phone"
          value={loggedInUser.phone}
          disabled={true}
        />

        <TextField
          sx={{
            "& label.Mui-disabled": {
              WebkitTextFillColor: "#000",
            },
          }}
          size="small"
          label="EMAIL"
          variant="standard"
          name="email"
          value={loggedInUser.email}
          disabled={true}
        />

        <TextField
          sx={{
            "& label.Mui-disabled": {
              WebkitTextFillColor: "#000",
            },
          }}
          size="small"
          label="LOCATION"
          variant="standard"
          name="location"
          value={"BENGALURU"}
          disabled={true}
        />
        <Typography variant="body1" fontWeight="900" sx={{ mt: 2 }}>
          KYC Documents
        </Typography>

        <Stack direction="row" justifyContent="space-between">
          {aadharCard != null ? (
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography variant="body1">Aadhar Card </Typography>
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.palette.green.main }}
              >
                {" "}
                ({aadharCardName})
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1">Aadhar Card</Typography>
          )}
          {document?.Aadhar_Card_Valid === true ? (
            <Typography
              onClick={() => {
                window.open(document?.Aadhar_Card_Document, "blank");
              }}
              variant="body1"
              sx={{
                color: (theme) => theme.palette.green.main,
                fontWeight: "800",
                cursor: "pointer",
              }}
            >
              VIEW
            </Typography>
          ) : aadharCard != null ? (
            <>
              <Typography
                sx={{
                  color: (theme) => theme.palette.red.main,
                  fontWeight: "800",
                  cursor: "pointer",
                }}
                onClick={removeAadharCard}
              >
                REMOVE{" "}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="label"
                htmlFor="aadhar_card"
                variant="body1"
                sx={{
                  color: (theme) => theme.palette.green.main,
                  fontWeight: "800",
                  cursor: "pointer",
                }}
              >
                UPLOAD{" "}
              </Typography>
              <input
                onChange={uploadAadharCard}
                name="aadhar_card"
                type="file"
                id="aadhar_card"
                style={{ display: "none" }}
              />
            </>
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          {drivingLicence != null ? (
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography variant="body1">Driving License </Typography>
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.palette.green.main }}
              >
                {" "}
                ({drivingLicenceName})
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1"> Driving License</Typography>
          )}
          {document?.Driving_Licence_Valid === true ? (
            <Typography
              onClick={() => {
                window.open(document?.Driving_Licence_Document, "blank");
              }}
              variant="body1"
              sx={{
                color: (theme) => theme.palette.green.main,
                fontWeight: "800",
                cursor: "pointer",
              }}
            >
              VIEW
            </Typography>
          ) : drivingLicence != null ? (
            <>
              <Typography
                sx={{
                  color: (theme) => theme.palette.red.main,
                  fontWeight: "800",
                  cursor: "pointer",
                }}
                onClick={removeDrivingLicence}
              >
                REMOVE
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="label"
                htmlFor="driving_licence"
                variant="body1"
                sx={{
                  color: (theme) => theme.palette.green.main,
                  fontWeight: "800",
                  cursor: "pointer",
                }}
              >
                UPLOAD
              </Typography>
              <input
                onChange={uploadDrivingLicence}
                name="driving_licence"
                type="file"
                id="driving_licence"
                style={{ display: "none" }}
              />
            </>
          )}
        </Stack>
        {isLoading && (
          <CircularProgress
            sx={{
              "&.MuiCircularProgress-colorPrimary": {
                color: (theme) => theme.palette.green.light,
              },
              "&.MuiCircularProgress-root": {
                width: "60px !important",
                height: "60px !important",
                position: "absolute",
                top: "50%",
                left: "50%",
              },
            }}
          />
        )}
      </Card>
      <ButtonVariant1
        sx={{
          // visibility: "hidden",
          background: (theme) => theme.palette.green.light,
          color: (theme) => theme.palette.white.light,
          fontSize: { xs: "12px", md: "20px" },
          mt: "40px",
          backgroundColor: (theme) => theme.palette.green.main,
          borderRadius: "20px",
          width: "10%",
          padding: "6px 0px",
          left: "50%",
          transform: "translate(-50%, -50%)",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.green.main,
          },
        }}
        onClick={handleUpload}
        disabled={isLoading || isDisabled}
      >
        Submit
      </ButtonVariant1>

      {/* <Card
        sx={{
          "&.MuiCard-root": {
            display: "flex",
            flexDirection: "column",
            width: "90%",
            margin: "10px auto",
            rowGap: "10px",
            p: "10px",
            mt: "20px",
          },
        }}
      ></Card> */}
    </>
  );
};

export default ProfileManagement;
