import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface IProp {
  cost: number;
}

const FuelCostCard: React.FC<IProp> = ({ cost }) => {
  return (
    <Box
      sx={{
        width: "305px",
        fontFamily: "var(--font-family-lexend)",
        "@media (max-width: 769px)": {
          width: "210px",
        },
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "var(--color-variant-gray)",
          fontWeight: 500,
          fontSize: "var(--font-size-md)",
        }}
        component="p"
      >
        PETROL
      </Typography>
      <Box
        sx={{
          textAlign: "center",
          padding: "45px 0px",
          border: "1px solid var(--color-variant-white)",
          borderRadius: "15px",
          color: "var(--color-variant-white)",
          marginTop: 2,
          "@media (max-width: 769px)": {
            padding: "10px 0px",
          },
        }}
      >
        <Typography
          sx={{
            "@media (max-width: 769px)": {
              fontSize: "10px",
            },
          }}
          component="p"
        >
          FUEL COST
        </Typography>
        <Typography
          sx={{
            fontFamily: "var(--font-family-fraunces)",
            fontStyle: "italic",
            fontWeight: 400,
            letterSpacing: "0.02em",
            fontSize: "48px",
            "@media (max-width: 769px)": {
              fontSize: "24px",
            },
          }}
          component="p"
        >
          &#8377;{cost}
        </Typography>
      </Box>
    </Box>
  );
};

export default FuelCostCard;
