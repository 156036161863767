import { Box, Stack, styled } from "@mui/material";
import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { IBikeData, ICarData } from "../../types/interfaces";
import VehicleList from "./VehicleList";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.light,
  padding: "15px 70px",
  [theme.breakpoints.down("md")]: {
    padding: "15px 20px",
    minWidth: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

interface IProp {
  vehicle: IBikeData[] | ICarData[];
}

const ImageSlider: React.FC<IProp> = ({ vehicle }) => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  const handleHorizantalScroll = (element: HTMLDivElement, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between">
        <Stack
          sx={{
            width: { md: "80%", sm: "90%" },
            overflowY: "hidden",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
          direction="row"
          spacing={2}
          {...events}
          ref={ref}
        >
          {vehicle.map((el, id) => {
            return (
              <Box key={id}>
                <VehicleList vehicle={el} />
              </Box>
            );
          })}
        </Stack>
        <Box
          component="img"
          src="/images/arrow-right-circle.svg"
          alt="Right Arrow"
          alignSelf="flex-end"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleHorizantalScroll(ref.current, 25, 250, 20);
          }}
        />
      </Stack>
    </Container>
  );
};

export default ImageSlider;
