import styled from '@emotion/styled'
import { Grid, Skeleton } from '@mui/material'

export const StyledSkeleton = styled(Skeleton)({
  "&.MuiSkeleton-root": {
    borderRadius: "20px"
  }
})


export const StyledSkeletonText = styled(Skeleton)({
  "&.MuiSkeleton-root": {
    borderRadius: "19.75px"
  }
})



const VehicleListLoader = () => {
  return (
    <Grid
      container
      rowSpacing="70px"
      sx={{
        justifyContent: { xl: "flex-start", lg: "space-between" },
        mt: "7px"
      }}
    >
      <Grid item xl={4} md={6} lg={6}>
        <StyledSkeleton variant="rectangular" animation="wave" width={300} height={300} />
        <StyledSkeletonText variant="text" animation="wave" width={300} height={100} />
      </Grid>
      <Grid item xl={4} md={6} lg={6}>
        <StyledSkeleton variant="rectangular" animation="wave" width={300} height={300} />
        <StyledSkeletonText variant="text" animation="wave" width={300} height={100} />
      </Grid>
      <Grid item xl={4} md={6} lg={6}>
        <StyledSkeleton variant="rectangular" animation="wave" width={300} height={300} />
        <StyledSkeletonText variant="text" animation="wave" width={300} height={100} />
      </Grid>
      <Grid item xl={4} md={6} lg={6}>
        <StyledSkeleton variant="rectangular" animation="wave" width={300} height={300} />
        <StyledSkeletonText variant="text" animation="wave" width={300} height={100} />
      </Grid>
      <Grid item xl={4} md={6} lg={6}>
        <StyledSkeleton variant="rectangular" animation="wave" width={300} height={300} />
        <StyledSkeletonText variant="text" animation="wave" width={300} height={100} />
      </Grid>
      <Grid item xl={4} md={6} lg={6}>
        <StyledSkeleton variant="rectangular" animation="wave" width={300} height={300} />
        <StyledSkeletonText variant="text" animation="wave" width={300} height={100} />
      </Grid>
    </Grid>
  )
}

export default VehicleListLoader
