import React from "react";
import { useNavigate } from "react-router-dom";

interface IProp {
  color: string;
  isBlack?: boolean;
}

const SwytchdLogo: React.FC<IProp> = ({ color, isBlack }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        fontFamily: "var(--font-family-lexend)",
        fontWeight: 700,
        fontSize: "20px",
        color: color,
        cursor: "pointer",
      }}
      onClick={() => navigate("/")}
    >
      {isBlack ? (
        <img src="/images/swytchd_logo_black.svg" alt="Swytchd Logo" />
      ) : (
        <img src="/images/swytchd_logo.svg" alt="Swytchd Logo" />
      )}
      <p>SWYTCHD</p>
    </div>
  );
};

export default SwytchdLogo;
