export function getShortName(str: string) {
  let result = "";

  let v = true;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === ' ') {
      v = true;
    }

    else if (str[i] !== ' ' && v === true) {
      result += (str[i]);
      v = false;
    }
  }
  return result;
}
