import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routeSlug } from "../../utils/createRouteSlug";
import { ButtonVariant1 } from "../Buttons/Buttons";

interface IProp {
  vehicleData: any;
  type: "car" | "bike";
  hideButton?: boolean;
  fontMax?: string;
}

const SmallVehicleDisplayCard: React.FC<IProp> = ({ fontMax, vehicleData, type, hideButton }) => {
  const navigate = useNavigate();

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "14px",
        background: (theme) => theme.palette.white.light,
        backgroundImage: "url('/images/vehicle-component-vector.svg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundPositionY: "90%",
        backgroundSize: "contain",
        p: "0px 10px 16px 10px",
      }}
    >
      <CardHeader
        sx={{ p: "10px 0px 0px 10px" }}
        title={<Typography variant="h6">
          {`${vehicleData.Make} ${vehicleData.Model}`}
        </Typography>}
        subheader={
          <Typography variant="h5">
            &#8377; {Number(vehicleData?.CostMonth.split(" ")[0]).toLocaleString("en-IN")}/month
          </Typography>
        }
      />
      <CardContent sx={{ p: "0px 10px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack minWidth="80px" direction="column" spacing={1}>
            <>
              <Typography variant="h2" fontSize={fontMax ? fontMax : "10px"}>
                SPEED {vehicleData.Top_Speed}
              </Typography>
              <Typography variant="h2" fontSize={fontMax ? fontMax : "10px"}>
                RANGE {vehicleData.Range}
              </Typography>
              <Typography variant="h2" fontSize={fontMax ? fontMax : "10px"}>
                CHARGING TIME {vehicleData.Charging_Time.replace("hours", "hr").replace("mins", "m")}
              </Typography>
            </>

          </Stack>
          <Box
            sx={{
              width: "180px",
              height: "140px",
              objectFit: "contain"
            }}
            component="img"
            src={`${process.env.REACT_APP_IMG_URL}/vehicles/${vehicleData.Vehicle_Type.toLowerCase()}s/${vehicleData.Make.toLowerCase()}/${vehicleData.Model.replaceAll(" ", "+").toLowerCase()}/${vehicleData.Model.replaceAll(" ", "_").toLowerCase()}.webp`}
            alt="car"
          />
        </Stack>
      </CardContent>
      {
        !hideButton ? <CardActions sx={{ justifyContent: "center" }}>
          <ButtonVariant1
            sx={{ background: (theme) => theme.palette.green.main }}
            onClick={() =>
              navigate(`${routeSlug(vehicleData.Model)}`)
            }
          >
            {type === "bike"
              ? vehicleData.Total_Available > 0
                ? "BOOK NOW @49"
                : "JOIN WAITLIST @49"
              : type === "car"
                ? vehicleData.Total_Available > 0
                  ? "BOOK NOW @999"
                  : "JOIN WAITLIST @999"
                : ""}
          </ButtonVariant1>
        </CardActions> :
          <></>
      }
      {/* {
        !hideButton &&
        <CardActions sx={{ justifyContent: "center" }}>
          <ButtonVariant1
            sx={{ background: (theme) => theme.palette.green.main }}
            onClick={() =>
              navigate(`${routeSlug(vehicleData.Model)}`)
            }
          >
            {type === "bike"
              ? vehicleData.Available > 0
                ? "BOOK NOW @99"
                : "JOIN WAITLIST @99"
              : type === "car"
                ? vehicleData.Available > 0
                  ? "BOOK NOW @999"
                  : "JOIN WAITLIST @999"
                : ""}
          </ButtonVariant1>
        </CardActions>
      }
      {
        hideButton ? <></> :
          <CardActions sx={{ justifyContent: "center" }}>
            <ButtonVariant1
              sx={{ background: (theme) => theme.palette.green.main }}
              onClick={() =>
                navigate(`${routeSlug(vehicleData.Model)}`)
              }
            >
              {type === "bike"
                ? vehicleData.Total_Available > 0
                  ? "BOOK NOW @49"
                  : "JOIN WAITLIST @49"
                : type === "car"
                  ? vehicleData.Total_Available > 0
                    ? "BOOK NOW @999"
                    : "JOIN WAITLIST @999"
                  : ""}
            </ButtonVariant1>
          </CardActions>
      } */}
    </Card>
  );
};

export default SmallVehicleDisplayCard;
