import { Skeleton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAppSlice } from "../../redux/app/appSlice";

const ServicesBannerMobile: React.FC = () => {
  const styles: any = {
    container: {
      width: "100%",
      minWidth: "100%",
      fontFamily: "var(--font-family-lexend)",
      color: "var(--color-variant-black)",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 300,
      padding: "25px 0px",
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      flexShrink: "revert-layer",
    },

    content: {
      display: "flex",
      flexWrap: "wrap",
      gap: "12px",
      justifyContent: "center",
    },

    text: {
      textAlign: "center",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      padding: "10px 0px",
    },

    span: {
      fontFamily: "var(--font-family-fraunces)",
      fontStyle: "italic",
      fontWeight: 600,
      color: "var(--color-variant-green)",
      margin: "0px 2px",
    },
    btn: {
      backgroundColor: "var(--color-variant-green)",
      color: "#ffffff",
      fontWeight: 600,
      fontSize: "14px",
      width: "300px",
      margin: "0 auto",
      borderColor: "var(--color-variant-green)",
    },
  };

  const navigate = useNavigate();

  const { buttonText } = useSelector(selectAppSlice);

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <li>LOW DEPOSIT</li>
          <li>|</li>
          <li>INSURANCE</li>
          <li>|</li>
          <li>SERVICING</li>
        </div>
        <div style={styles.content}>
          <li>RECOVERY</li>
          <li>|</li>
          <li>1500 KM/PM</li>
        </div>
      </div>
      <div
        style={{
          padding: "20px 0px",
        }}
      >
        <p style={styles.text}>
          Just
          <span style={styles.span}>swytch</span>
          to the good part
        </p>
        <div
          onClick={() => navigate("/join-waitlist")}
          style={styles.btn}
          className="button-variant-1"
        >
          {
            !!buttonText ? `${buttonText} From Rs.49` :
              <Skeleton variant="text" sx={{
                width: "100%",
                fontSize: "20px",
                "&.MuiSkeleton-root": {
                  backgroundColor: "#17a642 !important"
                }
              }} />
          }
        </div>
      </div>
    </div>
  );
};

export default ServicesBannerMobile;
