import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Tab from "../../components/Tab/Tab";
import CostCard from "../../components/WhySwytchedCards/CostCard";
import FeaturesCard from "../../components/WhySwytchedCards/FeaturesCard";
import useWindowWidth from "../../hooks/useWindowWidth";
import {
  handleChange,
  handleSelectedTabBikes,
  handleSelectedTabCars,
  selectPriceComparisionTabSlice,
  setSelectedTab,
} from "../../redux/priceComparisionTab/priceComparisionTabSlice";
import store from "../../redux/store";
import {
  getCarData,
  getBikeData,
} from "../../redux/priceComparisionTab/priceComparisionTabSlice";
import { COST_TYPE_SHORT } from "../../utils/costList";
import styles from "./css/WhySwytchd.module.css";
import { Skeleton } from "@mui/material";

const WhySwytchdSection: React.FC = () => {
  const windowWidth = useWindowWidth();

  const {
    isLoading,
    priceComparisionData,
    priceComparisionDataHardCoded,
    index,
    selectedTab,
  } = useSelector(selectPriceComparisionTabSlice);

  useEffect(() => {
    if (selectedTab === "CARS") {
      store.dispatch(handleSelectedTabCars());
    } else if (selectedTab === "BIKES") {
      store.dispatch(handleSelectedTabBikes());
    }
  }, [selectedTab]);

  useEffect(() => {
    store.dispatch(getCarData());
    store.dispatch(getBikeData());
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {windowWidth < 769 && (
          <div className={styles.header}>
            <h1>WHY SWYTCHD?</h1>
            <p
              style={{
                fontSize: "16px",
                paddingTop: "0px",
                paddingBottom: "40px",
              }}
              className={styles.header_text}
            >
              Skip the hassles of ownership,
              <span
                style={{
                  fontSize: "16px",
                  display: "block",
                }}
              >
                just swytch to the good part
              </span>
            </p>
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.toLeft}>
            <FeaturesCard />
          </div>

          <div className={styles.toRight}>
            {windowWidth > 769 && (
              <div className={styles.header}>
                <h1>WHY SWYTCHD?</h1>
                <p className={styles.header_text}>
                  Enjoy the ride, skip the hassles,
                  <span>just swytch to the good part</span>
                </p>
              </div>
            )}
            {windowWidth > 1000 ? (
              <>
                <Tab />
                <p className={styles.header_text}>
                  <span
                    style={{
                      fontSize: "18px",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    SWYTCHD is subscription only, purchase of vehicle is not
                    possible.
                  </span>
                </p>
              </>
            ) : (
              <>
                <div className={styles.selector}>
                  <div
                    onClick={() => store.dispatch(setSelectedTab("BIKES"))}
                    className={styles.selector_tab}
                    style={{
                      color:
                        selectedTab === "BIKES"
                          ? "var(--color-variant-green-1)"
                          : "var(--color-variant-white)",
                    }}
                  >
                    BIKES
                  </div>
                  <div
                    onClick={() => store.dispatch(setSelectedTab("CARS"))}
                    className={styles.selector_tab}
                    style={{
                      color:
                        selectedTab === "CARS"
                          ? "var(--color-variant-green-1)"
                          : "var(--color-variant-white)",
                    }}
                  >
                    CARS
                  </div>
                </div>
                <div className={styles.mobile_cost_list}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "23px 0px",
                      }}
                    >
                      <img
                        onClick={() => store.dispatch(handleChange("left"))}
                        src="/images/arrow-left-white.svg"
                        alt="left"
                      />
                      {!isLoading && priceComparisionData ? (
                        <p>
                          {`${priceComparisionData[index]?.Make} ${priceComparisionData[index]?.Model}`}
                        </p>
                      ) : (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "12px",
                            bgcolor: "#c1c1c1",
                            width: "100%",
                          }}
                        />
                      )}
                      <img
                        onClick={() => store.dispatch(handleChange("right"))}
                        src="/images/arrow-right-white.svg"
                        alt="right"
                      />
                    </div>
                    <p
                      style={{
                        color: "var(--color-variant-green-1)",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStyle: "italic",
                        fontFamily: "var(--font-family-fraunces)",
                      }}
                    >
                      Subscribe
                    </p>
                  </div>
                  <div className={styles.mobile_cost_list_data}>
                    <div style={{ marginTop: "1.2rem" }}>
                      {COST_TYPE_SHORT.map((type, ind) => {
                        return (
                          <p className={styles.mobile_cost_lists} key={ind}>
                            {type}
                          </p>
                        );
                      })}
                    </div>
                    {priceComparisionData && (
                      <CostCard
                        title="Subscribe"
                        data={priceComparisionData[index]}
                        selectedTab={selectedTab}
                        dataHardCoded={priceComparisionDataHardCoded}
                      />
                    )}
                  </div>

                  <div>
                    <p
                      style={{
                        marginTop: "3rem",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "var(--font-family-lexend)",
                      }}
                    >
                      Buy
                      <span
                        style={{
                          fontWeight: 300,
                          marginLeft: "5px",
                        }}
                      >
                        {selectedTab === "CARS" ? "(3y loan)" : "(2y loan)"}
                      </span>
                    </p>
                  </div>
                  <div className={styles.mobile_cost_list_data}>
                    <div style={{ marginTop: "1.2rem" }}>
                      {COST_TYPE_SHORT.map((type, ind) => {
                        return (
                          <p className={styles.mobile_cost_lists} key={ind}>
                            {type}
                          </p>
                        );
                      })}
                    </div>
                    {priceComparisionData && (
                      <CostCard
                        title="Buy"
                        data={priceComparisionData[index]}
                        selectedTab={selectedTab}
                        dataHardCoded={priceComparisionDataHardCoded}
                      />
                    )}
                  </div>
                </div>
                <p
                  className={styles.header_text}
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    SWYTCHD is subscription only, purchase of vehicle is not
                    possible.
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySwytchdSection;
