import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAppSlice } from "../redux/app/appSlice";
import { selectAuthSlice, signOut } from "../redux/auth/authSlice";
import store from "../redux/store";
import { INavLink } from "../types/interfaces";
import { getShortName } from "../utils/getShortName";
import { navLinks } from "../utils/navLinks";
import styles from "./css/Header.module.css";

interface IHeaderProp {
  /**
   * color is the color of active link.
   */
  color: string;
}

const Header: React.FC<IHeaderProp> = ({ color }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (link: INavLink) => {
    navigate(`/${link.to}`);
  };

  const { isLoggedIn, loggedInUser } = useSelector(selectAuthSlice);
  const { buttonText } = useSelector(selectAppSlice);

  return (
    <div className={styles.nav_links}>
      <ul>
        {navLinks.map((link: INavLink, ind: number) => {
          return (
            <li
              style={{
                color:
                  location.pathname.split("/")[1] === `${link.to}`
                    ? color
                    : "var(--color-variant-gray)",
              }}
              key={ind}
              onClick={() => handleClick(link)}
            >
              {link.name}
            </li>
          );
        })}
      </ul>
      <div
        style={{
          color: "#24ae4f",
          fontWeight: 500,
        }}
        className={`button-variant-1 ${styles.join_waitlist_btn} ${styles.join_waitlist_btn_display_control}`}
        onClick={() => navigate("/join-waitlist")}
      >
        {!!buttonText ? (
          `${buttonText}`
        ) : (
          <Skeleton
            variant="text"
            sx={{
              width: "60px",
              fontSize: "20px",
              "&.MuiSkeleton-root": {
                backgroundColor: "#17a642 !important",
              },
            }}
          />
        )}
      </div>
      {isLoggedIn ? (
        <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick1}
          >
            <Avatar
              sx={{
                "&.MuiAvatar-root": {
                  fontSize: "18px",
                  backgroundColor: (theme) => theme.palette.green.main,
                  color: "#000",
                },
                "&.MuiAvatar-root:hover": {
                  backgroundColor: (theme) => theme.palette.black.main,
                  color: "#fff",
                },
              }}
            >
              {getShortName(loggedInUser.name).slice(0, 2)}
            </Avatar>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/subscriptions");
              }}
            >
              <Typography variant="body1">Subscriptions</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/manage-profile");
              }}
            >
              <Typography variant="body1">Manage Profile</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                store.dispatch(signOut());
              }}
            >
              <Typography variant="body1">Logout</Typography>
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div
          style={{
            color: "#24ae4f",
            minWidth: "fit-content",
            fontWeight: 500,
            cursor: "pointer",
          }}
          className={`button-variant-1 ${styles.join_waitlist_btn}`}
          onClick={() => navigate("/auth/signin")}
        >
          SIGN IN
        </div>
      )}
    </div>
  );
};

export default Header;
