import "./App.css";
import Router from "./routes";
import { theme } from "./theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import "./axios/axios";
import ReactGA from "react-ga";
import { useEffect } from "react";
import userPool from "./userPool";
import store from "./redux/store";
import { setUserLoggedIn, toggleIsLoggedIn } from "./redux/auth/authSlice";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { detectButtonName } from "./utils/detectButtonName";
import { setButtonText } from "./redux/app/appSlice";
import { getAllVehicleData, selectVehicleSlice } from "./redux/vehicle/vehicleSlice";

const TRACKING_ID = "GTM-T83SNTC";
ReactGA.initialize(TRACKING_ID);

const App = () => {

  const { allVehicleData } = useSelector(selectVehicleSlice);


  useEffect(() => {
    if (allVehicleData.length > 0) {

      let _ = detectButtonName(allVehicleData);

      store.dispatch(setButtonText(_));
    }

  }, [allVehicleData])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let user = userPool.getCurrentUser()

    if (user) {
      (user.getSession((err: any, session: any) => {
        if (err) {
          return;
        }

        if (!session.isValid()) {
          return;
        }

        store.dispatch(toggleIsLoggedIn())
        let accessToken = session.accessToken.jwtToken;
        let d = session.idToken.payload;

        store.dispatch(setUserLoggedIn({
          accessToken: accessToken,
          phone: d.phone_number,
          email: d.email,
          name: d.name,
          userid: d.sub
        }))
      }))
    }
  }, []);



  useEffect(() => {
    store.dispatch(getAllVehicleData());
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Toaster toastOptions={{
        duration: 3000
      }} />
      <Router />
    </ThemeProvider>
  );
};

export default App;
