import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUnixTimestamp, getDays } from "../../utils/getDays";
import { getDaysBetweenEpochDates } from "../../utils/epochDatesDiff";
import { ButtonVariant1, ButtonVariant2 } from "../Buttons/Buttons";

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "305px",
  height: "400px",
  padding: "28px 53px 28px 28px",
  backgroundColor: theme.palette.white.light,
  borderRadius: "20px",
  backgroundImage: "url('/images/hero_vector.svg')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  backgroundPositionY: 200,
}));

const CarImage = styled("img", {
  shouldForwardProp: (prop) =>
    prop !== "isHovered" && prop !== "type" && prop !== "vehicleId",
})<{ type: "bike" | "car" }>(({ type, theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  transform: "scale(0.8) translate(-10%, 35%)",

  width: "345px",
  height: "235px",
  objectFit: "contain",
  pointerEvents: "none",
  transition: theme.transitions.create("transform"),
}));

const JoinWaitlistButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.green.main,
  borderRadius: "20px",
  width: "90%",
  padding: "6px 0px",
  position: "absolute",
  top: "95%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "&:hover": {
    backgroundColor: theme.palette.green.main,
  },
}));

const Tags = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  color: "#fff",
});

interface IProp {
  // vehicleData: IBikeData | ICarData;
  subscriptionData: any;
  onButtonClick: (id: string) => void;
  onRenewClick: (id: string) => void;
  onSwitchClick: (id: string) => void;
  onStopClick: (id: string) => void;
  requestVehicleDoc: (type: "insurance" | "rc", id: string) => void;
}

const LargeSubscriptionCard: React.FC<IProp> = ({
  onStopClick,
  onSwitchClick,
  subscriptionData,
  onButtonClick,
  requestVehicleDoc,
  onRenewClick,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const navigate = useNavigate();

  // const handleNavigation = () => {
  //   navigate(`${routeSlug(vehicleData.Model)}`);
  // };
  //

  const ButtonContainer =
    getDays(
      new Date().getTime(),
      new Date(
        Number(subscriptionData.Vehicle_Due_Date) * 1000
      ).getTime()
    ) > 0
      ? styled(Box)(({ theme }) => ({
          backgroundColor: theme.palette.black.light,
          width: "100%",
          height: "220px",
          position: "absolute",
          top: "93%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px 10px 20px 20px",
          padding: "28px 0px",
        }))
      : styled(Box)(({ theme }) => ({
          backgroundColor: theme.palette.black.light,
          width: "100%",
          height: "fit-content",
          position: "absolute",
          top: "93%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px 10px 20px 20px",
          padding: "28px 0px",
        }));

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography
        sx={{
          fontSize: "16px",
        }}
        variant="h6"
      >
        {`${subscriptionData.Make} ${subscriptionData.Vehicle_Name}`}
      </Typography>
      {getDays(
        new Date().getTime(),
        new Date(
          Number(subscriptionData.Vehicle_Due_Date) * 1000
        ).getTime()
      ) > 0 ? (
        <Typography
          sx={{
            fontSize: "14px",
            color: (theme) => theme.palette.black.main,
          }}
          variant="h2"
        >
          {getDays(
            new Date().getTime(),
            new Date(
              Number(subscriptionData.Vehicle_Due_Date) * 1000
            ).getTime()
          )}{" "}
          days left
        </Typography>
      ) : (
        <></>
      )}
      <Box p="5px 0px">
        {getDays(
          new Date().getTime(),
          new Date(
            Number(subscriptionData.Vehicle_Due_Date) * 1000
          ).getTime()
        ) > 0 ? (
          <img src="/images/active_icon.png" alt="available icon" />
        ) : (
          <img src="/images/expired_icon.png" alt="deactivate icon" />
        )}
      </Box>
      <CarImage
        type={subscriptionData?.Vehicle_Type}
        src={`${
          process.env.REACT_APP_IMG_URL
        }/vehicles/${subscriptionData?.Vehicle_Type.toLowerCase()}s/${subscriptionData?.Make.toLowerCase()}/${subscriptionData?.Vehicle_Name?.replaceAll(
          " ",
          "+"
        ).toLowerCase()}/${subscriptionData?.Vehicle_Name?.replaceAll(
          " ",
          "_"
        ).toLowerCase()}.webp`}
        alt={subscriptionData?.Model}
      />
      <ButtonContainer>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}  
        divider={<Divider orientation="vertical" flexItem />}>
          <>
            <Tags variant="body1">
              Start Date:{" "}
              {new Date(
                Number(subscriptionData.Vehicle_Delivery_Date) * 1000
              ).toLocaleDateString()}
            </Tags>
      
            <Tags variant="body1">
              End Date:{" "}
              {new Date(
                Number(subscriptionData.Vehicle_Due_Date) * 1000
              ).toLocaleDateString()}
            </Tags>
          </>
        </Stack>
        {getDays(
          new Date().getTime(),
          new Date(
            Number(subscriptionData.Vehicle_Due_Date) * 1000
          ).getTime()
        ) < 0 ? (
          <></>
        ) : (
          <></>
        )}{" "}
      </ButtonContainer>{" "}
      {getDays(
        new Date().getTime(),
        new Date(
          Number(subscriptionData.Vehicle_Due_Date) * 1000
        ).getTime()
      ) < 0 ? (
        <></>
      ) : (
        <>
          {" "}
          <JoinWaitlistButton
            onClick={() => onButtonClick(subscriptionData._id)}
            variant="contained"
            disableElevation
          >
            VIEW DETAILS
          </JoinWaitlistButton>
          <Box
            position="absolute"
            sx={{
              top: "111%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Stack direction="row" justifyContent="space-between" gap={2}>
              <ButtonVariant2
                sx={{
                  borderColor:
                    getDays(
                      new Date().getTime(),
                      new Date(
                        Number(subscriptionData.Vehicle_Due_Date) * 1000
                      ).getTime()
                    ) < 10
                      ? (theme) => theme.palette.green.main
                      : (theme) => theme.palette.gray.main,
                  color:
                    getDays(
                      new Date().getTime(),
                      new Date(
                        Number(subscriptionData.Vehicle_Due_Date) * 1000
                      ).getTime()
                    ) < 10
                      ? (theme) => theme.palette.green.main
                      : (theme) => theme.palette.gray.main,
                  padding: "5px 10px",
                }}
                onClick={() =>
                  getDays(
                    new Date().getTime(),
                    new Date(
                      Number(subscriptionData.Vehicle_Due_Date) * 1000
                    ).getTime()
                  ) < 10
                    ? onSwitchClick(subscriptionData._id)
                    : null
                }
              >
                SWITCH
              </ButtonVariant2>
              <ButtonVariant1
                sx={{
                  bgcolor:
                    getDays(
                      new Date().getTime(),
                      new Date(
                        Number(subscriptionData.Vehicle_Due_Date) * 1000
                      ).getTime()
                    ) < 10
                      ? (theme) => theme.palette.green.main
                      : (theme) => theme.palette.gray.main,
                  padding: "5px 10px",
                }}
                onClick={() =>
                  getDays(
                    new Date().getTime(),
                    new Date(
                      Number(subscriptionData.Vehicle_Due_Date) * 1000
                    ).getTime()
                  ) < 10
                    ? onStopClick(subscriptionData._id)
                    : null
                }
              >
                RENEW
              </ButtonVariant1>
            </Stack>

            <Stack justifyContent="center" direction="row">
              <Typography
                variant="body1"
                sx={{
                  color:
                    getDays(
                      new Date().getTime(),
                      new Date(
                        Number(subscriptionData.Vehicle_Due_Date)
                      ).getTime()
                    ) < 10
                      ? (theme) => theme.palette.gray.light
                      : (theme) => theme.palette.gray.main,
                  fontWeight: 500,
                  fontSize: "12px",
                  pt: "10px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  getDays(
                    new Date().getTime(),
                    new Date(
                      Number(subscriptionData.Vehicle_Due_Date) * 1000
                    ).getTime()
                  ) < 10
                    ? onRenewClick(subscriptionData._id)
                    : null
                }
              >
                Stop Subscription{" "}
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </Container>
  );
};

export default LargeSubscriptionCard;
