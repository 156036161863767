import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  styled,
  Stack,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  addToWaitlist,
  selectJoinWaitlistSlice,
  updateFormData,
  updatePaymentStatusAndResponse,
  updateSelectedCity,
  updateSelectedInterestedVehicle,
} from "../../redux/joinWaitlist/joinWaitlistSlice";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import {
  getBikeData,
  getCarData,
  selectVehicleSlice,
} from "../../redux/vehicle/vehicleSlice";
import React, { useEffect, useState } from "react";
import { IJoinWaitlistPayload } from "../../types/interfaces";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";
import { isFormDataValid, validateForm } from "./validateForm";
import { options } from "../../utils/razorpay";
import store from "../../redux/store";
import axios from "axios";
import { selectAuthSlice } from "../../redux/auth/authSlice";
import { selectAppSlice } from "../../redux/app/appSlice";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root:before": {
    borderColor: "#5b5b5b",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#fff",
  },
  "& label.Mui-disabled": {
    WebkitTextFillColor: "#fff",
  },
  "& label.Mui-focused": {
    color: "#aaa",
  },
  "& .MuiInputBase-root:after": {
    borderColor: theme.palette.green.main,
  },
  "& .MuiInputBase-input": {
    color: "#fff",
    fontWeight: 500,
    fontSize: "20px",
  },
  "& .MuiInputLabel-root": {
    fontWeight: 300,
    fontSize: "16px",
    color: "#aaa",
  },

  "&.MuiTextField-root": {
    width: "90%",
  },

  [theme.breakpoints.down("md")]: {
    "&.MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },

    "& .MuiInputLabel-root": {
      fontSize: "12px",
    },
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#373737",
  color: "#fff",
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiAutocomplete-popupIndicator": {
    color: theme.palette.white.light,
  },

  "& .MuiAutocomplete-clearIndicator": {
    color: theme.palette.white.light,
  },
}));

const JoinWaitlistForm = () => {
  const { cities, formData, errors, selectedInterestedVehicle, selectedCity } =
    useSelector(selectJoinWaitlistSlice);

  const { carData, bikeData, selectedVehicle } =
    useSelector(selectVehicleSlice);

  const { loggedInUser, isLoggedIn } = useSelector(selectAuthSlice);
  const { buttonText } = useSelector(selectAppSlice);

  const [vehicles, setVehicles] = useState<any>([]);

  const jobProfession = [
    "Company Employee",
    "Government",
    "Entrepreneur",
    "Freelancer",
    "Delivery",
    "Others",
  ];

  const [open, setOpen] = React.useState(false);
  const loading = open && vehicles?.length === 0;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "phone") {
      if (value.length > 10) {
        return;
      }
    }

    store.dispatch(updateFormData({ name, value }));
  };

  useEffect(() => {
    if (selectedVehicle) {
      store.dispatch(
        updateFormData({
          name: "interestedVehicle",
          value: selectedVehicle,
        })
      );
      store.dispatch(updateSelectedInterestedVehicle(selectedVehicle));
    }
  }, [selectedVehicle]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    store.dispatch(getCarData());
    store.dispatch(getBikeData());
    if (active && bikeData.length > 0 && carData.length > 0) {
      setVehicles([...bikeData, ...carData]);
    }

    return () => {
      active = false;
    };
  }, [loading, carData, bikeData]);

  useEffect(() => {
    store.dispatch(
      updateFormData({ name: "fullName", value: loggedInUser.name })
    );
    store.dispatch(
      updateFormData({ name: "email", value: loggedInUser.email })
    );
    store.dispatch(
      updateFormData({ name: "phone", value: loggedInUser.phone.slice(3) })
    );
  }, [loggedInUser]);

  useEffect(() => {
    if (isLoggedIn) {
      store.dispatch(updateSelectedCity(cities[0]));
      store.dispatch(
        updateFormData({
          name: "city",
          value: cities[0]?.city + ", " + cities[0]?.state,
        })
      );
    }
  }, [isLoggedIn, cities]);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      appkey: process.env.REACT_APP_API_KEY,
      secretkey: process.env.REACT_APP_SECRET_KEY,
      Authorization: loggedInUser.accessToken ? loggedInUser.accessToken : "",
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    validateForm(formData);

    if (isFormDataValid(formData)) {
      const { data }: any = await instance.post("/payments/razor/create", {
        uuid: loggedInUser.userid,
        vehicle: `${(formData.interestedVehicle?.Make).trim()} ${(formData.interestedVehicle?.Model).trim()}`,
        city: formData.city,
        name: `${formData.fullName}`,
        phone: formData.phone,
        email: formData.email,
        amount:
          formData.interestedVehicle?.Vehicle_Type === "Bike" ? 4900 : 99900,
      });

      let _options = {
        ...options,
        order_id: data.id,
        amount: `
        ${
          formData.interestedVehicle?.Vehicle_Type === "Bike" ? "4900" : "99900"
        },
        
        `,
        prefill: {
          name: `${formData.fullName}`,
          email: `${formData.email}`,
          contact: `${formData.phone}`,
          profession: `${formData.jobProfession}`,
        },
        notes: {
          address: `${formData.city}`,
        },
        handler: async function (response: any) {
          let joinWaitlistPayload: IJoinWaitlistPayload = {
            userid: loggedInUser.userid
              ? loggedInUser.userid
              : response.razorpay_payment_id,
            vehicle_type: formData.interestedVehicle.Vehicle_Type,
            vehicle_name: `${(formData.interestedVehicle?.Model).trim()}`,
            name: formData?.fullName,
            vehicle_duration: 1,
            city: "Bengaluru",
            make: (formData.interestedVehicle?.Make).trim(),
            email: formData.email,
            profession: formData.jobProfession,
            color: "undefined",
            dod: "2023-01-16",
            timeSlot: "3",
            address: formData.city,
            subscribe_period: "Within 1 month",
            other_profession: "",
            phone: formData.phone,
            payment_amount:
              formData.interestedVehicle.Vehicle_Type === "Bike"
                ? "4900"
                : "99900",
            payment_id: response.razorpay_payment_id,
            payment_date: `${new Date().getFullYear()}-${
              new Date().getMonth() + 1
            }-${new Date().getDate()}`,
            requested_for:
              formData.interestedVehicle.Total_Available > 0
                ? "Book Now"
                : "Join Waitlist",
          };
          store.dispatch(addToWaitlist(joinWaitlistPayload));

          store.dispatch(
            updatePaymentStatusAndResponse({
              status: "success",
              response: response.razorpay_payment_id,
            })
          );
        },
      };

      const razor = new window.Razorpay(_options);
      razor.on("payment.failed", function (response: any) {
        store.dispatch(
          updatePaymentStatusAndResponse({
            status: "failure",
            response: `${response.error.reason} - ${response.error.description} `,
          })
        );
      });

      razor.open();
    }
  };

  return (
    <Grid container mt="60px" rowGap="25px">
      <Grid item xs={12} md={6}>
        <StyledTextField
          required
          size="small"
          label="FULL NAME"
          variant="standard"
          name="fullName"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData.fullName}
          disabled={isLoggedIn ? true : false}
          error={errors.fullName ? Boolean(errors.fullName) : false}
          helperText={errors.fullName}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}> */}
      {/*   <StyledTextField */}
      {/*     required */}
      {/*     autoComplete="new-password" */}
      {/*     size="small" */}
      {/*     label="LAST NAME" */}
      {/*     variant="standard" */}
      {/*     name="lastName" */}
      {/*     onChange={handleChange} */}
      {/*     value={formData.lastName} */}
      {/*     error={errors.lastName ? Boolean(errors.lastName) : false} */}
      {/*     helperText={errors.lastName} */}
      {/*   /> */}
      {/* </Grid> */}

      <Grid item xs={12} md={6}>
        <StyledTextField
          required
          autoCorrect="new-password"
          size="small"
          label="EMAIL"
          variant="standard"
          name="email"
          type="email"
          onChange={handleChange}
          value={formData.email}
          disabled={isLoggedIn ? true : false}
          error={errors.email ? Boolean(errors.email) : false}
          helperText={errors.email}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <StyledTextField
          sx={{
            "& .MuiTypography-root": {
              color: "#fff",
              fontSize: "20px",
              fontWeight: 500,
            },
          }}
          required
          autoComplete="new-password"
          size="small"
          label="PHONE"
          type="number"
          variant="standard"
          name="phone"
          disabled={isLoggedIn ? true : false}
          onChange={handleChange}
          value={formData.phone}
          error={errors.phone ? Boolean(errors.phone) : false}
          helperText={errors.phone}
          InputProps={{
            startAdornment: (
              <InputAdornment disablePointerEvents position="start">
                +91
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <StyledAutocomplete
          id="interested vehicle"
          options={vehicles}
          loading={loading}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={
            selectedInterestedVehicle?.Model ? selectedInterestedVehicle : null
          }
          onChange={(event: any, newValue: any) => {
            store.dispatch(updateSelectedInterestedVehicle(newValue));

            store.dispatch(
              updateFormData({
                name: "interestedVehicle",
                value: newValue,
              })
            );
          }}
          PaperComponent={StyledPaper}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              required
              error={errors.interestedVehicle ? true : false}
              helperText={errors.interestedVehicle}
              size="small"
              label="INTERESTED VEHICLE"
              variant="standard"
              name="interestedVehicle"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress sx={{ color: "#fff" }} size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option: any, { inputValue }) => {
            const matches = match(
              `${option.Make} ${option.Model}`,
              inputValue,
              {
                insideWords: true,
              }
            );
            const parts = parse(`${option.Make} ${option.Model}`, matches);

            return (
              <li {...props} key={option.Model}>
                <div>
                  {parts.map((part: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        color: part.highlight ? "#24FF69" : "#C5C5C5",
                        fontWeight: 300,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
          getOptionLabel={(options: any) => options.Make + " " + options.Model}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <StyledAutocomplete
          id="cities data"
          autoComplete={false}
          options={cities}
          PaperComponent={StyledPaper}
          disabled={isLoggedIn ? true : false}
          value={selectedCity?.city ? selectedCity : null}
          onChange={(event: any, newValue: any) => {
            store.dispatch(updateSelectedCity(newValue));
            store.dispatch(
              updateFormData({
                name: "city",
                value: newValue?.city + ", " + newValue?.state,
              })
            );
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="CITY"
              required
              error={errors.city ? true : false}
              helperText={errors.city}
              size="small"
              variant="standard"
              name="city"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
          renderOption={(props, option: any, { inputValue }) => {
            const matches = match(
              `${option.city}, ${option.state}`,
              inputValue,
              {
                insideWords: false,
              }
            );
            const parts = parse(`${option.city}, ${option.state}`, matches);

            return (
              <li {...props} key={option._id.$oid}>
                <div>
                  {parts.map((part: any, index: any) => {
                    return (
                      <span
                        key={index}
                        style={{
                          color: part.highlight ? "#24FF69" : "#C5C5C5",
                          fontWeight: part.highlight ? 400 : 300,
                        }}
                      >
                        {part.text ? part.text : "loading"}
                      </span>
                    );
                  })}
                </div>
              </li>
            );
          }}
          getOptionLabel={(options: any) => options.city + ", " + options.state}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <StyledAutocomplete
          id="job profession"
          options={jobProfession}
          PaperComponent={StyledPaper}
          value={formData.jobProfession}
          onChange={(event: any, newValue: any) => {
            store.dispatch(
              updateFormData({
                name: "jobProfession",
                value: newValue,
              })
            );
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="JOB PROFESSION"
              required
              error={
                errors.jobProfession ? Boolean(errors.jobProfession) : false
              }
              helperText={errors.jobProfession}
              size="small"
              variant="standard"
              name="city"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
          renderOption={(props, option: any, { inputValue }) => {
            const matches = match(option, inputValue, {
              insideWords: true,
            });
            const parts = parse(option, matches);

            return (
              <li {...props} key={option}>
                <div>
                  {parts.map((part: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        color: part.highlight ? "#24FF69" : "#C5C5C5",
                        fontWeight: 300,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
          getOptionLabel={(options: any) => options}
        />
      </Grid>

      <Stack justifyContent="center" width="100%">
        <>
          <ButtonVariant1
            sx={(theme) => ({
              color: theme.palette.black.main,
              fontSize: { md: "18px", lg: "24px" },
              borderRadius: "80px",
              width: { sm: "100%", md: "50%" },
              padding: { sm: "8px", md: "14px 35px" },
              margin: "0 auto",
            })}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {!formData.interestedVehicle ? (
              !!buttonText ? (
                `${buttonText}`
              ) : (
                <Skeleton
                  variant="text"
                  sx={{
                    width: "inherit",
                    fontSize: "20px",
                    "&.MuiSkeleton-root": {
                      backgroundColor: "#17a642 !important",
                    },
                  }}
                />
              )
            ) : formData.interestedVehicle.Vehicle_Type === "Bike" ? (
              formData.interestedVehicle.Total_Available > 0 ? (
                "BOOK NOW @49"
              ) : (
                "JOIN WAITLIST @49"
              )
            ) : formData.interestedVehicle.Vehicle_Type === "Car" ? (
              formData.interestedVehicle.Total_Available > 0 ? (
                "BOOK NOW @999"
              ) : (
                "JOIN WAITLIST @999"
              )
            ) : (
              "JOIN WAITLIST"
            )}
          </ButtonVariant1>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.green.light,
              fontStyle: "italic",
              textAlign: "center",
              p: { xs: "10px 0px", md: "10px 20px 0px 0px" },
              fontSize: { xs: "14px", md: "18px" },
            }}
          >
            The amount is fully refundable at any time.
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.green.light,
              fontStyle: "italic",
              textAlign: "center",
              p: { xs: "10px 0px", md: "10px 20px 0px 0px" },
              fontSize: { xs: "12.5px", md: "16px" },
            }}
          >
            Promotion: If you end up taking a vehicle from us &#58;&#41; use
            MobiKwik wallet payment option and get 750 rupees cashback!
          </Typography>
        </>
      </Stack>
    </Grid>
  );
};

export default JoinWaitlistForm;
