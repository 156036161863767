import { Box, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";

const LeftPaper = styled(Box)(({ theme }) => ({
  padding: "115px 50px 90px 50px",
  background: "#101010",
  border: "2px solid #24ff69",
  borderRadius: "15px",
  flexBasis: "47%",
  [theme.breakpoints.down(1250)]: {
    padding: "115px 10px 90px 10px",
  },

  [theme.breakpoints.down(900)]: {
    padding: "50px 0px",
  },

  [theme.breakpoints.down("md")]: {
    background: "#1e1e1e",
  },
}));

const RightPaper = styled(Box)(({ theme }) => ({
  padding: "63px 85px 93px 86px",
  background: "#101010",
  borderRadius: "15px",
  flexBasis: "47%",
  [theme.breakpoints.down(1250)]: {
    padding: "63px 10px 93px 10px",
  },

  [theme.breakpoints.down(900)]: {
    padding: "50px 0px",
  },

  [theme.breakpoints.down("md")]: {
    background: "#1e1e1e",
    padding: "0px",
  },
}));

const PaperContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  rowGap: "60px",
  justifyContent: "space-between",
  marginTop: "55px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const AboutUs = () => {
  const windowWidth = useWindowWidth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | About Us | EV Subscription Model </title>
        <meta name="description" content="Why be an owner when you can be an enjoyer. Enjoy your ride without owning the Vehicle.Subscribe to SWYTCHD EV Plans. " />
        <meta name="keywords" content="EV Subscription, Book Electric Vehicle, EV Plans, Car rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Box
        sx={{
          backgroundImage: { xs: "", md: "url(/images/aboutus-bg.svg)" },
          backgroundRepeat: "no-repeat",
          backgroundColor: "#1e1e1e",
          backgroundSize: "cover",
        }}
      >
        <Stack
          sx={{
            p: { xs: "26px 22px", lg: "26px 80px" },
          }}
          direction="row"
          justifyContent="space-between"
        >
          <SwytchdLogo color="var(--color-variant-white)" />
          <Box>
            {windowWidth > 900 ? (
              <Header color="var(--color-variant-white)" />
            ) : (
              <HamburgerDrawer variant="white" />
            )}
          </Box>
        </Stack>

        <Box
          sx={{
            p: { xs: "26px 22px", lg: "26px 80px" },
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: "20px", md: "24px" }, color: "#fff" }}
          >
            ABOUT US
          </Typography>

          <PaperContainer>
            <LeftPaper>
              <Box
                sx={{
                  width: "80%",
                  margin: "0 auto",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#24ff69",
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  We want to change the way personal mobility is done today.
                </Typography>
                <br />
                <br />
                <Typography
                  variant="h5"
                  sx={{
                    color: "#24ff69",
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  To enable the transition:
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#24ff69",
                    fontSize: { xs: "18px", md: "24px" },
                    "& li": { listStyleType: "initial" },
                  }}
                >
                  <li>From petrol to EVs</li>
                  <li>From being an owner to being an enjoyer</li>
                  <li>Enjoy the ride without owning the vehicle</li>
                </Typography>
              </Box>
            </LeftPaper>

            <RightPaper>
              <Box
                sx={{
                  width: { xs: "95%", md: "85%" },
                  margin: "0 auto",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#24ff69",
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  Why the SWYTCH?
                </Typography>
                <br />
                <br />
                <Typography
                  variant="h3"
                  sx={{
                    color: "#ededed",
                    fontSize: { xs: "18px", md: "24px" },
                  }}
                >
                  People are thinking about what they drive, how they drive, and
                  how they pay for it more than ever before. We want to
                  accelerate the adoption of electric vehicles across the world
                  without the hassles of traditional ownership and maintenance.
                  We are here to help make the SWYTCH and enable you to drive
                  your way into sustainable, flexible, personal, and affordable
                  transportation.
                </Typography>
              </Box>
            </RightPaper>
          </PaperContainer>

          <Box
            sx={{
              mt: { xs: "3rem", lg: "75px" },
              mb: "58px",
            }}
          >
            <Typography variant="h2" sx={{ fontSize: "24px", color: "#fff" }}>
              FOUNDER
            </Typography>
            <Stack
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "6rem",
                rowGap: "2rem",
                background: { xs: "#1e1e1e", lg: "#101010" },
                mt: "55px",
                p: { xs: "20px 10px", lg: "50px 100px" },
                overflow: "hidden",
              }}
            >
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: { sm: "400px", md: "310px" },
                    height: "200px",
                    objectFit: "cover",
                  }}
                  src="/images/founder-img.jpeg"
                  alt="Sameer"
                />
              </Box>
              <Typography
                variant="h3"
                sx={{ color: "#ededed", fontSize: { xs: "18px", md: "24px" } }}
              >
                After realising that there are still significant barriers to
                entry for EV's, Sameer started SWYTCHD. He worked at Jaguar Land
                Rover for a decade in multiple roles in the UK, most recently as
                Global Head of Pricing Strategy.
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default AboutUs;
