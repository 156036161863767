import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { IBikeData, ICarData } from "../../types/interfaces";

interface IProp {
  selectedVehicles: any;
}

interface IVehicleIntroCard {
  image: string;
  price: string;
  Make: string;
  Model: string;
  Vehicle_Type: string;
}

const VehicleIntroCard: React.FC<IVehicleIntroCard> = ({
  image,
  Make,
  Model,
  Vehicle_Type,
  price,
}) => {
  return (
    <Stack
      height="200px"
      direction="column"
      alignItems="center"
      textAlign="center"
      maxWidth="130px"
    >
      <Box
        component="img"
        src={`${process.env.REACT_APP_IMG_URL}/vehicles/${Vehicle_Type.toLowerCase()}s/${Make.toLowerCase()}/${Model.replaceAll(" ", "+").toLowerCase()}/${Model.replaceAll(" ", "_").toLowerCase()}.webp`}
        alt="my vehicle"
        sx={{
          width: { xs: "100px", md: "180px" },
          height: { xs: "55px", md: "110px" },
        }}
      />
      <Typography sx={{ fontSize: { xs: "14px", md: "18px" }, minHeight: "65px" }} variant="h6">
        {Model}
      </Typography>
      <Typography sx={{ fontSize: { xs: "14px", md: "18px" } }} variant="h5">
        &#8377; {price}
      </Typography>
    </Stack>
  );
};

const TableHeading = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  width: "20rem",
  height: "2.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.black.light,
  [theme.breakpoints.down("md")]: {
    width: "7rem",
    fontSize: "14px",
    height: "4rem",
  },
}));

const TableContent = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.black.light,
  width: 100,
  fontSize: "18px",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    width: 108,
    fontSize: "14px",
  },
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: "130px",
  height: "2.5rem",
  marginLeft: "15px",
  [theme.breakpoints.down("md")]: {
    gap: "30px",
    height: "4rem",
  },
}));

const CompareVehicleTable: React.FC<IProp> = ({ selectedVehicles }) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  return (
    <Stack
      direction="row"
      sx={{
        width: { xs: "98%", lg: "70%" },
      }}
    >
      <Stack
        direction="column"
        sx={{
          backgroundColor: (theme) => theme.palette.white.light,
          mt: { xs: "150px", md: "224.5px" },
          p: "40px 0px",
          pl: { xs: "20px", md: "60px" },
          borderTopLeftRadius: "25px",
          borderBottomLeftRadius: "25px",
        }}
      >
        <TableHeading variant="h6">Range per full charge</TableHeading>
        <Divider />
        <TableHeading variant="h6">Time taken to fully charge</TableHeading>
        <Divider />
        <TableHeading variant="h6">Top speed</TableHeading>
        <Divider />
        {/* <TableHeading variant="h6">Fast charging</TableHeading> */}
        {/* <Divider /> */}
        {/* <TableHeading variant="h6">Removable or fixed</TableHeading> */}
        {/* <Divider /> */}
        {/* <TableHeading variant="h6">Battery count</TableHeading>
        <Divider />
        <TableHeading variant="h6">Boot space</TableHeading>
        <Divider />
        <TableHeading variant="h6">Rapid charging</TableHeading>
        <Divider />
        <TableHeading variant="h6">Number of seats</TableHeading>
        <Divider />
        <TableHeading variant="h6">Music system</TableHeading>
        <Divider />
        <TableHeading variant="h6">Sun roof</TableHeading>
        <Divider /> */}
      </Stack>
      <Box
        sx={{
          overflowX: "scroll",
          marginLeft: "-10px",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
        {...events}
        ref={ref}
      >
        <Stack ml="15px" width="500px" direction="row" sx={{ gap: { xs: "40px", md: "90px" } }}>
          {selectedVehicles.map((vehicle: any, key: any) => {
            return (
              <Fragment key={key}>
                <VehicleIntroCard
                  image={vehicle.image}
                  price={vehicle.CostMonth}
                  Make={vehicle.Make}
                  Model={vehicle.Model}
                  Vehicle_Type={vehicle.Vehicle_Type}
                  key={key}
                />
              </Fragment>
            );
          })}
          <Stack direction="column" alignItems="center">
            <Box
              component="img"
              src="/images/car-shadow.svg"
              alt="my vehicle"
              sx={{
                width: { xs: "100px", md: "180px" },
                height: { xs: "55px", md: "110px" },
              }}
            />
            <Typography
              sx={{ fontSize: { xs: "14px", md: "20px" } }}
              variant="h6"
            >
              SELECT A MODEL
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.white.light,
            margin: "0 auto",
            mt: { xs: "-50px", md: "25px" },
            p: "40px 0px",
            pr: { xs: "20px", md: "60px" },
            borderTopRightRadius: "25px",
            borderBottomRightRadius: "25px",
            minWidth: "fit-content"
          }}
          direction="column"
        >
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle: any, key: any) => {
              return (
                <TableContent key={key} variant="body1">
                  {vehicle?.Range}
                </TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle: any, key: any) => {
              return (
                <TableContent variant="body1">
                  {vehicle?.Charging_Time.split(" ")[0]}hr {vehicle?.Charging_Time.split(" ")[2]}min
                </TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle: any, key: any) => {
              return (
                <TableContent variant="body1">{vehicle?.Top_Speed}</TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          {/* <Divider /> */}
          {/* <ContentContainer direction="row"> */}
          {/*   {selectedVehicles.map((vehicle: any, key: any) => { */}
          {/*     return ( */}
          {/*       <TableContent variant="body1"> */}
          {/*         {vehicle.fastCharging} */}
          {/*       </TableContent> */}
          {/*     ); */}
          {/*   })} */}
          {/*   <TableContent variant="body1">-</TableContent> */}
          {/* </ContentContainer> */}
          <Divider />
          {/* <ContentContainer direction="row"> */}
          {/*   {selectedVehicles.map((vehicle, key) => { */}
          {/*     return ( */}
          {/*       <TableContent variant="body1"> */}
          {/*         {vehicle.removableOrFixed} */}
          {/*       </TableContent> */}
          {/*     ); */}
          {/*   })} */}
          {/*   <TableContent variant="body1">-</TableContent> */}
          {/* </ContentContainer> */}
          <Divider />
          {/* <ContentContainer direction="row">
            {selectedVehicles.map((vehicle, key) => {
              return (
                <TableContent variant="body1">
                  {vehicle.removableOrFixed}
                </TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle, key) => {
              return (
                <TableContent variant="body1">{vehicle.bootSpace}</TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle, key) => {
              return (
                <TableContent variant="body1">
                  {vehicle.rapidCharging}
                </TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle, key) => {
              return (
                <TableContent variant="body1">
                  {vehicle.numberOfSeats}
                </TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle, key) => {
              return (
                <TableContent variant="body1">
                  {vehicle.musicSystem}
                </TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider />
          <ContentContainer direction="row">
            {selectedVehicles.map((vehicle, key) => {
              return (
                <TableContent variant="body1">{vehicle.sunRoof}</TableContent>
              );
            })}
            <TableContent variant="body1">-</TableContent>
          </ContentContainer>
          <Divider /> */}
        </Stack>
      </Box>
    </Stack>
  );
};

export default CompareVehicleTable;
