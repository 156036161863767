import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import useWindowWidth from "../../hooks/useWindowWidth";

interface IProp {
  cost: number;
}

const ChargingCostCard: React.FC<IProp> = ({ cost }) => {
  const windowWidth = useWindowWidth();

  return (
    <Box
      sx={{
        width: "305px",
        fontFamily: "var(--font-family-lexend)",
        "@media (max-width: 1200px)": {
          width: "210px",
        },
        "@media (max-width: 992px)": {
          width: "200px",
        },
        "@media (max-width: 450px)": {
          width: "150px",
        },
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "var(--color-variant-gray)",
          fontWeight: 500,
          fontSize: "var(--font-size-md)",
        }}
        component="p"
      >
        EV
      </Typography>
      <Box
        sx={{
          border: "1px solid var(--color-variant-green-1)",
          borderRadius: "15px",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            padding: "45px 0px",
            "@media (max-width: 769px)": {
              padding: "10px 0px",
            },
          }}
        >
          <Typography
            sx={{
              color: "var(--color-variant-white)",
              "@media (max-width: 769px)": {
                fontSize: "10px",
              },
            }}
            component="p"
          >
            CHARGING COST
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font-family-fraunces)",
              fontWeight: 400,
              letterSpacing: "0.02em",
              color: "var(--color-variant-green-1)",
              fontSize: "48px",
              fontStyle: "italic",
              "@media (max-width: 769px)": {
                fontSize: "24px",
              },
            }}
            component="p"
          >
            &#8377; {cost}
          </Typography>
        </Box>
       
      </Box>
      {windowWidth > 769 && (
        <Typography
          sx={{
            color: "var(--color-variant-white)",
            fontWeight: 300,
            lineHeight: "27px",
            fontFamily: "var(--font-family-lexend)",
            fontSize: "18px",
            width: "300px",
            paddingTop: 2,
            "@media (max-width:769px)": {
              fontSize: "14px",
            },
          }}
          variant="subtitle1"
        >
          <Typography
            sx={{
              color: "var(--color-variant-green-1)",
              fontStyle: "italic",
              fontFamily: "var(--font-family-fraunces)",
              fontSize: "18px",
              marginLeft: 1,
            }}
            variant="caption"
          >
            What a Swytch!
          </Typography>
        </Typography>
      )}
    </Box>
  );
};

export default ChargingCostCard;
