import { createSlice } from "@reduxjs/toolkit";
import { calculateCost } from "../../utils/costEquation";
import { RootState } from "../store";

interface InitialState {
  selectedVehicleType: "CAR" | "BIKE";
  distance: number;
  chargingCost: number;
  fuelCost: number;
}

const initialState: InitialState = {
  selectedVehicleType: "CAR",
  distance: 0,
  chargingCost: 0,
  fuelCost: 0,
};

const fuelCostSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setVehicleType: (state, action) => {
      state.selectedVehicleType = action.payload;

      const { fuelCostCar, fuelCostBike, chargingCostCar, chargingCostBike } =
        calculateCost(state.distance);

      if (state.selectedVehicleType === "CAR") {
        state.fuelCost = fuelCostCar;
        state.chargingCost = chargingCostCar;
      }

      if (state.selectedVehicleType === "BIKE") {
        state.fuelCost = fuelCostBike;
        state.chargingCost = chargingCostBike;
      }
    },

    setDistance: (state, action) => {
      state.distance = action.payload;

      if (state.distance > 50) {
        state.distance = 50;
      }

      const { fuelCostCar, fuelCostBike, chargingCostCar, chargingCostBike } =
        calculateCost(state.distance);

      if (state.selectedVehicleType === "CAR") {
        state.fuelCost = fuelCostCar;
        state.chargingCost = chargingCostCar;
      }

      if (state.selectedVehicleType === "BIKE") {
        state.fuelCost = fuelCostBike;
        state.chargingCost = chargingCostBike;
      }
    },
  },
});

export const selectFuelCostSlice = (state: RootState) => state.fuelCost;

export const { setVehicleType, setDistance } = fuelCostSlice.actions;

export default fuelCostSlice.reducer;
