import React, { useEffect, useState } from "react";
import {
  styled,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { StyledTextField } from "../joinWaitlistPage/JoinWaitlistForm";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import userPool from "../../userPool";
import FailureAlert from "../../components/Alerts/Error";
import store from "../../redux/store";
import { setUserData } from "../../redux/auth/authSlice";
import { Helmet } from "react-helmet";

type Data = {
  name: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.black.light,
  minHeight: "calc(100vh - 90px)",
  padding: "26px 60px",
  "& .MuiInputLabel-root": {
    fontWeight: 300,
    fontSize: "16px",
    color: "#aaa",
  },
  error: {
    "MuiInputLabel-root.Mui-error.Mui-error": {
      color: "#aaa",
    },
  },

  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
}));
const SignUp: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErr, setPasswordErr] = React.useState("");
  const [signUpError, setSignUpError] = React.useState("");
  const [formError, setFormError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isFocused, setIsFocused] = useState(false);

  const navigate = useNavigate();

  const [data, setData] = useState<Data>({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "phone") {
      if (e.target.value.length > 10) {
        return;
      }
    }

    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "name") {
      if (!e.target.value.match(/^[a-zA-Z ]*$/)) {
        setSignUpError("");
        setErrorMessage("Only Alphabets and SPACE charaters allowed");
      } else {
        setErrorMessage("");
      }
    }

    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const signUp = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (passwordErr) return;

    if (
      data.phone.length < 10 ||
      !data.name ||
      !data.email ||
      !data.phone ||
      !data.password ||
      !data.confirmPassword
    ) {
      return setFormError(true);
    }

    if (data.phone.length < 10) {
      return setSignUpError("Please enter valid phone number!");
    }

    if (!data.name.match(/^[a-zA-Z ]*$/)) {
      return setSignUpError("Only Alphabets and SPACE charaters allowed!");
    }

    if (!data.email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi)) {
      return setSignUpError("Please enter valid email id!");
    }

    setIsLoading(true);

    let attributeList: any = [];

    let dataEmail = {
      Name: "email",
      Value: data.email,
    };

    let dataName = {
      Name: "name",
      Value: data.name,
    };

    let dataPhone = {
      Name: "phone_number",
      Value: `+91${data.phone}`,
    };

    const attributeName = new CognitoUserAttribute(dataName);
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributePhone = new CognitoUserAttribute(dataPhone);

    attributeList.push(attributeName);
    attributeList.push(attributeEmail);
    attributeList.push(attributePhone);

    //@ts-ignore
    userPool.signUp(
      `+91${data.phone}`,
      data.password,
      attributeList,
      [],
      function (err, _data) {
        if (err) {
          setIsLoading(false);
          return setSignUpError(err.message);
        }

        if (_data) {
          store.dispatch(
            setUserData({
              userid: _data.userSub,
              email: data.email,
              phone: data.phone,
              name: data.name,
            })
          );

          setIsLoading(false);

          navigate("/auth/verify-otp");
        }
      }
    );
  };

  useEffect(() => {
    if (data.password && data.confirmPassword) {
      if (data.password !== data.confirmPassword) {
        setPasswordErr("Doesn't match with the password.");
      } else {
        setPasswordErr("");
      }
    } else {
      setPasswordErr("");
    }
  }, [data.password, data.confirmPassword]);

  return (
    <>
      <Helmet>
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Container>
        {signUpError && (
          <FailureAlert
            errorMsg={signUpError}
            errorTitle={"Error"}
            closeErrorPopup={() => setSignUpError("")}
          />
        )}
        <Typography
          color="#fff"
          variant="h6"
          textAlign="center"
          sx={{ fontSize: { sm: "20px", md: "24px" } }}
        >
          SIGN UP
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            pt: "1em",
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", sm: "350px" },
              position: "relative",
              alignItems: "center",
            }}
            direction="column"
            rowGap={4}
          >
            {isLoading && (
              <CircularProgress
                sx={{
                  "&.MuiCircularProgress-colorPrimary": {
                    color: (theme) => theme.palette.green.light,
                  },
                  "&.MuiCircularProgress-root": {
                    width: "60px !important",
                    height: "60px !important",
                    position: "absolute",
                    top: "30%",
                    left: "40%",
                  },
                }}
              />
            )}

            <StyledTextField
              sx={{
                ".MuiInputLabel-root": {
                  color: "#aaa",
                },
                ".MuiFormHelperText-root": {
                  color: "#d32f2f",
                },
              }}
              required
              size="small"
              label="FULL NAME"
              variant="standard"
              name="name"
              type="text"
              autoComplete="new-password"
              onChange={handleUsername}
              value={data.name}
              error={formError && !data.name}
              helperText={
                formError && !data.name
                  ? "Please enter name!"
                  : errorMessage
                  ? errorMessage
                  : ""
              }
            />
            <StyledTextField
              sx={{
                "& .MuiTypography-root": {
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 500,
                },
              }}
              required
              size="small"
              label="PHONE"
              type="tel"
              variant="standard"
              name="phone"
              autoComplete="new-password"
              onChange={handleChange}
              value={data.phone}
              error={formError && !data.phone}
              helperText={
                formError && !data.phone
                  ? "Please enter valid phone number"
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment disablePointerEvents position="start">
                    +91
                  </InputAdornment>
                ),
              }}
            />

            <StyledTextField
              required
              size="small"
              label="EMAIL"
              variant="standard"
              name="email"
              autoComplete="new-password"
              type="email"
              onChange={handleChange}
              value={data.email}
              error={formError && !data.email}
              helperText={
                formError && !data.email ? "Please enter valid email id" : ""
              }
            />

            <StyledTextField
              required
              size="small"
              label="PASSWORD"
              variant="standard"
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={data.password}
              error={formError && !data.password}
              helperText={
                formError && !data.password ? "Please enter password" : ""
              }
              // error={errors.firstName ? Boolean(errors.firstName) : false}
              // helperText={errors.firstName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "#fff" }} />
                      ) : (
                        <Visibility sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isFocused && (
              <Typography
                variant="body1"
                sx={{
                  mt: "-2.75em",
                  mb: "-2.75em",
                  ml: "30px",
                  fontSize: "11px",
                  color: "#c1c1c1",
                  fontStyle: "italic",
                  width: "98%",
                  textAlign: "left",
                }}
              >
                Valid password should be 6 characters with 1 uppercase, 1
                lowercase, 1 number, and a special character
              </Typography>
            )}
            <StyledTextField
              sx={{
                ".MuiInputLabel-root": {
                  color: "#aaa",
                },
                ".MuiFormHelperText-root": {
                  color: "#d32f2f",
                },
              }}
              required
              size="small"
              label="CONFIRM PASSWORD"
              variant="standard"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={data.confirmPassword}
              error={passwordErr ? Boolean(passwordErr) : false}
              // helperText={passwordErr}
              helperText={
                formError && !data.confirmPassword
                  ? "Please re-enter password"
                  : passwordErr
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "#fff" }} />
                      ) : (
                        <Visibility sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isFocused && (
              <Typography
                variant="body1"
                sx={{
                  mt: "-2.75em",
                  mb: "-2.75em",
                  ml: "30px",
                  fontSize: "11px",
                  color: "#c1c1c1",
                  fontStyle: "italic",
                  width: "98%",
                  textAlign: "left",
                }}
              >
                Valid password should be 6 characters with 1 uppercase, 1
                lowercase, 1 number, and a special character
              </Typography>
            )}
            <ButtonVariant1
              sx={(theme) => ({
                color: theme.palette.black.main,
                fontSize: { md: "18px", lg: "24px" },
                borderRadius: "80px",
                width: { sm: "100%", md: "90%" },
                padding: { sm: "8px", md: "14px 35px" },
              })}
              onClick={(e) => signUp(e)}
            >
              SIGN UP
            </ButtonVariant1>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default SignUp;
