import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  styled,
  Box,
  Typography,
  Stack,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { ButtonVariant1 } from "../../components/Buttons/Buttons";
import { StyledTextField } from "../joinWaitlistPage/JoinWaitlistForm";
import { setNewPassword } from "../../redux/auth/authSlice";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";
import FailureAlert from "../../components/Alerts/Error";

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.black.light,
  minHeight: "calc(100vh - 70px)",
  padding: "26px 60px",

  [theme.breakpoints.down("lg")]: {
    padding: "26px 30px",
  },
}));
const ChangePassword = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [password, setPassword] = useState("");
  const [newConfirmPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [err, setErr] = useState("");
  // const { newPassword } = useSelector(selectAuthSlice);

  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirm = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(e.target.value);
  };

  const confirmChangePassword = () => {
    if (!password) return;

    if (password !== newConfirmPassword) {
      setErr("Password & Confirm password doesn't match");
      return;
    }

    store.dispatch(setNewPassword(password));
    navigate("/auth/recover-password/verify-otp");
    // setIsLoading(true);

    // const _user = {
    //   Username: `+91${forgotPasswordPhone}`,
    //   Pool: userPool,
    // };

    // const cognitoUser = new CognitoUser(_user);

    // cognitoUser.confirmPassword(forgotPasswordOTP, newPassword, {
    //   onSuccess() {
    //     toast.success("Password changed successfully!");
    //     setIsLoading(false);
    //     navigate("/auth/signin");
    //   },

    //   onFailure(err) {
    //     setIsLoading(false);
    //     if (err.name === "UserNotFoundException") {
    //       customChangePassword();
    //     } else {
    //       setErr(err.message);
    //     }
    //   },
    // });
  };

  // const customChangePassword = () => {
  //   if (!newPassword) return;

  // setIsLoading(true);

  //   const _user = {
  //     Username: forgotPasswordPhone,
  //     Pool: userPool,
  //   };

  //   const cognitoUser = new CognitoUser(_user);

  //   cognitoUser.confirmPassword(forgotPasswordOTP, newPassword, {
  //     onSuccess() {
  //       toast.success("Password changed sucessfully!");
  //       setIsLoading(false);
  //       navigate("/auth/signin");
  //     },

  //     onFailure(err) {
  //       setIsLoading(false);
  //       setErr(err.message);
  //     },
  //   });
  // };

  return (
    <Container>
      {err && (
        <FailureAlert
          errorMsg={err}
          errorTitle={"Error"}
          closeErrorPopup={() => setErr("")}
        />
      )}
      <Typography
        color="#fff"
        variant="h6"
        textAlign="center"
        sx={{ fontSize: { sm: "20px", md: "24px" } }}
      >
        CONFIRM NEW PASSWORD
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          pt: "4em",
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", sm: "350px" },
            position: "relative",
          }}
          direction="column"
          rowGap={5}
        >
          {isLoading && (
            <CircularProgress
              sx={{
                "&.MuiCircularProgress-colorPrimary": {
                  color: (theme) => theme.palette.green.light,
                },
                "&.MuiCircularProgress-root": {
                  width: "60px !important",
                  height: "60px !important",
                  position: "absolute",
                  top: "30%",
                  left: "40%",
                },
              }}
            />
          )}

          <StyledTextField
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "20px",
                fontWeight: 500,
              },
            }}
            required
            size="small"
            label="ENTER NEW PASSWORD"
            type={showPassword ? "text" : "password"}
            variant="standard"
            name="password"
            autoComplete="new-password"
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "#fff" }} />
                    ) : (
                      <Visibility sx={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isFocused && (
            <Typography
              variant="body1"
              sx={{
                mt: "-2.75em",
                mb: "-2.75em",
                ml: "5px",
                fontSize: "11px",
                color: "#c1c1c1",
                fontStyle: "italic",
                width: "98%",
                textAlign: "left",
              }}
            >
              Valid password should be 6 characters with 1 uppercase, 1
              lowercase, 1 number, and a special character
            </Typography>
          )}
          <StyledTextField
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "20px",
                fontWeight: 500,
              },
            }}
            required
            size="small"
            label="ENTER CONFIRM PASSWORD"
            type={showConfirmPassword ? "text" : "password"}
            variant="standard"
            name="confirmPassword"
            autoComplete="confirm-password"
            onChange={handleConfirm}
            value={newConfirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setConfirmShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityOff sx={{ color: "#fff" }} />
                    ) : (
                      <Visibility sx={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <ButtonVariant1
            sx={(theme) => ({
              color: theme.palette.black.main,
              fontSize: { md: "18px", lg: "24px" },
              borderRadius: "80px",
              width: { sm: "100%", md: "90%" },
              padding: { sm: "8px", md: "14px 35px" },
            })}
            onClick={() => confirmChangePassword()}
          >
            CONFIRM
          </ButtonVariant1>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ChangePassword;
