import { Snackbar, Alert, IconButton, AlertTitle } from "@mui/material";
import React from "react";

interface IFailureAlert {
  errorMsg: string;
  errorTitle: string;
  closeErrorPopup: () => void;
}

const FailureAlert: React.FC<IFailureAlert> = ({ errorMsg, errorTitle, closeErrorPopup }) => {

  return (
    <Snackbar
      open={errorMsg ? true : false}
      onClose={() => closeErrorPopup()}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        sx={(theme) => ({
          fontFamily: "var(--font-family-lexend)",
          borderRadius: "12px",
          fontSize: { xs: "12px", md: "15px" },
        })}
        variant="filled"
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              closeErrorPopup()
            }}
          >
            <img src="/images/close.svg" alt="close" />
          </IconButton>
        }
      >
        <AlertTitle sx={{ fontSize: { xs: "14px", md: "18px" } }}>{errorTitle}</AlertTitle>
        {errorMsg}
      </Alert>
    </Snackbar>
  );
};


export default FailureAlert; 
