import { INavLink } from "../types/interfaces";

export const navLinks: INavLink[] = [
  {
    name: "HOME",
    to: "",
  },
  {
    name: "BIKES",
    to: "electric-bikes-subscription",
  },
  {
    name: "CARS",
    to: "electric-cars-subscription",
  },
  {
    name: "COMPARE",
    to: "compare",
  },
  // {
  //   name: "BLOG",
  //   to: "blog",
  // },
];

export const navLinksMobile: INavLink[] = [
  {
    name: "HOME",
    to: "",
  },
  {
    name: "BIKES",
    to: "electric-bikes-subscription",
  },
  {
    name: "CARS",
    to: "electric-cars-subscription",
  },
  {
    name: "COMPARE",
    to: "compare",
  },
  // {
  //   name: "BLOG",
  //   to: "blog",
  // },
  {
    name: "FAQS",
    to: "faqs",
  },
  {
    name: "ABOUT US",
    to: "about-us",
  },
];
