import React, { useEffect } from "react";
import CostCard from "../WhySwytchedCards/CostCard";

import { COST_TYPE } from "../../utils/costList";

import styles from "./css/Tab.module.css";
import {
  handleSelectedTabBikes,
  handleSelectedTabCars,
  selectPriceComparisionTabSlice,
  setSelectedTab,
  handleChange,
} from "../../redux/priceComparisionTab/priceComparisionTabSlice";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { Skeleton } from "@mui/material";

const tabs: string[] = ["BIKES", "CARS"];

const Tab: React.FC = () => {
  const { isLoading, selectedTab, priceComparisionData, priceComparisionDataHardCoded, index } = useSelector(
    selectPriceComparisionTabSlice
  );

  useEffect(() => {
    if (selectedTab === "CARS") {
      store.dispatch(handleSelectedTabCars());
    } else if (selectedTab === "BIKES") {
      store.dispatch(handleSelectedTabBikes());
    }
  }, [selectedTab]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.tabs_container}>
          {tabs.map((tab: any, ind: number) => {
            return (
              <div
                style={{
                  color:
                    selectedTab === tab
                      ? "var(--color-variant-green-1)"
                      : "var(--color-variant-white)",
                }}
                onClick={() => store.dispatch(setSelectedTab(tab))}
                key={ind}
                className={styles.tabs}
              >
                {tab}
              </div>
            );
          })}
        </div>
        <div className={styles.tab_content}>
          <div className={styles.topics}>
            <div className={styles.vehicle_name}>
              <img
                onClick={() => store.dispatch(handleChange("left"))}
                src="/images/arrow-left-white.svg"
                alt="left"
              />
              {
                !isLoading && priceComparisionData ?
                  <p>
                    {`${priceComparisionData[index]?.Make} ${priceComparisionData[index]?.Model}`}
                  </p> :
                  <Skeleton variant="text" sx={{ fontSize: "12px", bgcolor: "#c1c1c1", width: "100%" }} />
              }
              <img
                onClick={() => store.dispatch(handleChange("right"))}
                src="/images/arrow-right-white.svg"
                alt="right"
              />
            </div>
            {COST_TYPE.map((type, ind) => {
              return <p key={ind}>{type}</p>;
            })}
          </div>
          <div className={styles.info}>
            <div className={styles.info_header}>
              <div className={styles.title}>
                <p
                  style={{
                    fontFamily: "var(--font-family-fraunces)",
                    fontSize: "20px",
                    fontWeight: 400,
                    color: "var(--color-variant-green-1)",
                    fontStyle: "italic",
                  }}
                >
                  Subscribe
                </p>
              </div>
            </div>
            <div className={styles.info_content}>
              <CostCard
                title="Subscribe"
                data={priceComparisionData[index]}
                selectedTab={selectedTab}
                dataHardCoded={priceComparisionDataHardCoded}
              />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.info_header}>
              <div className={styles.title}>
                <p style={{ fontSize: "20px", fontWeight: 400 }}>
                  {selectedTab === "CARS" ? "Buy (3y loan)" : "Buy (2y loan)"}
                </p>
              </div>
            </div>
            <div className={styles.info_content}>
              <CostCard
                title="Buy"
                data={priceComparisionData[index]}
                selectedTab={selectedTab}
                dataHardCoded={priceComparisionDataHardCoded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
