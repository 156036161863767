import { Box, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import HamburgerDrawer from "../../components/HamburgerDrawer/HamburgerDrawer";
import SwytchdLogo from "../../components/SwytchdLogo/SwytchdLogo";
import useWindowWidth from "../../hooks/useWindowWidth";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";

export const Topic = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "16px",

  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28.8px",
  margin: "25px 0px",
  color: theme.palette.black.light,
  "& span": {
    fontWeight: 500,
    marginRight: "5px",
    color: "#000",
  },

  "& li": {
    padding: "0px 0px 10px 15px",
  },

  [theme.breakpoints.down("lg")]: {
    margin: "25px 0px 0px 0px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const Summary = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "36px",
  margin: "20px 0px",
  color: theme.palette.black.light,

  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.white.main,
  padding: "0px 130px 0px 63px",
  flexBasis: "52%",
  paddingTop: "48px",

  [theme.breakpoints.down(1024)]: {
    padding: "20px 65px 0px 31.5px",
  },

  [theme.breakpoints.down("lg")]: {
    borderRadius: "15px",
    padding: "0px 65px 0px 31.5px",
  },

  [theme.breakpoints.down("sm")]: {
    borderRadius: "15px",
    padding: "0px 16px 0px 16px",
  },
}));

const TermsAndConditions = () => {
  const windowWidth = useWindowWidth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> SWYTCHD | Terms and Conditions </title>
        <meta name="description" content="In order for us to successfully register for a SWYTCHD account, you must have satisfied our qualification criteria..." />
        <meta name="keywords" content="EV Subscription, Book Electric Vehicle, EV Plans, Car rental service, Monthly ev rental " />
        <script src="/gtag.js" type="text/javascript" />
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T83SNTC" height="0" width="0"
          style={{ display: "none", visibility: "hidden" }}></iframe>`}
        </noscript>
      </Helmet>
      <Stack
        sx={{
          p: { xs: "26px 22px", md: "26px 80px" },
        }}
        direction="row"
        justifyContent="space-between"
      >
        <SwytchdLogo isBlack={true} color="var(--color-variant-black)" />
        <Box>
          {windowWidth > 900 ? (
            <Header color="var(--color-variant-black)" />
          ) : (
            <HamburgerDrawer variant="black" />
          )}
        </Box>
      </Stack>

      <Box
        sx={{
          p: { xs: "26px 22px", md: "0px 80px" },
          mt: "50px",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: { xs: "14px", lg: "20px" } }}>
          TERMS & CONDITIONS
        </Typography>

        {/* --------THIS IS FIRST T&C "THE AGREEMENT"--------------- */}
        <Stack
          sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}
          mt="32px"
        >
          <Box mt="42px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">1 This Agreement</Topic>
            <Content variant="body1">
              <span>1.1</span>
              The hire terms and conditions set out below and the financial and
              other information on pages 1 to 2 make up the agreement between us
              and you (the “Agreement”) upon which we are willing to enter into
              arrangements relating to the hire of a vehicle set out on page 1
              (the “Vehicle”).
            </Content>
            <Content variant="body1">
              <span>1.2</span>
              In order to successfully register for a SWYTCHD account you must
              have satisfied our qualification criteria.
            </Content>
            <Content variant="body1">
              <span>1.3</span>
              By entering into this Hire Agreement, you:
            </Content>
            <Content variant="body1">
              <span>1.3.1</span>
              Acknowledge and agree that we need to verify your identity,
              including your address and whether you are a person with whom we
              are prohibited from doing business. SWYTCHD signs this Agreement
              subject to the checks that we are legally required to do. If our
              checks disclose that we cannot do business with you, we will
              immediately cease onboarding you as a Hirer and terminate this
              agreement before the Vehicle Handover Date.
            </Content>
            <Content variant="body1">
              <span>1.3.2</span>
              Warrant that the Qualification Criteria remain satisfied and that
              the answers you gave us during the registration process remain
              true and accurate as of the date of this Agreement. If at any
              point this information is no longer valid and accurate, you must
              cease using the Vehicle and inform us immediately; and
            </Content>
            <Content variant="body1">
              <span>1.3.3</span>
              Re-confirm acceptance of the website terms and conditions.
            </Content>
            <Content variant="body1">
              <span>1.4</span>
              Please read the Qualification Criteria and the terms and
              conditions on our website, both of which form part of your
              Agreement.
            </Content>
            <Content variant="body1">
              <span>1.5</span>
              You are liable to us under the Agreement, regardless of whether
              the actions of a third party, such as an additional driver
              authorized by us (“Additional Driver”), caused you to be in breach
              of this Agreement.
            </Content>
            <Content variant="body1">
              <span>1.6</span>
              The maximum agreement term is one (1) month after which, should
              future hires be required, a new agreement is required. Extensions
              to this Agreement are strictly prohibited. Please contact us prior
              to the end of the Period of Hire should you wish to discuss any
              future hires.
            </Content>
            <Content variant="body1">
              We're so glad you're here! Before we get started, we need to make
              sure you know about our terms and conditions:
            </Content>
          </Box>

          <Container
            sx={{
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
              pt: "42px",
            }}
          >
            <Summary variant="body1">
              In order for us to successfully register for a SWYTCHD account,
              you must have satisfied our qualification criteria. By entering
              into this Hire Agreement, you acknowledge and agree that we need
              to verify your identity, including your address and whether you
              are a person with whom we are prohibited from doing business.
            </Summary>

            <Summary variant="body1">
              Oh, one more our Hire agreement is valid only for 1 month. Post
              that a renewal is required, an extension of the agreement is not
              valid.
            </Summary>
          </Container>
        </Stack>

        {/* ------------THIS IS SECOND "THE VEHICLE"----------  */}
        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">2 The Vehicle</Topic>
            <Content variant="body1">
              <span>2.1</span>
              The Vehicle:
            </Content>

            <Content variant="body1">
              <span>2.1.1</span>
              We will provide the Vehicle to you as detailed on page 1 of the
              Agreement.We will provide the Vehicle to you as detailed on page 1
              of the Agreement.
            </Content>
            <Content variant="body1">
              <span>2.1.2</span>
              Vehicles may vary slightly from their pictures. The images of the
              vehicles on our website or the SWYTCHD App are for illustrative
              purposes only. Although we have made every effort to display the
              colors accurately, we cannot guarantee that a device's display of
              the colors accurately reflects the color of the vehicles. Your
              Vehicle may vary slightly from those images.
            </Content>

            <Content variant="body1">
              <span>2.1.3</span>
              The word "Vehicle" includes any accessories and replacements made
              whilst this Agreement continues.
            </Content>

            <Content variant="body1">
              <span>2.1.4</span>
              You must unlock and lock your Vehicle using the provided keys or
              using technology that SWYTCHD provides.
            </Content>

            <Content variant="body1">
              <span>2.1.5</span>
              The Vehicle shall at all times remain our property, and you shall
              have no right, title or interest in or to the Vehicle (save the
              right to enjoy possession and use of the Vehicle subject to the
              terms and conditions of this Agreement). You must not claim
              ownership, assign, offer for sale or otherwise deal with the
              Vehicle or any interest in it.
            </Content>

            <Content variant="body1">
              <span>2.2</span>
              The Vehicle Condition:
            </Content>

            <Content variant="body1">
              <span>2.2.1</span>
              The Vehicle will be provided to you in a roadworthy condition and
              licenced. You must return the Vehicle in the same condition as
              provided, excepting fair wear and tear. See further clauses 7 and
              12.3.
            </Content>

            <Content variant="body1">
              <span>2.2.2</span>
              Before you begin driving the Vehicle, you must inspect the
              exterior and interior of the Vehicle for damage, complete the
              inspection form provided to capture its condition and submit it to
              us. Failure to notify as set out in this clause 2.2.2 shall
              conclusively be presumed that the Vehicle is complete and in good
              order and condition, fit for purpose, and in every way in
              satisfaction to you.
            </Content>

            <Content variant="body1">
              <span>2.3</span>
              Vehicle Package:
            </Content>

            <Content variant="body1">
              <span>2.3.1</span>
              The Vehicle is provided to you inclusive of
            </Content>

            <Content variant="body1">

              <li>
                1. Maintenance of the Vehicle, being regular service or
                maintenance in line with manufacturer recommendations (including
                Roadside Assistance in accordance with Appendix 2 to this
                Agreement), and
              </li>
              <li>
                2.Fully comprehensive insurance cover for you, as the main
                hirer, and any Additional Driver(s).
              </li>
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              The Vehicle may vary slightly from its pictures on our website or
              the SWYTCHD App but it will be delivered to you in roadworthy
              condition and licensed, still plz take an up close and personal
              look at it(interior and exterior) before you start driving it and
              fill the provided inspection form.
            </Summary>
            <Summary variant="body1">
              Also, the Vehicle will forever be SWYTCHD property, and
              unfortunately, you can't Sell it or claim ownership.
            </Summary>
          </Container>
        </Stack>

        {/*------------ THIS IS THIRD "PRERIOD OF HIRE..."------- */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">
              3 Period of Hire and Modifications to the Agreement
            </Topic>
            <Content variant="body1">
              <span>3.1</span>
              You are responsible for the Vehicle for the duration of the Period
              of Hire.
            </Content>

            <Content variant="body1">
              <span>3.2</span>
              The Period of Hire will start on the "Commencement Date" and,
              subject to the remaining terms of this Agreement, will continue
              for the Period of Hire shown on page 1 of the Agreement. Subject
              to clause 3.8, at the end of the Period of Hire, you must return
              the Vehicle to us in accordance with clause 12.
            </Content>

            <Content variant="body1">
              <span>3.3</span>
              On the Commencement Date, a physical key and a digital key will be
              available to you. The digital key will be in the SWYTCHD App and
              you will have access to the Vehicle by selecting ‘Get Key’.
            </Content>

            <Content variant="body1">
              <span>3.4</span>
              At any time prior to the end of the Period of Hire, you may ask us
              to add additional services/bolt-ons (“Additional Services”) for
              the remainder of the Period of Hire.
            </Content>

            <Content variant="body1">
              <span>3.5</span>
              Any requests for Additional Services must be submitted via the
              SWYTCHD App, our website or by calling us on the number below (see
              clause 18) and if we agree to the modifications these will be
              confirmed to you in writing. This will also show any additional
              charges payable by you.
            </Content>

            <Content variant="body1">
              <span>3.6</span>
              If at the end of the Period of Hire, we determine that there is a
              difference between the contractual Mileage Allowance shown on page
              1 of the Agreement and actual mileage at the end of the Period of
              Hire, we may at our discretion apply this as a mileage credit to
              your SWYTCHD account (“Mileage Credit”). If we award a Mileage
              Credit, km credited may be used towards any future vehicle hire,
              provided that the vehicle is of the same Tier Level.
            </Content>

            <Content variant="body1">
              <span>3.7</span>
              At the end of the Period of Hire, the previous hire information
              and Additional Services limits detailed in the SWYTCHD App will be
              saved as your ‘preferences’ and used as the initial selection
              options should you take any future hires. You can update these
              preferences at any time in the SWYTCHD App or on your “My Account”
              page on our website.
            </Content>

            <Content variant="body1">
              <span>3.8</span>
              If you wish to take the Vehicle or a new vehicle for a new hire
              period, a new hire agreement is required. You should contact us
              before the end of the Period of Hire to discuss your requirements.
              Taking a new agreement does not guarantee the availability of the
              same vehicle or the same rental price of a vehicle, any of which
              may be subject to change. We will only increase our prices as a
              result of our own costs increasing. If you do not agree to the
              increased price, you do not have to take a new hire.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              If you have any additional requirements plz notify us before the
              Hire period.
            </Summary>
            <Summary variant="body1">
              At the time of delivery of your Swytchd Vehicle, we will provide
              you with two keys: Physical and Digital, and Mileage allowance.
            </Summary>
            <Summary variant="body1">
              All these details will be available on the “My Account” on our
              website for your convenience.
            </Summary>
          </Container>
        </Stack>

        {/* -------THIS IS FOURTH "PAYMENTS"--------- */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">4 Payments</Topic>

            <Content variant="body1">
              <span>4.1</span>
              You must pay the Total Rental Payable shown under “Financial
              Information” on page 2 of the Agreement and any other sums due
              from you to us under this Agreement by the relevant due date,
              including any Additional Services that you requested and were
              agreed to be added to your account.
            </Content>

            <Content variant="body1">
              <span>1.1</span>
              Payments of all rentals and other charges incurred under the
              Agreement will be charged to the payment card saved in your
              account. You must maintain valid credit and/or debit cards on your
              SWYTCHD account and we will not be liable for any fees you incur
              from your bank or credit supplier associated with going overdrawn
              or exceeding your credit limit.
            </Content>

            <Content variant="body1">
              <span>1.2</span>
              Total Rental Payment:
            </Content>

            <Content variant="body1">
              <span>1.1.1</span>
              The Total Rental Payment will be taken on the date of the
              Agreement, or if over 14 days prior to the Commencement Date, an
              amount of 50% of the Total Rental Payment on signing the Agreement
              and the remaining 50% on the date seven days prior to the
              Commencement Date.
            </Content>

            <Content variant="body1">
              <span>1.1.2</span>
              In any event, the Total Rental Payable must be paid prior to the
              Commencement Date. We will use payment card pre-authorization to
              reserve any amount owed to us up to seven days before a payment is
              actually due. During this pre-authorization period, you will not
              have access to the reserved funds in your account. If we cannot
              pre-authorize the required amount three days before your
              Commencement Date then we reserve the right to terminate the
              Agreement and, if we do not proceed, cancellation charges may
              apply.
            </Content>

            <Content variant="body1">
              <span>1.2</span>
              Security Deposit
            </Content>

            <Content variant="body1">
              <span>1.2.1</span>
              The Security Deposit, as detailed on page 1 (if applicable), must
              be paid on the date of the Agreement.
            </Content>

            <Content variant="body1">
              <span>1.2.2</span>
              Following the return of the Vehicle any charges applicable under
              this Agreement will be deducted first from your Security Deposit
              before any remaining (if any) funds are returned to you.
            </Content>

            <Content variant="body1">
              <span>1.3</span>
              Charges/Other Payments:
            </Content>

            <Content variant="body1">
              <span>1.3.1</span>
              You must pay for, toll charges, parking, traffic, vehicle
              impounding due to driver fault or other fines and charges and
              related administration and processing fees, if due in accordance
              with this Agreement. Any fine incurred by you but not paid or
              recovered later by authorities will be chargeable to you, even
              after the vehicle has been returned. We will provide FASTAG RFIDs
              with every four (4) wheeled vehicle, and you will repay the tolls
              to us on actuals. We will be constantly monitoring traffic
              violations on government websites, and we will collect all fines
              from you on actuals.
            </Content>

            <Content variant="body1">
              <span>1.3.2</span>
              Save as provided in clause 10.1 and 11.2, any charges applied to
              the Agreement will be notified to you in writing and payment will
              be taken from your authorised payment card (that is linked to your
              account) seven ( 7 ) calendar days following notification.
            </Content>

            <Content variant="body1">
              <span>1.4</span>
              GST:
            </Content>

            <Content variant="body1">
              <span>1.4.1</span>
              All rentals, fees and charges detailed in this Agreement include
              GST at the rate which is current at the date of this Agreement. If
              the rate of GST has changed at the time the rental, fee or charge
              becomes payable (as applicable), the rental, fee or charge will be
              increased or decreased to reflect such change.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              Your Hire Agreement for the SWYTCHD vehicle will have all the
              payment terms and details. Please read the agreement carefully
              before signing.
            </Summary>
            <Summary variant="body1">
              All the payments will be charged to the card saved in your
              account. Please maintain valid credit and/or debit cards on your
              SWYTCHD account and we will not be liable for any fees you incur
              from your bank or credit supplier associated with going overdrawn
              or exceeding your credit limit.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Content variant="body1">Delivery</Content>

            <Content variant="body1">
              <span>2.1</span>
              Vehicle delivery and collection services are included as part of
              the Agreement. Your Vehicle will be delivered to you on the
              Commencement Date. It's free upto 20km from our location. Beyond
              20km it's 300 INR charge and beyond 25km is 500 INR.
            </Content>

            <Content variant="body1">
              <span>2.2</span>
              If our supply of the Vehicle is delayed by an event outside our
              control, then we will contact you as soon as possible to let you
              know and we will take steps to minimize the effect of the delay.
              Provided we do this we will not be liable for delays caused by the
              event, but if there is a risk of substantial delay you may contact
              us to end the contract and receive a full refund.
            </Content>
          </Box>
          <Container>
            <Summary variant="body1">
              At Swytchd we provide free doorstep delivery of your vehicle upto
              20km from our location. Chargeable beyond 20km. Under any
              circumstance, if there is any kind of delay in the delivery we
              will notify you in advance. In the rarest of rare care if there is
              a substantial delay from our end because of reasons beyond our
              control you can choose to end the contract. A full refund will be
              processed in that case.
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Content variant="body1">Insuring/Maintaining the vehicle</Content>

            <Content variant="body1">
              <span>3.1</span>
              Insurance
            </Content>

            <Content variant="body1">
              <span>3.1.1</span>
              We are responsible for maintaining appropriate insurance cover for
              the Vehicle. Mechanical defects in the Vehicle are not covered by
              our insurance. You must report these to our 24-hour customer
              service team on 9886309567 (please see Appendix 2 for further
              information).
            </Content>
          </Box>
          <Container>
            <Summary variant="body1">
              It’s our responsibility to maintain appropriate insurance cover
              for the Vehicle. However, Mechanical defects are not covered by
              our insurance.
            </Summary>

            <Summary variant="body1">
              For assistance, you can reach out to our 24-hour customer service
              team at 9886309567
            </Summary>
          </Container>
        </Stack>

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Content variant="body1">Care and Use of the Vehicle</Content>

            <Content variant="body1">
              <span>3.2.1</span>
              Reasonable care and skill. You must take reasonable care of the
              Vehicle including using reasonable care and skill when using the
              Vehicle, keeping the Vehicle in good repair and condition, using
              the Vehicle according to the laws of the country in which you are
              driving, using the Vehicle in a lawful manner and for lawful
              purposes, locking the Vehicle when you are not using it and
              ensuring that all openings are properly closed, refraining from
              using the vehicle (if not safe to do so) and notifying us
              immediately if you become aware of a fault with the Vehicle.
              Please see further the conditions in Appendix 1.
            </Content>

            <Content variant="body1">
              <span>3.2.2</span>
              Use. You must not use the Vehicle for any hire and reward or other
              business use, for any motorsport (recreational or professional) or
              related activity, off-road tow another vehicle or trailer,
              transport flammable, explosive, or combustible materials, or allow
              any person other than an authorized driver to use the Vehicle.
            </Content>

            <Content variant="body1">
              <span>3.2.3</span>
              Keeping the Vehicle secure. You must keep the Vehicle in your
              possession and under your day-to-day control. You must store the
              Vehicle in a secure location when you are not driving it and keep
              it locked at all times when you are not inside it.
            </Content>

            <Content variant="body1">
              <span>3.2.4</span>
              Remain in INDIA. You must not allow the Vehicle to be taken
              outside of India without our prior written consent. The vehicle
              package insurance does not cover travel outside of INDIA, as such
              an Additional Service of travel cover for outside INDIA will be
              required.
            </Content>

            <Content variant="body1">
              <span>3.2.5</span>
              Unauthorised third parties. You must not allow any third party who
              is not an Additional Driver to use the Vehicle.
            </Content>

            <Content variant="body1">
              <span>3.2.6</span>
              Personal Belongings. Personal belongings left in the Vehicle
              remain your responsibility at all times and we accept no liability
              for such.
            </Content>
          </Box>
          <Container>
            <Summary variant="body1">
              You are required to keep the Vehicle in good repair and condition,
              park it in a secure location when driving and follow all the rules
              and regulations in accordance with the laws of the country in
              which you are driving.
            </Summary>

            <Summary variant="body1">
              SWYTCHD will not be responsible for the personal belonging kept in
              the vehicle.
            </Summary>
          </Container>
        </Stack>

        {/* --------THIS IS LOSS AND DAMAGE--------- */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">4 Loss/Damage</Topic>

            <Content variant="body1">
              <span>4.1</span>
              You must notify the police immediately and us within 48 hours if
              the Vehicle has been involved in an accident or damage event, even
              if no third party was involved. You can notify us by calling our
              24-hour customer service team on 9886309567. Failure to notify us
              within 48 hours may result in you being liable for the losses we
              incur as a result of the delay. If you are involved in an accident
              or collision, you must also refer to Appendix 3 and follow the
              Accidents and Collisions Procedure.
            </Content>

            <Content variant="body1">
              <span>4.2</span>
              You are responsible for costs as provided in clause 1.3,
              (excluding any reasonable fair wear and tear), whether or not such
              loss or damage is your fault.
            </Content>

            <Content variant="body1">
              <span>4.3</span>
              If the Vehicle is lost, stolen or damaged you must, to the extent
              the law allows, pay our Insurance Excess stated on page 1 of this
              Agreement and any taxes. Your excess will not apply in
              circumstances set out in clauses 7.5 to 7.7.
            </Content>

            <Content variant="body1">
              <span>4.4</span>
              We will try to recover the excess and other charges from the party
              at fault if you are able to show that the damage, theft or loss Is
              not attributable to your fault, deliberate breach, fraudulence or
              gross negligence. To help us, you must provide us with a properly
              completed incident report form, including the contact details of
              the other parties involved, within 48 hours of the accident.
            </Content>

            <Content variant="body1">
              <span>4.5</span>
              If you breach this Agreement, or if one of our insurer’s
              exclusions apply (please refer to Appendix 1 Part B for our
              insurer’s significant exclusions), our insurance may be
              invalidated. If this occurs, you shall be liable to us for all
              damage that occurs to the Vehicle during the Period of Hire. You
              may also be liable to any third parties that have been injured or
              suffered property damage, as a result of your negligence.
            </Content>

            <Content variant="body1">
              <span>4.6</span>
              Your excess will not apply if the loss or damage is attributable
              to your deliberate or fraudulent act, omission or gross negligence
              or a deliberate breach of clause 6 and/or this clause 7. If your
              excess does not apply, we will be entitled to claim losses or
              damages against you in an amount equal to the severity of the
              negligence up to the full amount of the damage or loss we have
              incurred or will incur (whichever is the greater).
            </Content>

            <Content variant="body1">
              <span>4.7</span>
              Please note that tyre damage and wheel damage (including alloy
              wheels) is not covered by our insurance and you are therefore
              liable for tyre damage and wheel damage beyond reasonable wear and
              tear.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              In case of an unfortunate incident like an accident or theft you
              are to notify the Police immediately. Also please notify us by
              calling our 24-hour customer service team at 9886309567. We will
              take the necessary steps required to deal with the situation at
              that given time as per the Hire Agreement.
            </Summary>
          </Container>
        </Stack>

        {/* ------------THIS IS "OUR LIABILITY"---------- */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">5 Our Liability</Topic>

            <Content variant="body1">
              <span>5.1</span>
              If we fail to comply with this Agreement, we are responsible for
              loss or damage you suffer that is a foreseeable result of our
              breaching this Agreement or our failing to use reasonable care and
              skill. Loss or damage is foreseeable if either it is obvious that
              it will happen or if, at the time the contract was made, both we
              and you knew it might happen, for example, if you discussed it
              with us during the sales process.
            </Content>

            <Content variant="body1">
              <span>5.2</span>
              You are not liable for any loss or damage charges to the extent
              attributable to our failure to maintain the Vehicle or covered
              under a manufacturer warranty.
            </Content>

            <Content variant="body1">
              <span>5.3</span>
              We do not exclude or limit in any way our liability to you where
              it would be unlawful to do so. This includes liability for death
              or personal injury caused by our negligence or the negligence of
              our employees, agents, or subcontractors; for fraud or fraudulent
              misrepresentation; for breach of your legal rights in relation to
              the Vehicle and for defective products under the Consumer
              Protection Act 1987.
            </Content>

            <Content variant="body1">
              <span>5.4</span>
              We only supply the products for domestic and private use. If you
              use the products for any commercial, business or resale purpose we
              will have no liability to you for any loss of profit, loss of
              business, business interruption, or loss of business opportunity.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              We will be responsible for the loss or damage you suffer because
              of our breaching this agreement. We will also take full
              responsibility for negligence on our employees, agents, and
              subcontractors behalf. However, All SWYTCHD vehicles are meant
              strictly for personal use and we will have no liability to you if
              you use the Vehicle for business or commercial purposes.
            </Summary>
          </Container>
        </Stack>

        {/*----------- 6 OUR RIGHTS TO THE END OF THE AGREEMENT... ------ */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">
              6 Our rights to end the Agreement and What you must do if we end
              this Agreement
            </Topic>

            <Content variant="body1">
              <span>6.1</span>
              We may end the Agreement immediately if:
            </Content>

            <Content variant="body1">
              <span>6.1.1</span>
              We are not able to complete our Hirer checks to our satisfaction
              or if our checks show that we cannot do business with you or are
              otherwise inconclusive.
            </Content>

            <Content variant="body1">
              <span>6.1.2</span>
              We are not able to pre-authorise an amount you owe to us on your
              payment card at least 72 hours before the relevant payment is due;
            </Content>

            <Content variant="body1">
              <span>6.1.3</span>
              You, or an Additional Driver, cease to meet our Qualification
              Criteria at any point;
            </Content>

            <Content variant="body1">
              <span>6.1.4</span>
              You, or an Additional Driver, have given us any information, in
              relation to this Agreement or any agreement we may have with you,
              which we have reasonable grounds to believe is false or misleading
              in a material respect;
            </Content>

            <Content variant="body1">
              <span>6.1.5</span>
              You, or an Additional Driver, breach the Prohibited Use Rules,
              contained in Appendix 1 Part A, at any point;
            </Content>

            <Content variant="body1">
              <span>6.1.6</span>
              The mileage allowance is exceeded by 250 km or more during the
              Period of Hire;
            </Content>

            <Content variant="body1">
              <span>6.1.7</span>
              You or an Additional Driver are involved in a traffic collision,
              or the Vehicle is stolen or vandalised and we need to investigate;
            </Content>

            <Content variant="body1">
              <span>6.1.8</span>
              You or an Additional Driver receives more than three
              fines/penalties of the type described (without limitation) in
              clause 13.2 during the Period of Hire;
            </Content>

            <Content variant="body1">
              <span>6.1.9</span>
              You, or an Additional Driver, allow any third party not authorised
              by us to use the Vehicle; or
            </Content>

            <Content variant="body1">
              <span>6.1.10</span>
              We find out that any of your belongings have been taken away from
              you until you pay off your debts;
            </Content>

            <Content variant="body1">
              <span>6.1.11</span>A petition for your bankruptcy is presented, a
              bankruptcy order is made against you, or you enter into any
              voluntary arrangement with your creditors;
            </Content>

            <Content variant="body1">
              <span>6.1.12</span>
              We have reasonable grounds for believing that our interest in the
              Vehicle is at risk or any step which prejudices our ownership or
              rights in respect of the Vehicle is attempted or taken;
            </Content>

            <Content variant="body1">
              <span>6.1.13</span>
              You abandon the Vehicle; or
            </Content>

            <Content variant="body1">
              <span>6.1.14</span>
              You are in material breach of any of the terms of this Agreement
              or any other terms and conditions relating to your account and
              where capable of remedy you fail to rectify the breach.
            </Content>

            <Content variant="body1">
              <span>7.1</span>
              You must pay us immediately on-demand:
            </Content>

            <Content variant="body1">
              <span>7.1.1</span>
              All amounts that have already fallen due under the Agreement, but
              which have not been paid;
            </Content>

            <Content variant="body1">
              <span>7.1.2</span>
              Our costs of repairing the Vehicle and restoring it to good
              condition and enforcing our rights under the Agreement;
            </Content>

            <Content variant="body1">
              <span>7.1.3</span>
              Any costs, fines, charges and expenses incurred by us as a result
              of your breach of this Agreement (as shown in clause 13); and
            </Content>

            <Content variant="body1">
              <span>7.1.4</span>
              If the Vehicle is, for any reason, not returned to or recovered by
              us, a sum equal to the residual value of the Vehicle (such sum to
              be determined by us).
            </Content>

            <Content variant="body1">
              <span>7.2</span>
              You will no longer have the right to keep the Vehicle and you must
              return the Vehicle to us in accordance with clause 12.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              We have the right to end the agreement immediately if you do not
              comply with the terms and conditions of the Hire Agreement,
              including but not limited to your failing the background check or
              delay in payments.
            </Summary>

            <Summary variant="body1">
              You are required to clear the due amount along with penalties if
              applicable as per the Agreement under such circumstances.
            </Summary>
          </Container>
        </Stack>

        {/*--------- 8 YOUR RIGHTS TO END THE AGREEMENT ------ */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">8 Your rights to end the agreement</Topic>

            <Content variant="body1">
              <span>8.1</span>
              In the event that you request to end the Agreement before the
              Commencement Date, we may consider, at our absolute discretion,
              refunds of the Total Rental Payment made. However, if you cancel
              the Hire Agreement within 48 hours of the Commencement Date, we
              may not refund you any payment or delivery charges (if applicable)
              paid, as we are likely to incur costs associated with moving the
              Vehicle, parking it, and being unable to make it available to
              other customers.
            </Content>

            <Content variant="body1">
              <span>8.2</span>
              We may, at our discretion, allow you to end the hiring of the
              Vehicle during the Period of Hire by giving us not less than seven
              (7) days’ notice. You can notify us via the SWYTCHD App, website,
              or by calling us on the number detailed at clause 18 below. If we
              agree to your early termination you will no longer have the right
              to keep the Vehicle and you must arrange to return the Vehicle to
              us within seven (7) days from the date of your early termination.
              The Vehicle must be returned to us in accordance with clause 12,
              and you must pay us immediately on request all amounts detailed in
              clause 10.1. For the avoidance of doubt, there will be no refund
              of rentals due to you.
            </Content>

            <Content variant="body1">
              <span>8.3</span>
              As this is a consumer hire agreement for vehicle hire at a
              specific time, you do not have a right to change your mind and
              cancel the Agreement (or any ancillary Additional Services that
              are part of the Hire Agreement) once we have entered into it.
              However, we may be able to consider cancellation requests before
              your Period of Hire starts on a case-by-case basis in accordance
              with clause 11.1.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              If you wish to terminate the agreement you can raise a request for
              the same on our website or app or by reaching out to us at our
              customer care number - 9886309567.
            </Summary>
            <Summary variant="body1">
              Please note that this will totally be at our discretion and we may
              be able to consider cancellation requests before your Period of
              Hire starts on a case-by-case basis.
            </Summary>
          </Container>
        </Stack>

        {/*---- 9 COLLECTION OF THE VEHICLE AT THE END OF THE HIRE ---- */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">
              9 Collection of the Vehicle at the end of the hire
            </Topic>

            <Content variant="body1">
              <span>9.1</span>
              Upon expiry of the Period of Hire or such earlier termination in
              accordance with this Agreement, the Vehicle must be collected by
              us.
            </Content>

            <Content variant="body1">
              <span>9.2</span>
              On collection, you must make sure that:
            </Content>

            <Content variant="body1">
              <span>9.2.1</span>
              the interior and exterior of the Vehicle are clean;
            </Content>

            <Content variant="body1">
              <span>9.2.2</span>
              the Vehicle has a minimum of 80% charge;
            </Content>

            <Content variant="body1">
              <span>9.2.3</span>
              you have complied with your obligations as to the care and use of
              the Vehicle. This will be checked when the Vehicle is inspected.
              In particular, the purpose of the inspection will be to check:
            </Content>

            <Content variant="body1">
              <li>
                1. whether there is any damage which, in our reasonable opinion,
                is in excess of fair wear and tear ( as determined by SWYTCHD’ s
                Guidelines having regard to age and mileage);
              </li>

              <li>
                2. that the Vehicle is being returned in a similar level of
                cleanliness in which you found it (including if you or any third
                party has smoked inside the Vehicle during the Period of Hire);
              </li>

              <li>
                3. that the Vehicle is being returned with all documentation and
                parts as originally supplied with the Vehicle;
              </li>

              <li>
                4. that the Vehicle is being returned with (where applicable)
                the spare wheel, tools, master key or key card (if applicable),
                electric vehicle charge card(s) and all accessories as
                originally supplied; and
              </li>

              <li>
                5. that the Vehicle is being returned free of any added extras
                or modifications.
              </li>
            </Content>

            <Content variant="body1">
              <span>9.3</span>
              You are responsible for any loss, damage, cleaning or charges as a
              result of your breach of this Agreement, whether or not such loss,
              damage or charge is your fault.
            </Content>

            <Content variant="body1">
              <span>9.4</span>
              When making the Vehicle ready for collection, you must park the
              Vehicle appropriately, put the Vehicle on charge (if a charging
              point is available, place the charge cards and the key card (if
              applicable) in the glove compartment, ensure all windows are
              securely closed and properly lock the Vehicle.
            </Content>

            <Content variant="body1">
              <span>9.5</span>
              Please do not leave any belongings in the Vehicle after the end of
              the Agreement. If you do, we will send any of your personal
              effects that are found in the Vehicle as soon as we reasonably can
              to your address last known to us (subject to a charge payable in
              advance for our reasonable costs of delivering these items to
              you). Alternatively, you may collect these personal effects from
              us at a place of our choosing free of charge. Save as set out in
              this clause 13.4, we shall have no liability to you for any loss
              you may incur in relation to such personal effects. In any event,
              we will hold belongings for a period of three months before
              disposing of them or donating them to charity, at our discretion.
            </Content>

            <Content variant="body1">
              <span>9.6</span>
              If you fail to return the Vehicle or make it available for
              collection upon expiry or early termination of this Agreement,
              then you shall pay to us all reasonable and direct costs we incur
              due to any delays in return or in us
            </Content>

            <Content variant="body1">
              having to collect the Vehicle due to your breach of this
              Agreement, including the costs in us relocating, repossessing and
              inspecting the Vehicle. You irrevocably authorise us to enter upon
              your premises to collect the Vehicle under this clause.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              Under all circumstances the Vehicle will be collected by us only.
              Please ensure that you return the vehicle with all the original
              documents which were given to you at the time of delivery. Also
              double-check the vehicle to make sure you don't leave any of your
              personal belongings in it. When making the vehicle ready for
              collection you are to make sure it’s clean and is 80% charged.
            </Summary>

            <Summary variant="body1">
              Please note you are responsible for any loss, damage, cleaning, or
              charges as a result of your breach of this Agreement
            </Summary>
          </Container>
        </Stack>

        {/* ----------- ADDITIONAL CHARGES ----------------- */}

        <Stack sx={{ flexDirection: { xs: "column", lg: "row" }, gap: "54px" }}>
          <Box mt="48px" sx={{ flexBasis: "48%" }}>
            <Topic variant="body1">10 Additional Charges</Topic>

            <Content variant="body1">
              <span>10.1</span>
              You must pay for any costs or charges incurred by us during the
              Period of Hire (whether or not these were incurred due to a third
              party, such as an Additional Driver). You must also pay our
              administrative fee to cover our time in dealing with these costs
              and charges.
            </Content>

            <Content variant="body1">
              <span>10.2</span>
              Fines, Charges, Recovery, Towing, and Driving Offences - You are
              responsible for paying all fines, penalties, toll/congestion, and
              such other items incurred by you or any Additional Drivers using
              the Vehicle during the Period of Hire.
            </Content>

            <Content variant="body1">
              <span>10.3</span>
              Mileage charge - If the mileage allowance as detailed on page 1
              (inclusive of the mileage allowance of any applicable bolt-on) is
              exceeded, any additional mileage is charged depending on the
              relevant Tier Level your Vehicle sits in, as follows:
            </Content>

            <Content variant="body1">
              (a) Tier 1 (Small): 2 Rupees per additional km
            </Content>

            <Content variant="body1">
              (b) Tier 2 (Large): 8 Rupees per additional km
            </Content>

            <Content variant="body1">
              <span>10.4</span>
              Loss, damage or condition. You are responsible for any loss,
              damage, cleaning or charges as a result of your breach of this
              Agreement, whether or not such loss, damage or charge is your
              fault. In addition, please also see clause 7 and 12.
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              You are responsible for any and all kinds of damages, fines, and
              penalties. The same will be applicable to the Mileage allowance
              too if exceeded (case-on-case basis)
            </Summary>
          </Container>
        </Stack>

        {/*--------- ADDITIONAL DRIVERS AND PASSENGERS ---------- */}
        <Stack
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            gap: "54px",
            mb: { xs: "55px", lg: "0px" },
          }}
        >
          <Box
            mt="48px"
            sx={{ flexBasis: "48%", pb: { xs: "0px", lg: "330px" } }}
          >
            <Topic variant="body1">11 Additional Drivers and Passengers</Topic>

            <Content variant="body1">
              <span>11.1</span>
              You are responsible for ensuring that any additional drivers you
              have added to the Agreement or any passengers that you allow in
              the Vehicle observe the terms of this Agreement. You are
              responsible for any costs or charges we incur because an
              additional driver or passenger(s) does not comply.
            </Content>

            <Content variant="body1">
              <span>11.2</span>
              Your excess may not apply if the loss or damage is because of the
              additional driver’s or passengers' deliberate or fraudulent act,
              omission or gross negligence, or deliberate breach of clauses 6
              and 7.
            </Content>

            <Topic variant="body1">12 Complaints</Topic>

            <Content variant="body1">
              <span>12.1</span>
              If you have any questions or complaints about your Vehicle or
              otherwise, please contact us. You can telephone our 24- hour
              customer service team at 9886309567 or write to us at
              customer@swytchd.com or the postal address was given at the top of
              this Hire Agreement.
            </Content>

            <Content variant="body1">
              <span>12.2</span>
              If we are unable to resolve your complaint to your satisfaction
              within 30 working days, you can write to our Founder at
              sameer@swytchd.com
            </Content>
          </Box>

          <Container>
            <Summary variant="body1">
              You are responsible for any third-party access you give to
              additional drivers or passengers and for any costs or charges we
              incur because of the third-party access agree to.
            </Summary>
          </Container>
        </Stack>
      </Box>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
